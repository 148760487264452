import React, { forwardRef } from 'react';
import { Button, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import { H5 } from 'components';
import { palette } from 'styles/global';

interface IMuiButtonProps {
  disabled?: boolean;
  type?: 'regular' | 'outline';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  id?: string;
  fullWidth?: boolean;
  buttonStyleOverride?: React.CSSProperties;
  textStyleOverride?: React.CSSProperties;
  buttonType?: 'submit' | 'reset' | 'button';
}

export const MUIButton = forwardRef<HTMLButtonElement, IMuiButtonProps>(
  (
    {
      onClick,
      label,
      Icon,
      disabled,
      id,
      type = 'regular',
      buttonType = 'button',
      fullWidth,
      buttonStyleOverride,
      textStyleOverride
    },
    ref
  ) => {
    const buttonStyle =
      type === 'outline' ? style.button.outline : style.button[disabled ? 'disabled' : 'enabled'];
    const textStyle =
      type === 'outline' ? style.text.outline : style.text[disabled ? 'disabled' : 'enabled'];
    return (
      <Button
        style={{
          margin: '5px 0px',
          padding: '0px 16px',
          minHeight: 40,
          ...buttonStyle,
          ...buttonStyleOverride
        }}
        ref={ref}
        fullWidth={fullWidth}
        disabled={disabled}
        type={buttonType}
        onClick={onClick}
        id={id}
        aria-disabled={disabled}
      >
        {!!Icon && <Icon style={{ ...textStyle, ...textStyleOverride, paddingRight: '6px' }} />}
        <H5
          styleOverride={{
            textTransform: 'none',
            letterSpacing: '0.015em',
            ...textStyle,
            ...textStyleOverride
          }}
        >
          {label}
        </H5>
      </Button>
    );
  }
);

const style = {
  button: {
    disabled: {
      backgroundColor: palette.cement,
      border: `2px solid ${palette.cement}`
    },
    enabled: {
      backgroundColor: palette.marine,
      border: `2px solid ${palette.marine}`
    },
    outline: {
      border: `2px solid ${palette.marine}`,
      borderRadius: 4,
      backgroundColor: palette.white
    }
  },
  text: {
    disabled: {
      color: palette.concrete
    },
    enabled: {
      color: palette.sawdust
    },
    outline: {
      color: palette.marine
    }
  }
};
