import React, { FC, useContext } from 'react';
import { Box, Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { navigate } from '@reach/router';

import { Spinner, Text } from 'components';
import { formatMUICurrency, pushGAEvent } from 'utils';
import { palette } from 'styles/global';
import { IAction } from 'core/interfaces';
import { IssueContext, SnackbarContext } from 'context';
import { useQuery } from '@apollo/client';
import { CALCULATE_SERVICE_ACTION } from 'graphql/queries';
import {
  CalculatePaymentResult,
  Query,
  QueryCalculateServiceActionArgs,
  ServiceType
} from 'generated/graphql';
import { SERVICE_CATEGORIES, SERVICE_TYPES } from 'data/constants';
import { SNACKBAR_OPTIONS } from 'data/snackbar-options';

interface ISelections {
  actions: IAction[];
  projectId: string;
  backRouteNavigation: string;
  declineAllSelections: boolean;
  hasFeePromotion?: boolean | null;
  hasPreviousAccepts?: boolean | undefined | null;
  promoTitle?: string;
  promoTerms?: string | undefined | null;
}

export const Selections: FC<ISelections> = ({
  actions,
  projectId,
  backRouteNavigation,
  declineAllSelections,
  hasFeePromotion,
  hasPreviousAccepts,
  promoTitle,
  promoTerms
}) => {
  const classes = useStyles();
  const { getEstimateFromId, getServiceFromId, getProjectFromId } = useContext(IssueContext);
  const { updateSnackbar } = useContext(SnackbarContext);
  const projectFees = getProjectFromId(projectId)!.fees;
  const { loading, data } = useQuery<Query, QueryCalculateServiceActionArgs>(
    CALCULATE_SERVICE_ACTION,
    {
      variables: {
        projectId,
        data: actions
      },
      onError: (e) => {
        console.log(e);
        updateSnackbar(SNACKBAR_OPTIONS.apiError);
      },
      fetchPolicy: 'network-only'
    }
  );
  const getServiceText = (action: CalculatePaymentResult) => {
    const service = getServiceFromId(action.serviceId);
    return service.type === ServiceType.SmallWork || service.type === ServiceType.NewServiceOffering
      ? SERVICE_CATEGORIES[service.category]
      : SERVICE_TYPES[service.type];
  };

  return (
    <Box className={classes.selections}>
      <Grid
        container
        direction="row"
        alignContent="center"
        alignItems="center"
        justify="space-between"
        className={classes.selectionsHeader}
      >
        <Grid item>
          <Text customClass="overline-b" style={{ color: palette.marine }}>
            Your Selections
          </Text>
        </Grid>
        <Grid item>
          <Button
            className={classes.startOver}
            onClick={() => {
              pushGAEvent({ id: 'service-estimate-edit-selection-lnk' });
              navigate(`${backRouteNavigation}`);
            }}
          >
            <Text customClass="button">Start Over</Text>
          </Button>
        </Grid>
      </Grid>
      {loading && !data ? (
        <Spinner />
      ) : (
        data?.calculateServiceAction.map((action, index) => (
          <Box className={classes.item} key={`estimate-item-${index}`}>
            <Text customClass="body" additionalClasses={classes.itemTitle}>
              Estimate {index + 1}: {action.status.toLowerCase()}
            </Text>
            <Grid
              container
              direction="row"
              alignContent="flex-start"
              alignItems="flex-start"
              justify="space-between"
              wrap="nowrap"
            >
              <Grid item style={{ flexGrow: 1 }}>
                <Text customClass="body" additionalClasses={classes.itemDesc}>
                  {!!action.estimateId
                    ? getEstimateFromId(projectId, action.serviceId, action.estimateId)?.title
                    : getServiceText(action)}
                </Text>
              </Grid>
              <Grid item>
                <Text customClass="body" additionalClasses={classes.itemDesc}>
                  $
                  {formatMUICurrency(
                    declineAllSelections && projectFees && projectFees.length
                      ? 0
                      : action.customerCost
                  )}
                </Text>
              </Grid>
            </Grid>
          </Box>
        ))
      )}
      {declineAllSelections && projectFees && projectFees.length > 0 && (
        <Grid
          className={classes.fee}
          container
          direction="row"
          alignContent="center"
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Text customClass="body" additionalClasses={classes.feeTitle}>
              In-Person Assessment Fee
            </Text>
          </Grid>
          <Grid item>
            <Text
              customClass="body"
              additionalClasses={hasFeePromotion ? classes.feeAmountWaived : classes.feeAmount}
            >
              ${formatMUICurrency(projectFees.find((f) => f?.type === 'ASSESSMENT')!.fee)}
            </Text>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        direction="row"
        alignContent="center"
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <Text customClass="body" additionalClasses={classes.totalTitle}>
            Total
          </Text>
        </Grid>
        <Grid item>
          <Text customClass="body" additionalClasses={classes.totalAmount}>
            $
            {formatMUICurrency(
              data?.calculateServiceAction.map((x) => x.customerCost).reduce((a, b) => a + b, 0)
            )}
          </Text>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  selections: {
    background: palette.cement,
    padding: '15px 18px',
    margin: '0 -18px 0'
  },
  selectionsHeader: {
    marginBottom: 20
  },
  item: {
    borderBottom: `1px solid ${palette.tide}`,
    paddingBottom: 5,
    marginBottom: 15,
    alignItems: 'flex-start'
  },
  itemTitle: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'capitalize'
  },
  itemDesc: {
    fontSize: 16
  },
  fee: {
    borderBottom: `1px solid ${palette.tide}`,
    paddingBottom: 5,
    marginBottom: 15
  },
  feeTitle: {
    fontSize: 14,
    fontWeight: 700
  },
  feeAmount: {
    fontSize: 16,
    fontWeight: 700
  },
  feeAmountWaived: {
    fontSize: 16,
    fontWeight: 700,
    textDecoration: 'line-through'
  },
  totalTitle: { fontFamily: 'Manrope', fontSize: 18, fontWeight: 700 },
  totalAmount: { fontFamily: 'Manrope', fontSize: 18, fontWeight: 700 },
  startOver: {
    padding: 0,
    color: palette.caribbean,
    '&:hover': {
      background: 'none'
    }
  }
}));
