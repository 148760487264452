import React, { createContext, useState, useCallback } from 'react';

import { pushSnackbarInformation } from 'utils';

export interface Snackbar {
  type: 'success' | 'error';
  message: string;
  key: string;
  description?: string;
}

interface SnackbarContextProps {
  snackbars: Snackbar[];
  updateSnackbar: (snackbar: Snackbar) => void;
  closeSnackbar: (key: string) => void;
  closeAllSnackbars: () => void;
  closeAllErrorSnackbars: () => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>({} as SnackbarContextProps);

type NotificationProviderProps = {
  children: React.ReactNode | JSX.Element;
};

export const SnackbarProvider = ({ children }: NotificationProviderProps) => {
  const [snackbars, setSnackbars] = useState<Snackbar[]>([]);

  const closeSnackbar = useCallback(
    (key: string) => {
      if (snackbars.length > 0) {
        const cloned = snackbars
          .map(x => Object.assign({}, x))
          .filter(snackbar => snackbar.key !== key);
        setSnackbars(cloned);
      }
    },
    [setSnackbars, snackbars]
  );

  const closeAllSnackbars = useCallback(() => {
    if (snackbars.length > 0) {
      setSnackbars([]);
    }
  }, [snackbars.length]);

  const closeAllErrorSnackbars = useCallback(() => {
    if (snackbars.length > 0 && snackbars.some(x => x.type === 'error')) {
      const cloned = snackbars
        .map(x => Object.assign({}, x))
        .filter(snackbar => snackbar.type === 'success');
      setSnackbars(cloned);
    }
  }, [snackbars]);

  const updateSnackbar = useCallback(
    (newSnackbar: Snackbar) => {
      // Push Snackbar to GA
      pushSnackbarInformation(newSnackbar.message, newSnackbar?.description);
      // Prevent Duplicate Snackbars
      if (!snackbars.some(x => x.key === newSnackbar.key)) {
        // Maximum number of snackbars
        if (snackbars.length >= 3) {
          const cloned = snackbars.map(x => Object.assign({}, x)).slice(1);
          cloned.push(newSnackbar);
          setSnackbars(cloned);
        } else {
          const cloned = snackbars.map(x => Object.assign({}, x));
          cloned.push(newSnackbar);
          setSnackbars(cloned);
        }
      }
    },
    [snackbars]
  );

  return (
    <SnackbarContext.Provider
      value={{
        snackbars,
        updateSnackbar,
        closeSnackbar,
        closeAllSnackbars,
        closeAllErrorSnackbars
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const SnackbarContextConsumer = SnackbarContext.Consumer;
