import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { Auth as AmplifyAuth } from 'aws-amplify';
import client from 'graphql/client';

import { BannerContext, SnackbarContext } from 'context';
import { GET_CURRENT_USER, GET_USER_FROM_TOKEN } from 'graphql/queries';
import { SNACKBAR_OPTIONS } from 'data/snackbar-options';
import { COGNITO_NOT_AUTHENTICATED_ERROR, CastleStorage } from 'data/constants';
import { User, Address, Query } from 'generated/graphql';
import { useLazyQuery } from '@apollo/client';

interface TokenUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string;
  email: string;
  addresses: Address[];
  paymentToken: string;
}

interface UserContextProps {
  user: User;
  token: string;
  tokenUser: TokenUser;
  isLoaded?: boolean;
  updateCurrentUser: (cached?: boolean) => Promise<void>;
  clearUserToken: Function;
  signOutUser: () => Promise<void>;
}

export const UserContext = createContext<UserContextProps>({} as UserContextProps);

type UserProviderProps = {
  children: React.ReactNode | JSX.Element;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const { updateSnackbar } = useContext(SnackbarContext);
  const { clearBanners } = useContext(BannerContext);
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState('');
  const [tokenUser, setTokenUser] = useState<TokenUser>();
  const [isLoaded, setIsLoaded] = useState(false);

  const clearUserToken = () => {
    setToken('');
    setTokenUser(undefined);
  };

  const [getUserFromToken] = useLazyQuery<Query>(GET_USER_FROM_TOKEN, {
    onCompleted: (data) => {
      const response = data.getUserFromToken;
      const user: any = { ...response, ...response?.user, user: undefined };
      setTokenUser(user);
    },
    onError: (d) => {
      updateSnackbar(SNACKBAR_OPTIONS.invalidToken);
      setTokenUser(undefined);
    },
    fetchPolicy: 'no-cache'
  });

  // Check if token is being passed in query string
  const checkToken = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const userToken = urlParams.get('token');
    if (userToken) {
      try {
        getUserFromToken({
          variables: {
            token: userToken
          }
        });
        setToken(userToken);
      } catch {
        updateSnackbar(SNACKBAR_OPTIONS.invalidToken);
        setTokenUser(undefined);
      }
    }
  }, [updateSnackbar, getUserFromToken]);

  const updateCurrentUser = useCallback(
    async (cached: boolean = false) => {
      try {
        // If user is logged in, set current user
        await AmplifyAuth.currentAuthenticatedUser();
        const query = await client.query({
          query: GET_CURRENT_USER,
          fetchPolicy: cached ? 'cache-first' : 'no-cache'
        });
        setUser(query.data.getCurrentUser);
        setIsLoaded(true);
      } catch (err) {
        if (COGNITO_NOT_AUTHENTICATED_ERROR.includes(err)) {
          // If user is not logged in, check for a token
          checkToken();
        } else {
          updateSnackbar(SNACKBAR_OPTIONS.apiError);
        }
        setUser(undefined);
        setIsLoaded(true);
        localStorage.removeItem(CastleStorage.COVID19);
      }
    },
    [updateSnackbar, checkToken]
  );

  const signOutUser = useCallback(async () => {
    await AmplifyAuth.signOut({
      global: true
    });
    localStorage.removeItem(CastleStorage.COVID19);
    setUser(undefined);
    clearBanners(true);
  }, [clearBanners]);

  useEffect(() => {
    if (!user && !tokenUser && !isLoaded) {
      updateCurrentUser();
    }
  }, [user, updateCurrentUser, tokenUser, isLoaded]);

  return (
    <UserContext.Provider
      value={{
        user: user as User,
        token,
        tokenUser: tokenUser as TokenUser,
        isLoaded,
        updateCurrentUser,
        clearUserToken,
        signOutUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
