import React, { FunctionComponent } from 'react';
import { P } from 'components';
import { pushGAEvent } from 'utils';
import { CastleCheckBox } from 'components/CastleCheckBox/CastleCheckBox';
import { Grid } from '@material-ui/core';
import { palette } from 'styles/global';
import { BASE_URL } from 'data/env-vars';

const TermsLabel: FunctionComponent = () => (
  <P mui muiType="body">
    I acknowledge I have read and accept the{' '}
    <a
      href={`${window.location.origin}${BASE_URL}/terms`}
      target="_blank"
      rel="noreferrer noopener"
      style={styles.a}
      id="terms-account-creation-lbl"
      onClick={() => {
        pushGAEvent({ id: 'terms-account-creation-lbl' });
      }}
    >
      Terms and Conditions
    </a>{' '}
    and{' '}
    <a
      href={`${window.location.origin}${BASE_URL}/privacy`}
      target="_blank"
      rel="noreferrer noopener"
      style={styles.a}
      id="privacy-account-creation-lbl"
      onClick={() => {
        pushGAEvent({ id: 'privacy-account-creation-lbl' });
      }}
    >
      Privacy Policy
    </a>
    .
  </P>
);

interface ITermsCheckboxProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TermsCheckbox = ({ checked, onChange }: ITermsCheckboxProps) => {
  return (
    <Grid container direction="row" spacing={1} wrap="nowrap">
      <Grid item>
        <CastleCheckBox id="accept-terms-btn" checked={checked} onChange={onChange} />
      </Grid>
      <Grid item style={styles.label}>
        <TermsLabel />
      </Grid>
    </Grid>
  );
};

const styles = {
  a: {
    fontWeight: 'bold' as 'bold',
    color: palette.elephant
  },
  label: {
    marginTop: '3px'
  }
};
