import React, { FC } from 'react';

interface IDoubleSlash {
  width?: string;
  height?: string;
  fill?: string;
}

export const DoubleSlash: FC<IDoubleSlash> = ({ width, height, fill = '#EB8870' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.07191 0.0799866L0.159912 26H4.51591L11.4279 0.0799866H7.07191Z" fill={fill} />
      <path d="M14.6188 0.0799866L7.70679 26H12.0628L18.9748 0.0799866H14.6188Z" fill={fill} />
    </svg>
  );
};
