export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type ActionHistory = {
  __typename?: 'ActionHistory';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<ActionType>;
  userType?: Maybe<ActionUser>;
  userName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  primary: Scalars['Boolean'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: State;
  county?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
  market: Market;
  timezone: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateUpdateAddressInput = {
  primary: Scalars['Boolean'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: State;
  county?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
  market: Market;
  timezone: Scalars['String'];
};

export enum DiscountType {
  Percent = 'PERCENT',
  Dollar = 'DOLLAR'
}

export enum PromotionType {
  Fee = 'FEE',
  Service = 'SERVICE',
  Estimate = 'ESTIMATE'
}

export enum FeatureFlag {
  RequiredCreditCardAtSetup = 'REQUIRED_CREDIT_CARD_AT_SETUP',
  AutomatedPayments = 'AUTOMATED_PAYMENTS'
}

export enum ServiceStatus {
  SchedulingInProgress = 'SCHEDULING_IN_PROGRESS',
  Scheduled = 'SCHEDULED',
  EstimateReadyForReview = 'ESTIMATE_READY_FOR_REVIEW',
  EstimateAccepted = 'ESTIMATE_ACCEPTED',
  EstimateDeclinedPendingPayment = 'ESTIMATE_DECLINED_PENDING_PAYMENT',
  EstimateDeclinedPaid = 'ESTIMATE_DECLINED_PAID',
  ServiceCompletedPendingPayment = 'SERVICE_COMPLETED_PENDING_PAYMENT',
  WorkApprovedPaid = 'WORK_APPROVED_PAID',
  CanceledPendingPayment = 'CANCELED_PENDING_PAYMENT',
  CanceledPaid = 'CANCELED_PAID'
}

export enum FeeType {
  Assessment = 'ASSESSMENT',
  Nexus = 'NEXUS'
}

export enum ServiceOverrideStatus {
  Void = 'VOID',
  CanceledNotCharged = 'CANCELED_NOT_CHARGED',
  DeclinedNotCharged = 'DECLINED_NOT_CHARGED',
  Scheduled = 'SCHEDULED',
  SchedulingInProgress = 'SCHEDULING_IN_PROGRESS',
  EstimateReadyForReview = 'ESTIMATE_READY_FOR_REVIEW',
  EstimateAccepted = 'ESTIMATE_ACCEPTED',
  ServiceCompletePendingPayment = 'SERVICE_COMPLETE_PENDING_PAYMENT',
  CanceledPendingPayment = 'CANCELED_PENDING_PAYMENT',
  EstimateDeclinedPendingPayment = 'ESTIMATE_DECLINED_PENDING_PAYMENT',
  CanceledPaid = 'CANCELED_PAID',
  EstimateDeclinedPaid = 'ESTIMATE_DECLINED_PAID',
  WorkApprovedPaid = 'WORK_APPROVED_PAID'
}

export enum ServiceStatusReason {
  NoLongerNeeded = 'NO_LONGER_NEEDED',
  CustomerRequest = 'CUSTOMER_REQUEST',
  SchedulingIssues = 'SCHEDULING_ISSUES',
  CustomerNoShow = 'CUSTOMER_NO_SHOW',
  AssessmentFeeTooExpensive = 'ASSESSMENT_FEE_TOO_EXPENSIVE',
  CastleServiceDissatisfaction = 'CASTLE_SERVICE_DISSATISFACTION',
  ServiceProviderSelection = 'SERVICE_PROVIDER_SELECTION',
  ServiceProviderNoShow = 'SERVICE_PROVIDER_NO_SHOW',
  ActsOfGod = 'ACTS_OF_GOD',
  CastleExpertMistake = 'CASTLE_EXPERT_MISTAKE',
  Other = 'OTHER'
}

export enum ServiceEstimateDeclinedReason {
  TooExpensive = 'TOO_EXPENSIVE',
  UnsatisfiedWithPaymentOptions = 'UNSATISFIED_WITH_PAYMENT_OPTIONS',
  UnsatisfiedWithServiceTechnician = 'UNSATISFIED_WITH_SERVICE_TECHNICIAN',
  UnsatisfiedWithCastleService = 'UNSATISFIED_WITH_CASTLE_SERVICE',
  DecidedNotToFix = 'DECIDED_NOT_TO_FIX',
  SchedulingIssues = 'SCHEDULING_ISSUES',
  NoLongerNeeded = 'NO_LONGER_NEEDED',
  Inactive_14Days = 'INACTIVE_14_DAYS',
  Other = 'OTHER'
}

export enum ServiceCategory {
  DoorsWindows = 'DOORS_WINDOWS',
  DrywallPaint = 'DRYWALL_PAINT',
  Electrical = 'ELECTRICAL',
  Exterior = 'EXTERIOR',
  Flooring = 'FLOORING',
  General = 'GENERAL',
  Hvac = 'HVAC',
  Millwork = 'MILLWORK',
  Plumbing = 'PLUMBING',
  RoofingGutter = 'ROOFING_GUTTER'
}

export enum ServiceType {
  NewServiceOffering = 'NEW_SERVICE_OFFERING',
  SmallWork = 'SMALL_WORK',
  GarageDoor = 'GARAGE_DOOR',
  WindowsSlidingGlassDoor = 'WINDOWS_SLIDING_GLASS_DOOR',
  InteriorDoor = 'INTERIOR_DOOR',
  ExteriorDoor = 'EXTERIOR_DOOR',
  Drywall = 'DRYWALL',
  InteriorPaint = 'INTERIOR_PAINT',
  ExteriorPaint = 'EXTERIOR_PAINT',
  Ceiling = 'CEILING',
  Texturing = 'TEXTURING',
  ElectricalBreaker = 'ELECTRICAL_BREAKER',
  OutletSwitchWork = 'OUTLET_SWITCH_WORK',
  CeilingFan = 'CEILING_FAN',
  Lighting = 'LIGHTING',
  Specialty = 'SPECIALTY',
  Tree = 'TREE',
  Hardscape = 'HARDSCAPE',
  SidewalkAsphalt = 'SIDEWALK_ASPHALT',
  Chimney = 'CHIMNEY',
  Siding = 'SIDING',
  TileGroutRepair = 'TILE_GROUT_REPAIR',
  Carpet = 'CARPET',
  WoodFlooring = 'WOOD_FLOORING',
  Tile = 'TILE',
  Remodel = 'REMODEL',
  FoundationCrawlSpace = 'FOUNDATION_CRAWL_SPACE',
  GeneralContractor = 'GENERAL_CONTRACTOR',
  HvacInstall = 'HVAC_INSTALL',
  CleanAirVentsDuctwork = 'CLEAN_AIR_VENTS_DUCTWORK',
  Insulation = 'INSULATION',
  HvacRepair = 'HVAC_REPAIR',
  CabinetRepair = 'CABINET_REPAIR',
  CabinetInstallation = 'CABINET_INSTALLATION',
  TrimMolding = 'TRIM_MOLDING',
  CaulkingGrout = 'CAULKING_GROUT',
  Toilet = 'TOILET',
  WaterHeater = 'WATER_HEATER',
  Drain = 'DRAIN',
  SinkFaucet = 'SINK_FAUCET',
  LeakDetection = 'LEAK_DETECTION',
  SupplyLine = 'SUPPLY_LINE',
  RoofRepair = 'ROOF_REPAIR',
  RoofReplace = 'ROOF_REPLACE',
  SoffitFascia = 'SOFFIT_FASCIA',
  Gutter = 'GUTTER',
  Venting = 'VENTING',
  Solar = 'SOLAR'
}

export enum ServiceWindowType {
  Assessment = 'ASSESSMENT',
  Preferred = 'PREFERRED',
  Alternate_1 = 'ALTERNATE_1',
  Alternate_2 = 'ALTERNATE_2',
  Service = 'SERVICE'
}

export enum NoteReason {
  RepairCanWaitNotImmediate = 'REPAIR_CAN_WAIT_NOT_IMMEDIATE',
  AssessmentFeeTooExpensive = 'ASSESSMENT_FEE_TOO_EXPENSIVE',
  ShoppingForQuotes = 'SHOPPING_FOR_QUOTES',
  WantedToChooseServiceProvider = 'WANTED_TO_CHOOSE_SERVICE_PROVIDER',
  NotInterestedInCastleService = 'NOT_INTERESTED_IN_CASTLE_SERVICE',
  Other = 'OTHER'
}

export enum IssueStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export enum IssueClosedReason {
  ServiceCanceled = 'SERVICE_CANCELED',
  ServicePaid = 'SERVICE_PAID'
}

export enum SystemType {
  XactAnalysis = 'XACT_ANALYSIS',
  Alacnet = 'ALACNET'
}

export enum Market {
  Cleveland = 'CLEVELAND',
  Tampa = 'TAMPA',
  Austin = 'AUSTIN'
}

export enum State {
  Ak = 'AK',
  Al = 'AL',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY'
}

export enum FinancialTransactionType {
  Payment = 'PAYMENT',
  Refund = 'REFUND'
}

export enum FinancialTransactionProcessor {
  Braintree = 'BRAINTREE'
}

export enum FinancialInstrumentType {
  AndroidPayCard = 'ANDROID_PAY_CARD',
  ApplePayCard = 'APPLE_PAY_CARD',
  CreditCard = 'CREDIT_CARD',
  MasterpassCard = 'MASTERPASS_CARD',
  PaypalAccount = 'PAYPAL_ACCOUNT',
  SamsungPayCard = 'SAMSUNG_PAY_CARD',
  VenmoAccount = 'VENMO_ACCOUNT',
  VisaCheckoutCard = 'VISA_CHECKOUT_CARD'
}

export enum InvoiceStatus {
  Active = 'ACTIVE',
  Void = 'VOID'
}

export enum EstimateStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Dismissed = 'DISMISSED',
  Declined = 'DECLINED',
  Canceled = 'CANCELED',
  Void = 'VOID'
}

export enum Referral {
  NewCustomerFriendsFamilyPromo = 'NEW_CUSTOMER_FRIENDS_FAMILY_PROMO',
  PilotParticipantFriendsFamilyPromo = 'PILOT_PARTICIPANT_FRIENDS_FAMILY_PROMO',
  PgrEmployeePilotParticipant = 'PGR_EMPLOYEE_PILOT_PARTICIPANT',
  DirectMail = 'DIRECT_MAIL',
  SocialMedia = 'SOCIAL_MEDIA',
  GoogleSearch = 'GOOGLE_SEARCH',
  RadioAd = 'RADIO_AD',
  ZetaEmail = 'ZETA_EMAIL',
  Other = 'OTHER'
}

export enum CallDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

export enum NotificationType {
  SchedulingInProgress = 'SCHEDULING_IN_PROGRESS',
  Scheduled = 'SCHEDULED',
  EstimateReadyForReview = 'ESTIMATE_READY_FOR_REVIEW',
  ServiceAppointment = 'SERVICE_APPOINTMENT',
  ServiceComplete = 'SERVICE_COMPLETE'
}

export enum ActionUser {
  Expert = 'EXPERT'
}

export enum ActionType {
  UpdateIssue = 'UPDATE_ISSUE',
  CreateIssue = 'CREATE_ISSUE',
  UpdateUser = 'UPDATE_USER',
  CreateUser = 'CREATE_USER'
}

export type Feature = {
  __typename?: 'Feature';
  id: Scalars['ID'];
  name?: Maybe<FeatureFlag>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type Fee = {
  __typename?: 'Fee';
  id: Scalars['ID'];
  fee: Scalars['Float'];
  tax: Scalars['Float'];
  issue: Issue;
  services: Array<Service>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  grossCost: Scalars['Float'];
  subtotal: Scalars['Float'];
  salesTax: Scalars['Float'];
  customerCost: Scalars['Float'];
  totalPromoValue: Scalars['Float'];
  type: FeeType;
  promotionReference?: Maybe<Array<Maybe<PromotionReference>>>;
};

export type CreateFeeInput = {
  fee: Scalars['Float'];
  tax: Scalars['Float'];
  type: FeeType;
};

export type UpdateFeeInput = {
  fee: Scalars['Float'];
  tax: Scalars['Float'];
};

export type FinancialTransaction = {
  __typename?: 'FinancialTransaction';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  type: FinancialTransactionType;
  processor: FinancialTransactionProcessor;
  processorId: Scalars['String'];
  instrumentType: FinancialInstrumentType;
  instrumentDescription: Scalars['String'];
};

export type IssueNote = {
  __typename?: 'IssueNote';
  id?: Maybe<Scalars['String']>;
  author: Scalars['String'];
  description: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type Issue = {
  __typename?: 'Issue';
  id: Scalars['ID'];
  address: Address;
  title: Scalars['String'];
  description: Scalars['String'];
  status: IssueStatus;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  system: SystemType;
  verbalConsentGrantedAt?: Maybe<Scalars['DateTime']>;
  projects?: Maybe<Array<Maybe<Project>>>;
  notes?: Maybe<Array<Maybe<IssueNote>>>;
  closedReason?: Maybe<IssueClosedReason>;
  twilioTasks: Array<TwilioTask>;
  ActionsHistory?: Maybe<Array<Maybe<ActionHistory>>>;
};

export type Labor = {
  __typename?: 'Labor';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  price: Scalars['Float'];
  units: Scalars['String'];
  amount: Scalars['Float'];
};

export type Note = {
  __typename?: 'Note';
  id?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  reason?: Maybe<NoteReason>;
  User?: Maybe<Scalars['String']>;
  Issue?: Maybe<Scalars['String']>;
};

export type Part = {
  __typename?: 'Part';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  price: Scalars['Float'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CreditCard = {
  __typename?: 'CreditCard';
  cardType?: Maybe<Scalars['String']>;
  cardholderName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  default?: Maybe<Scalars['Boolean']>;
  expirationDate?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PayPalAccount = {
  __typename?: 'PayPalAccount';
  imageUrl?: Maybe<Scalars['String']>;
  payerId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  billingAgreementId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentMethods = {
  __typename?: 'PaymentMethods';
  creditCards?: Maybe<Array<Maybe<CreditCard>>>;
  paypalAccounts?: Maybe<Array<Maybe<PayPalAccount>>>;
};

export type Project = {
  __typename?: 'Project';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  xactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  services?: Maybe<Array<Maybe<Service>>>;
  fees?: Maybe<Array<Maybe<Fee>>>;
  appointments?: Maybe<Array<Maybe<ServiceWindow>>>;
  estimates?: Maybe<Array<Maybe<ServiceEstimate>>>;
  promotionReference?: Maybe<Array<Maybe<PromotionReference>>>;
  financialTransactions?: Maybe<Array<Maybe<FinancialTransaction>>>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
};

export type PromotionReference = {
  __typename?: 'PromotionReference';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  promotion: Promotion;
  redeemed: Scalars['Boolean'];
  totalDollarAmount: Scalars['Float'];
  userId: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  feeId?: Maybe<Scalars['String']>;
  estimateId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};

export type Promotion = {
  __typename?: 'Promotion';
  id: Scalars['ID'];
  title: Scalars['String'];
  market: Market;
  promoCode: Scalars['String'];
  amount: Scalars['Float'];
  amountType: DiscountType;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  terms: Scalars['String'];
  shortTerms?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityType: PromotionType;
  redemptions: Scalars['Int'];
  combinable: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
  maxDollarAmount?: Maybe<Scalars['Float']>;
};

export type ServiceCostOfGoodsSold = {
  __typename?: 'ServiceCostOfGoodsSold';
  id: Scalars['ID'];
  materials: Scalars['Float'];
  equipment: Scalars['Float'];
  labor: Scalars['Float'];
  partsTax: Scalars['Float'];
  laborTax: Scalars['Float'];
  taxesSubtotal: Scalars['Float'];
  melt: Scalars['Float'];
  marketBurden: Scalars['Float'];
  total: Scalars['Float'];
  miscFee?: Maybe<Scalars['Float']>;
  miscFeeReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceCostOfGoodsSoldCreateInput = {
  materials: Scalars['Float'];
  equipment: Scalars['Float'];
  labor: Scalars['Float'];
  partsTax: Scalars['Float'];
  laborTax: Scalars['Float'];
  marketBurden: Scalars['Float'];
};

export type ServiceCostOfGoodsSoldUpdateDataInput = {
  materials: Scalars['Float'];
  equipment: Scalars['Float'];
  labor: Scalars['Float'];
  partsTax: Scalars['Float'];
  laborTax: Scalars['Float'];
  marketBurden: Scalars['Float'];
};

export type ServiceEstimate = {
  __typename?: 'ServiceEstimate';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  description: Scalars['String'];
  parts?: Maybe<Scalars['String']>;
  partsSubtotal: Scalars['Float'];
  laborSubtotal: Scalars['Float'];
  totalCost: Scalars['Float'];
  discount: Scalars['Float'];
  grossCost: Scalars['Float'];
  subtotal: Scalars['Float'];
  salesTax: Scalars['Float'];
  customerCost: Scalars['Float'];
  totalPromoValue: Scalars['Float'];
  status: EstimateStatus;
  promotionReference?: Maybe<Array<Maybe<PromotionReference>>>;
  costOfGoodsSold?: Maybe<ServiceCostOfGoodsSold>;
  termsAcceptedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceFeedback = {
  __typename?: 'ServiceFeedback';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  homeowner?: Maybe<User>;
};

export type ServiceFeedbackCreateInput = {
  id?: Maybe<Scalars['ID']>;
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
};

export type ServiceFeedbackUpdateDataInput = {
  rating?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
};

export type ServiceGuarantee = {
  __typename?: 'ServiceGuarantee';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
};

export type ServiceGuaranteeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
};

export type ServiceGuaranteeUpdateDataInput = {
  description?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  invoiceNumber: Scalars['Int'];
  assessmentFee: Scalars['Float'];
  assessmentFeeTax: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  partsSubtotal: Scalars['Float'];
  laborSubtotal: Scalars['Float'];
  totalCost: Scalars['Float'];
  grossCost: Scalars['Float'];
  subtotal: Scalars['Float'];
  salesTax: Scalars['Float'];
  customerCost: Scalars['Float'];
  totalPromoValue: Scalars['Float'];
  discount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  status: InvoiceStatus;
};

export type ServiceMarket = {
  __typename?: 'ServiceMarket';
  id: Scalars['ID'];
  market: Market;
};

export type ServiceProvider = {
  __typename?: 'ServiceProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  markets: Array<ServiceMarket>;
};

export type ServiceProviderCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type ServiceProviderUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ServiceWindow = {
  __typename?: 'ServiceWindow';
  id: Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  type: ServiceWindowType;
};

export type ServiceWindowCreateInput = {
  id?: Maybe<Scalars['ID']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  type: ServiceWindowType;
};

export type ServiceWindowUpdateDataInput = {
  id?: Maybe<Scalars['ID']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  type: ServiceWindowType;
};

export type AdditionalAppointmentCreateUpdateDataInput = {
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
};

export type Service = {
  __typename?: 'Service';
  id: Scalars['ID'];
  category: ServiceCategory;
  type: ServiceType;
  typeDescription?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  status: ServiceStatus;
  serviceProvider?: Maybe<ServiceProvider>;
  technicianName?: Maybe<Scalars['String']>;
  technicianPhone?: Maybe<Scalars['String']>;
  estimates: Array<ServiceEstimate>;
  guarantee?: Maybe<ServiceGuarantee>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  statusReason?: Maybe<ServiceStatusReason>;
  estimateDeclinedReason?: Maybe<ServiceEstimateDeclinedReason>;
  statusNotes?: Maybe<Scalars['String']>;
  estimateDeclinedNotes?: Maybe<Scalars['String']>;
  feedback?: Maybe<ServiceFeedback>;
  appointments?: Maybe<Array<Maybe<ServiceWindow>>>;
  preAssessment?: Maybe<Scalars['Boolean']>;
  fee?: Maybe<Fee>;
  amountRequested: Scalars['Float'];
  amountDue: Scalars['Float'];
  financialTransactions: Array<FinancialTransaction>;
  arrivalTime?: Maybe<Scalars['DateTime']>;
  serviceCompletionDate?: Maybe<Scalars['DateTime']>;
  workApprovedDate?: Maybe<Scalars['DateTime']>;
  costOfGoodsSold?: Maybe<ServiceCostOfGoodsSold>;
  promotionReference?: Maybe<Array<Maybe<PromotionReference>>>;
  invoices?: Maybe<Array<Maybe<Invoice>>>;
};

export type ServiceStatusUpdateDataInput = {
  estimateDeclinedReason?: Maybe<ServiceEstimateDeclinedReason>;
  estimateDeclinedNotes?: Maybe<Scalars['String']>;
  estimateId?: Maybe<Scalars['ID']>;
};

export type TwilioTask = {
  __typename?: 'TwilioTask';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type TwilioTaskCreateInput = {
  id: Scalars['ID'];
};

export type HomeownerCall = {
  __typename?: 'HomeownerCall';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  taskId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  recordingId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  dateTime?: Maybe<Scalars['DateTime']>;
  taskQueueId?: Maybe<Scalars['String']>;
};

export type UserNoteCreateInput = {
  author: Scalars['String'];
  reason: NoteReason;
  description: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  addresses: Array<Address>;
  xactId?: Maybe<Scalars['String']>;
  cognitoId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  termsAcceptedAt?: Maybe<Scalars['DateTime']>;
  referral?: Maybe<Referral>;
  referralOther?: Maybe<Scalars['String']>;
  issues?: Maybe<Array<Maybe<Issue>>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  paymentMethods: PaymentMethods;
  promotionReference?: Maybe<Array<Maybe<PromotionReference>>>;
  ActionsHistory?: Maybe<Array<Maybe<ActionHistory>>>;
};

export type TokenUser = {
  __typename?: 'TokenUser';
  user: User;
  paymentToken?: Maybe<Scalars['String']>;
};

export type VerificationToken = {
  __typename?: 'VerificationToken';
  id: Scalars['ID'];
  token: Scalars['String'];
  userId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  redeemed: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  subscribeConsumer: Scalars['Boolean'];
  addNoteToIssue: Array<Maybe<IssueNote>>;
  closeIssue: Issue;
  addTwilioTaskToIssue: Issue;
  createFee?: Maybe<Fee>;
  updateFee?: Maybe<Fee>;
  createNote: Note;
  updateNote: Note;
  notifyUserSms: Scalars['Boolean'];
  createProject?: Maybe<Project>;
  updateProject?: Maybe<Project>;
  createPromotionReference: Promotion;
  deletePromotionReference: Scalars['Boolean'];
  createAppointment?: Maybe<ServiceWindow>;
  updateAppointment?: Maybe<ServiceWindow>;
  updateArrivalTime?: Maybe<Service>;
  updateServiceCompletionDate?: Maybe<Service>;
  completionDateReadyForReview?: Maybe<Service>;
  createServiceForProject?: Maybe<Service>;
  updateService?: Maybe<Service>;
  deleteServiceForIssue?: Maybe<Issue>;
  cancelService?: Maybe<Service>;
  createCostOfGoodsSold?: Maybe<ServiceCostOfGoodsSold>;
  updateCostOfGoodsSold?: Maybe<ServiceCostOfGoodsSold>;
  createEstimate?: Maybe<ServiceEstimate>;
  updateEstimate?: Maybe<ServiceEstimate>;
  estimateReadyForReview?: Maybe<Array<Maybe<Service>>>;
  voidEstimate?: Maybe<ServiceEstimate>;
  createGuarantee?: Maybe<ServiceGuarantee>;
  setOverride?: Maybe<Override>;
  setCogsOverride?: Maybe<ServiceCostOfGoodsSold>;
  updateServiceStatus?: Maybe<Service>;
  createUserNote: Array<Maybe<Note>>;
  createUserCall: HomeownerCall;
  createUserSMS: Scalars['Boolean'];
  createAddress: Address;
  updateAddress: Address;
  notifyUser: Scalars['Boolean'];
  createIssue: Issue;
  updateIssue: Issue;
  processTransaction: Service;
  processProjectTransaction: Project;
  processAutomatedDecline: Service;
  savePaymentMethod: User;
  savePaymentMethodFromToken: User;
  setDefaultPaymentMethod: Scalars['Boolean'];
  deletePaymentMethod: Scalars['Boolean'];
  acceptServiceEstimate?: Maybe<Service>;
  declineServiceEstimate?: Maybe<Service>;
  applyServiceAction?: Maybe<Array<Maybe<Service>>>;
  createUser: User;
  updateUser: User;
  setPasswordFromToken: User;
  sendEmailReceipt?: Maybe<Scalars['Boolean']>;
};


export type MutationSubscribeConsumerArgs = {
  data: ConsumerSubscribeInput;
};


export type MutationAddNoteToIssueArgs = {
  data: IssueNoteInput;
  issueId: Scalars['String'];
};


export type MutationCloseIssueArgs = {
  data: CloseIssueInput;
  issueId: Scalars['String'];
};


export type MutationAddTwilioTaskToIssueArgs = {
  data: TwilioTaskCreateInput;
  issueId: Scalars['ID'];
};


export type MutationCreateFeeArgs = {
  projectId: Scalars['ID'];
  data: CreateFeeInput;
  serviceId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateFeeArgs = {
  assessmentId: Scalars['ID'];
  data: UpdateFeeInput;
  serviceId?: Maybe<Scalars['ID']>;
};


export type MutationCreateNoteArgs = {
  userId: Scalars['String'];
  issueId?: Maybe<Scalars['String']>;
  data: ICreateOrUpdateNoteInput;
  twilioTask?: Maybe<Scalars['String']>;
};


export type MutationUpdateNoteArgs = {
  noteId: Scalars['String'];
  data: ICreateOrUpdateNoteInput;
};


export type MutationNotifyUserSmsArgs = {
  projectId: Scalars['String'];
  notificationType: NotificationType;
  serviceId?: Maybe<Scalars['String']>;
};


export type MutationCreateProjectArgs = {
  issueId: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateProjectArgs = {
  projectId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationCreatePromotionReferenceArgs = {
  data?: Maybe<ICreatePromotionReferenceInput>;
};


export type MutationDeletePromotionReferenceArgs = {
  data?: Maybe<IDeletePromotionReferenceInput>;
};


export type MutationCreateAppointmentArgs = {
  projectId: Scalars['ID'];
  data: CreateAppointmentInput;
  serviceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUpdateAppointmentArgs = {
  appointmentId: Scalars['ID'];
  data?: Maybe<UpdateAppointmentInput>;
  serviceIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationUpdateArrivalTimeArgs = {
  serviceId: Scalars['ID'];
  arrivalTime: Scalars['DateTime'];
};


export type MutationUpdateServiceCompletionDateArgs = {
  serviceId: Scalars['ID'];
  completionDate: Scalars['DateTime'];
};


export type MutationCompletionDateReadyForReviewArgs = {
  serviceId: Scalars['ID'];
};


export type MutationCreateServiceForProjectArgs = {
  data: CreateServiceInput;
  projectId: Scalars['String'];
};


export type MutationUpdateServiceArgs = {
  data: UpdateServiceInput;
  serviceId: Scalars['String'];
};


export type MutationDeleteServiceForIssueArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationCancelServiceArgs = {
  projectId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  data?: Maybe<CancelServiceInput>;
};


export type MutationCreateCostOfGoodsSoldArgs = {
  estimateId: Scalars['ID'];
  data: ServiceCostOfGoodsSoldCreateInput;
};


export type MutationUpdateCostOfGoodsSoldArgs = {
  id: Scalars['ID'];
  data: ServiceCostOfGoodsSoldUpdateInput;
};


export type MutationCreateEstimateArgs = {
  projectId: Scalars['ID'];
  serviceId?: Maybe<Scalars['ID']>;
  data: CreateUpdateEstimateInput;
};


export type MutationUpdateEstimateArgs = {
  estimateId: Scalars['ID'];
  data: CreateUpdateEstimateInput;
  serviceId?: Maybe<Scalars['ID']>;
};


export type MutationEstimateReadyForReviewArgs = {
  serviceIds: Array<Scalars['ID']>;
};


export type MutationVoidEstimateArgs = {
  estimateId: Scalars['ID'];
};


export type MutationCreateGuaranteeArgs = {
  data?: Maybe<GuaranteeCreateInput>;
};


export type MutationSetOverrideArgs = {
  xactId: Scalars['String'];
  override?: Maybe<ServiceOverrideStatus>;
  serviceId: Scalars['String'];
};


export type MutationSetCogsOverrideArgs = {
  xactId: Scalars['String'];
  serviceId: Scalars['String'];
  miscFee: Scalars['Float'];
  miscFeeReason: Scalars['String'];
};


export type MutationUpdateServiceStatusArgs = {
  id: Scalars['ID'];
  status: ServiceStatus;
  data?: Maybe<ServiceStatusUpdateDataInput>;
};


export type MutationCreateUserNoteArgs = {
  userId: Scalars['ID'];
  data: UserNoteCreateInput;
};


export type MutationCreateUserCallArgs = {
  data: ICreateUserCallInput;
};


export type MutationCreateUserSmsArgs = {
  data: ICreateUserSmsInput;
};


export type MutationCreateAddressArgs = {
  userId: Scalars['ID'];
  data: CreateUpdateAddressInput;
};


export type MutationUpdateAddressArgs = {
  addressId: Scalars['ID'];
  data: CreateUpdateAddressInput;
};


export type MutationNotifyUserArgs = {
  userId: Scalars['ID'];
};


export type MutationCreateIssueArgs = {
  data?: Maybe<IssueCreateInput>;
};


export type MutationUpdateIssueArgs = {
  data?: Maybe<IssueUpdateInput>;
};


export type MutationProcessTransactionArgs = {
  nonce?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  serviceId: Scalars['String'];
};


export type MutationProcessProjectTransactionArgs = {
  nonce?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
};


export type MutationProcessAutomatedDeclineArgs = {
  serviceId: Scalars['String'];
};


export type MutationSavePaymentMethodArgs = {
  nonce: Scalars['String'];
  termsAccepted: Scalars['Boolean'];
};


export type MutationSavePaymentMethodFromTokenArgs = {
  nonce: Scalars['String'];
  termsAccepted: Scalars['Boolean'];
  signUpToken: Scalars['String'];
};


export type MutationSetDefaultPaymentMethodArgs = {
  paymentMethodToken: Scalars['String'];
};


export type MutationDeletePaymentMethodArgs = {
  paymentMethodToken: Scalars['String'];
};


export type MutationAcceptServiceEstimateArgs = {
  estimateId: Scalars['ID'];
  acceptedEstimateTerms: Scalars['Boolean'];
};


export type MutationDeclineServiceEstimateArgs = {
  serviceId: Scalars['ID'];
  data: DeclineServiceEstimateInput;
};


export type MutationApplyServiceActionArgs = {
  projectId: Scalars['String'];
  data: Array<CalculatePaymentInput>;
  estimateDeclinedReason?: Maybe<ServiceEstimateDeclinedReason>;
  estimateDeclinedNotes?: Maybe<Scalars['String']>;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
};


export type MutationSetPasswordFromTokenArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSendEmailReceiptArgs = {
  email: Scalars['String'];
  serviceId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
};

export type ConsumerSubscribeInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  zip: Scalars['String'];
};

export type IssueNoteInput = {
  twilioTaskId?: Maybe<Scalars['String']>;
  author: Scalars['String'];
  description: Scalars['String'];
};

export type CloseIssueInput = {
  closedReason?: Maybe<IssueClosedReason>;
};

export type ICreateOrUpdateNoteInput = {
  author: Scalars['String'];
  description: Scalars['String'];
  reason?: Maybe<NoteReason>;
};

export type Query = {
  __typename?: 'Query';
  getPromotions: Array<Promotion>;
  getAllServiceGuarantees?: Maybe<Array<Maybe<ServiceGuarantee>>>;
  getOverride?: Maybe<Array<Maybe<Override>>>;
  getCogsOverride?: Maybe<Array<Maybe<Service>>>;
  getServiceProviders?: Maybe<Array<ServiceProvider>>;
  getTokenFromUser?: Maybe<VerificationToken>;
  getTokenStatus?: Maybe<VerificationToken>;
  getUserPaymentMethodStatus: Scalars['String'];
  getFeatures?: Maybe<Array<Maybe<Feature>>>;
  getIssue?: Maybe<Issue>;
  getIssues?: Maybe<Array<Maybe<Issue>>>;
  getIssueForUserById?: Maybe<Issue>;
  getPaymentToken: Scalars['String'];
  getPaymentTokenForPaymentMethod: Scalars['String'];
  calculateServiceAction: Array<CalculatePaymentResult>;
  searchUser?: Maybe<Array<Maybe<User>>>;
  getUsers?: Maybe<Array<User>>;
  getCurrentUser: User;
  getUserFromToken?: Maybe<TokenUser>;
  getUser: User;
};


export type QueryGetOverrideArgs = {
  xactId: Scalars['String'];
};


export type QueryGetCogsOverrideArgs = {
  xactId: Scalars['String'];
};


export type QueryGetTokenFromUserArgs = {
  userId: Scalars['ID'];
};


export type QueryGetTokenStatusArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUserPaymentMethodStatusArgs = {
  userId: Scalars['ID'];
};


export type QueryGetIssueArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetIssuesArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryGetIssueForUserByIdArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCalculateServiceActionArgs = {
  projectId: Scalars['String'];
  data: Array<CalculatePaymentInput>;
};


export type QuerySearchUserArgs = {
  searchParam?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};


export type QueryGetUserFromTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type ICreatePromotionReferenceInput = {
  estimateId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  feeId?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  userId: Scalars['String'];
  promotionId: Scalars['String'];
};

export type IDeletePromotionReferenceInput = {
  promotionReferenceId: Scalars['String'];
  estimateId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  feeId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  projectId: Scalars['String'];
};

export type CreateAppointmentInput = {
  appointment: ServiceWindowUpdateDataInput;
  serviceProviderId: Scalars['ID'];
  technicianName?: Maybe<Scalars['String']>;
  technicianPhone?: Maybe<Scalars['String']>;
};

export type UpdateAppointmentInput = {
  appointment: ServiceWindowUpdateDataInput;
  serviceProviderId: Scalars['ID'];
  technicianName?: Maybe<Scalars['String']>;
  technicianPhone?: Maybe<Scalars['String']>;
};

export type CreateServiceInput = {
  category: ServiceCategory;
  type: ServiceType;
  typeDescription?: Maybe<Scalars['String']>;
};

export type UpdateServiceInput = {
  category: ServiceCategory;
  type: ServiceType;
  typeDescription?: Maybe<Scalars['String']>;
};

export type CancelServiceInput = {
  statusReason: ServiceStatusReason;
  statusNotes?: Maybe<Scalars['String']>;
  preAssessment: Scalars['Boolean'];
};

export type ServiceCostOfGoodsSoldUpdateInput = {
  materials: Scalars['Float'];
  equipment: Scalars['Float'];
  labor: Scalars['Float'];
  partsTax: Scalars['Float'];
  laborTax: Scalars['Float'];
  marketBurden: Scalars['Float'];
};

export type CreateUpdateEstimateInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  parts?: Maybe<Scalars['String']>;
  partsSubtotal: Scalars['Float'];
  laborSubtotal: Scalars['Float'];
  salesTax: Scalars['Float'];
  discount: Scalars['Float'];
};

export type GuaranteeCreateInput = {
  duration: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Override = {
  __typename?: 'Override';
  id: Scalars['ID'];
  type: ServiceType;
  typeDescription?: Maybe<Scalars['String']>;
  override?: Maybe<ServiceOverrideStatus>;
};

export type ServiceProviderInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type ICreateUserCallInput = {
  taskId?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  recordingId?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  dateTime?: Maybe<Scalars['DateTime']>;
  taskQueueId?: Maybe<Scalars['String']>;
  direction?: Maybe<CallDirection>;
};

export type ICreateUserSmsInput = {
  messageServiceId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  origPhoneNumber?: Maybe<Scalars['String']>;
  termPhoneNumber?: Maybe<Scalars['String']>;
  direction?: Maybe<CallDirection>;
  numberOfSegments?: Maybe<Scalars['Int']>;
  mmsFileNames?: Maybe<Scalars['String']>;
  deliveryStatus?: Maybe<Scalars['String']>;
  handlingService?: Maybe<Scalars['String']>;
  messageText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  retain?: Maybe<Scalars['Boolean']>;
};


export type IssueCreateInput = {
  userId: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  system?: Maybe<SystemType>;
  addressId: Scalars['String'];
  initiatingAgent: Scalars['String'];
};

export type IssueUpdateInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  system?: Maybe<SystemType>;
  addressId: Scalars['String'];
  initiatingAgent: Scalars['String'];
};

export type DeclineServiceEstimateInput = {
  estimateDeclinedReason: ServiceEstimateDeclinedReason;
  estimateDeclinedNotes?: Maybe<Scalars['String']>;
};

export type CalculatePaymentInput = {
  serviceId: Scalars['String'];
  status: Scalars['String'];
  estimateId?: Maybe<Scalars['String']>;
};

export type CalculatePaymentResult = {
  __typename?: 'CalculatePaymentResult';
  serviceId: Scalars['String'];
  status: Scalars['String'];
  estimateId?: Maybe<Scalars['String']>;
  customerCost: Scalars['Float'];
};

export type UserCreateInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  referral?: Maybe<Referral>;
  referralOther?: Maybe<Scalars['String']>;
  initiatingAgent: Scalars['String'];
  address?: Maybe<CreateUpdateAddressInput>;
};

export type UserUpdateInput = {
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  referral?: Maybe<Referral>;
  initiatingAgent: Scalars['String'];
  referralOther?: Maybe<Scalars['String']>;
};
