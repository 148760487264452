// Color names come from http://chir.ag/projects/name-that-color/
export const palette = {
  white: '#FFFFFF',
  black: '#000000',
  grenadier: '#D24702',
  linen: '#FBEDE6',
  mystic: '#EAF1F1',
  firefly: '#0E2F2E',
  geyser: '#D4E3E3',
  elm: '#1A7674',
  coal: '#323232',
  slate: '#363636',
  dove: '#6B6B6B',
  apple: '#E4F5EE',
  jungle: '#22A970',
  silver: '#ADADAD',
  dust: 'rgba(0, 0, 0, 0.15)',
  edward: '#A2ACAA',
  ocean: '#3BA676',
  oceanRibbon: 'rgba(59, 166, 118, 0.1)',
  coralRed: '#FF4040',
  marine: '#14313A',
  elephant: '#0E3745',
  caribbean: '#18657D',
  caribbeanTransparent: 'rgba(24, 101, 125, .4)',
  sawdust: '#F4F0E9',
  stone: '#D3CFC8',
  inactiveGrey: '#A2ACAA',
  tide: '#B6B2AB',
  ultraMarine: '#071D24',
  menuBackground: 'rgba(7, 29, 36, 0.97)',
  concrete: '#818588',
  cement: '#E9EFF1',
  freon: '#85BFD0',
  tarnishedGold: '#836A25',
  geyserMUI: '#DDE2E4',
  granite: '#4E5153',
  polymer: '#BBF2D3',
  dustMUI: '#FFECE8',
  electricalWire: '#D32E06',
  pvcWhite: '#F8F9FA',
  brick: '#EB8870',
  sand: '#FDFBF7',
  onahau: '#C9EFFF',
  nevada: '#6D7072',
  gold: '#c99912'
};

export const iconStyles = {
  strokeWidth: 1,
  size: 24
};

export const fontWeights = {
  extraLight: 200,
  light: 300,
  regular: 400,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800
};
