import { makeStyles, Theme } from '@material-ui/core';
import { palette } from 'styles/global';

export const StickyHeaderStyles = makeStyles((theme: Theme) => ({
  appBarGrid: {
    padding: '12px 16px',

    '& .MuiTouchRipple-root': {
      color: palette.caribbean
    },

    '& .contactUsText': {
      fontFamily: 'Manrope',
      fontWeight: 600,
      color: palette.sawdust,
      marginLeft: 4,
      textTransform: 'none'
    }
  },
  floatingMenuRoot: {
    width: 98,
    right: 20,
    top: 66
  },
  contactUsMenuButton: {
    padding: '0 18px',
    backgroundColor: palette.caribbean,
    height: 34,
    minHeight: 34,
    width: 98,
    minWidth: 98,

    '&:hover': {
      backgroundColor: palette.caribbean
    },

    '& .contactUsMenuButtonText': {
      fontFamily: 'Manrope',
      lineHeight: '18px',
      color: palette.pvcWhite,
      marginLeft: 4,
      letterSpacing: '1px'
    }
  }
}));
