import { Theme, makeStyles } from '@material-ui/core';
import { palette } from 'styles/global';

export const EstimatesBreadcrumbStyles = makeStyles((theme: Theme) => ({
  EstimatesBreadcrumbWrapper: {
    margin: '8px 20px 0'
  },
  confirm: {
    margin: 0,
    borderBottom: `1px solid ${palette.tide}`,

    '& $navConfirm': { color: palette.tarnishedGold },
    '& $navReview': {
      color: palette.concrete
    }
  },
  EstimatesBreadcrumb: {
    paddingBottom: 8
  },
  navText: {
    textTransform: 'uppercase',
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: 1
  },
  navReview: {
    color: palette.tarnishedGold
  },
  navSlashes: { height: 18 },
  navConfirm: { color: palette.concrete }
}));
