import React, { FC, useState } from 'react';
import {
  Collapse,
  Grid,
  Step,
  StepConnector,
  StepContent,
  StepContentProps,
  StepIcon,
  StepLabel,
  Stepper,
  withStyles
} from '@material-ui/core';
import { H5, MUIButton } from 'components';
import { palette, fontWeights } from 'styles/global';
import { pushGAEvent } from 'utils';
import { StepperStep } from 'core/interfaces';

interface CastleStepperProps {
  onComplete: () => void;
  onCompleteLabel?: string;
  onCompleteRef?: React.RefObject<HTMLButtonElement> | null;
  steps: StepperStep[];
}

export const CastleStepper: FC<CastleStepperProps> = props => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const lastStep = props.steps.length - 1;

  return (
    <CustomStepper
      activeStep={activeStep}
      orientation="vertical"
      connector={<CustomStepConnector />}
    >
      {props.steps.map((step, i) => (
        <Step key={i} expanded={!!step.persistedmount}>
          <StepLabel StepIconComponent={CustomStepIcon}>
            <H5>{step.label}</H5>
          </StepLabel>
          <CustomStepContent persistedmount={step.persistedmount} active={activeStep === i}>
            <Grid container direction="column" spacing={3}>
              <Grid item>{step.content}</Grid>
              <Grid item container direction="row" spacing={2}>
                {i > 0 && (
                  <Grid item>
                    <MUIButton
                      id={step.idBack || 'back-btn'}
                      label="Back"
                      type="outline"
                      onClick={() => {
                        setActiveStep(activeStep - 1);
                        step.idBack && pushGAEvent({ id: step.idBack });
                      }}
                    />
                  </Grid>
                )}
                <Grid item>
                  {i < lastStep ? (
                    <MUIButton
                      id={step.idNext || 'next-btn'}
                      label="Next"
                      onClick={() => {
                        setActiveStep(activeStep + 1);
                        step.idNext && pushGAEvent({ id: step.idNext });
                      }}
                      disabled={step.disabledNext}
                    />
                  ) : (
                    <MUIButton
                      id={step.idNext || 'finish-btn'}
                      ref={props.onCompleteRef}
                      label={props.onCompleteLabel || 'Finish'}
                      onClick={props.onComplete}
                      disabled={step.disabledNext}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item />
            </Grid>
          </CustomStepContent>
        </Step>
      ))}
    </CustomStepper>
  );
};

// #region Custom Stepper Components

const CustomStepper = withStyles({ root: { padding: '0px', backgroundColor: palette.pvcWhite } })(
  Stepper
);

const CustomStepIcon = withStyles({
  root: {
    color: palette.concrete,
    width: 30,
    height: 30,
    '&$active': {
      color: palette.tarnishedGold
    }
  },
  text: {
    fontFamily: 'Manrope',
    fontWeight: fontWeights.bold
  },
  active: {}
})(StepIcon);

const CustomStepContent = withStyles({
  root: {
    marginTop: '-10px',
    paddingTop: '20px',
    marginLeft: '14px',
    paddingLeft: '24px',
    borderColor: palette.marine
  }
})((props: StepContentProps & { persistedmount?: string; active: boolean }) => (
  <StepContent {...props}>
    {!!props.persistedmount ? (
      <Collapse in={props.active}>{props.children}</Collapse>
    ) : (
      props.children
    )}
  </StepContent>
));

const CustomStepConnector = withStyles({
  vertical: { padding: 0 },
  lineVertical: { marginLeft: '2px' },
  line: { borderColor: palette.marine }
})(StepConnector);

// #endregion
