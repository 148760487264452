// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  oauth: {}
};

export default awsmobile;
