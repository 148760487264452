import React, { createContext, useState, useCallback } from 'react';

import { pushBannerInformation } from 'utils';
import { BannerState } from 'data/constants';

export interface Banner {
  title: string;
  link: string;
  newTab?: boolean;
  desc: string;
  type: 'success' | 'error' | 'info';
  dismissStorageID?: string;
  closed?: boolean;
}

export type IBannerOptionFunc = (obj: any) => Banner;

interface BannerContextProps {
  banners: Banner[];
  pushBanner: (banner: Banner) => void;
  closeBanner: () => void;
  clearBanners: (all?: boolean) => void;
}

export const BannerContext = createContext<BannerContextProps>({} as BannerContextProps);

type BannerProviderProps = {
  children: React.ReactNode | JSX.Element;
};

export const BannerProvider = ({ children }: BannerProviderProps) => {
  const [banners, setBanners] = useState<Banner[]>([]);

  const pushBanner = useCallback(
    (newBanner: Banner) => {
      if (localStorage.getItem(newBanner?.dismissStorageID || '') === BannerState.DISMISSED) return;
      pushBannerInformation(newBanner.title, newBanner.desc);
      setBanners([newBanner, ...banners]);
    },
    [setBanners, banners]
  );

  const closeBanner = useCallback(() => {
    const newBanners = banners.map(singleBanner => ({ ...singleBanner }));
    const newBannerIndex = newBanners.findIndex(x => !x.closed);
    if (newBannerIndex >= 0) {
      newBanners[newBannerIndex].closed = true;
      pushBannerInformation(newBanners[newBannerIndex].title, newBanners[newBannerIndex].desc);
      setBanners(newBanners);
    }
  }, [banners, setBanners]);

  const clearBanners = useCallback(
    (all = false) => setBanners(all ? [] : banners.filter(x => x.dismissStorageID)),
    [setBanners, banners]
  );

  return (
    <BannerContext.Provider value={{ banners, pushBanner, closeBanner, clearBanners }}>
      {children}
    </BannerContext.Provider>
  );
};

export const BannerConsumer = BannerContext.Consumer;
