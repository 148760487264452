import { Theme, makeStyles } from '@material-ui/core';
import { palette } from 'styles/global';

export const EstimatesStepperStyles = makeStyles((theme: Theme) => ({
  estimateStepper: {
    padding: '0 20px'
  },
  headline: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: 24,
    color: `${palette.ultraMarine}`
  },
  estimateGrossCost: {
    fontWeight: 700,
    fontSize: 14,
    color: `${palette.concrete}`,
    textDecoration: 'line-through',
    fontFamily: 'Manrope'
  },
  estimateSubtotal: {
    fontWeight: 700,
    fontSize: 18,
    color: `${palette.ultraMarine}`,
    fontFamily: 'Manrope'
  },
  subHeadline: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 700,
    fontSize: 16,
    color: `${palette.ultraMarine}`
  },
  mobileStepperRoot: {
    padding: '8px 0 0 0',
    fontSize: 12,
    fontFamily: 'Manrope',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: palette.marine,
    borderTop: `1px solid ${palette.tide}`,
    marginBottom: 30
  },
  buttonRoot: {
    fontSize: 12,
    fontFamily: 'Manrope',
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: 0,
    color: palette.marine,
    '& svg': {
      fill: '#836A25'
    },
    '&[disabled]': {
      color: '#F8F9FA',
      '& svg': {
        fill: '#F8F9FA'
      }
    }
  },

  btnAccept: {
    margin: '8px 0',
    fontFamily: 'Manrope',
    fontSize: 18,
    fontWeight: 700,
    color: palette.pvcWhite,
    backgroundColor: palette.ultraMarine,
    border: `2px solid ${palette.pvcWhite}`,
    borderRadius: 6,
    boxShadow: `0 0 0 0px ${palette.pvcWhite}, 0 0 0 2px ${palette.pvcWhite} !important`,
    width: '100%',
    '&:disabled': {
      color: palette.concrete,
      backgroundColor: palette.cement
    },
    '&:hover': {
      backgroundColor: palette.ultraMarine,
      boxShadow: `0 0 0 0px ${palette.pvcWhite}, 0 0 0 2px ${palette.tarnishedGold} !important`
    },
    '&:focus': {
      backgroundColor: palette.ultraMarine,
      boxShadow: `0 0 0 0px ${palette.pvcWhite}, 0 0 0 2px ${palette.tarnishedGold} !important`
    },
    '& .MuiTouchRipple-root': {
      color: palette.caribbean
    },
    '& .MuiButton-label': {
      zIndex: 2
    },
    '& span': {
      color: palette.pvcWhite,
      padding: 0,
      margin: 0
    }
  },

  btnAccepted: {
    backgroundColor: palette.tarnishedGold,
    '&:hover': {
      backgroundColor: palette.tarnishedGold,
      boxShadow: `0 0 0 0px ${palette.pvcWhite}, 0 0 0 2px ${palette.ultraMarine} !important`
    },
    '&:focus': {
      backgroundColor: palette.tarnishedGold,
      boxShadow: `0 0 0 0px ${palette.pvcWhite}, 0 0 0 2px ${palette.ultraMarine} !important`
    },
    '& .MuiTouchRipple-root': {
      color: palette.gold
    }
  },

  buttonAcceptRipple: {
    opacity: 0.4,
    transform: 'scale(1)',
    animation: `$accept-ripple-effect 550ms ${theme.transitions.easing.easeInOut}`
  },
  '@keyframes accept-ripple-effect': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0.1
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0.4
    }
  },

  btnDecline: {
    fontFamily: 'Manrope',
    fontSize: 18,
    fontWeight: 700,
    color: palette.ultraMarine,
    backgroundColor: palette.pvcWhite,
    border: `2px solid ${palette.ultraMarine}`,
    borderRadius: 6,
    boxShadow: `0 0 0 2px ${palette.pvcWhite}, 0 0 0 4px ${palette.pvcWhite} !important`,
    padding: '4px 6px',
    width: 'calc(100% - 4px)',
    display: 'block',
    margin: '8px auto',

    '&:hover': {
      color: palette.ultraMarine,
      backgroundColor: palette.pvcWhite,
      boxShadow: `0 0 0 2px ${palette.pvcWhite}, 0 0 0 4px ${palette.tarnishedGold} !important`
    },
    '&:focus': {
      color: palette.ultraMarine,
      backgroundColor: palette.pvcWhite,
      boxShadow: `0 0 0 2px ${palette.pvcWhite}, 0 0 0 4px ${palette.tarnishedGold} !important`
    },
    '& .MuiTouchRipple-root': {
      color: palette.cement
    },
    '& .MuiButton-label': {
      position: 'relative',
      zIndex: 2
    }
  },
  btnDeclined: {
    color: palette.tarnishedGold,
    border: `2px solid ${palette.tarnishedGold}`,
    boxShadow: `0 0 0 2px ${palette.pvcWhite}, 0 0 0 4px ${palette.pvcWhite} !important`,
    padding: '4px 6px',
    width: 'calc(100% - 4px)',
    display: 'block',
    margin: '8px auto',
    '&:hover': {
      color: palette.tarnishedGold,
      boxShadow: `0 0 0 2px ${palette.pvcWhite}, 0 0 0 4px ${palette.ultraMarine} !important`
    },
    '&:focus': {
      color: palette.tarnishedGold,
      boxShadow: `0 0 0 2px ${palette.pvcWhite}, 0 0 0 4px ${palette.ultraMarine} !important`
    },
    '& .MuiTouchRipple-root': {
      color: palette.cement
    }
  },
  buttonDeclineRipple: {
    opacity: 1,
    transform: 'scale(1)',
    animation: `$decline-ripple-effect 550ms ${theme.transitions.easing.easeInOut}`
  },
  '@keyframes decline-ripple-effect': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0.1
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1
    }
  },

  btnSkip: {
    margin: '8px 0',
    fontFamily: 'Manrope',
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    color: palette.caribbean,
    background: 'none',

    '&:hover': {
      background: 'none'
    }
  }
}));
