import React, { FC, Fragment } from 'react';

import { DollarText, H5, P } from 'components';
import { palette } from 'styles/global';
import { DashboardServiceProviderHistory } from 'utils';
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import { Box, Button, Card, CardActions, CardContent, Grid } from '@material-ui/core';
import { SERVICE_CATEGORIES, SERVICE_TYPES } from 'data/constants';
import { Text } from 'components/Shared';
import { ServiceCategory, ServiceStatus, ServiceType } from 'generated/graphql';

interface CardDashboardActionCta {
  id: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
}

interface CardDashboardAction {
  status: string;
  cta?: CardDashboardActionCta;
}

interface ICardServiceProviderHistory {
  id: string;
  tile: DashboardServiceProviderHistory;
  action?: CardDashboardAction;
}

export const CardServiceProviderHistory: FC<ICardServiceProviderHistory> = ({
  action,
  id,
  tile
}) => {
  const { subtotal, serviceProviderName, services, grossCost } = tile;
  const total = subtotal === null ? '- - -' : (subtotal as number);

  const getServiceText = (service: {
    type: ServiceType;
    category: ServiceCategory;
    lastUpdated: string;
    status: ServiceStatus;
  }) => {
    return service.type === ServiceType.SmallWork || service.type === ServiceType.NewServiceOffering
      ? SERVICE_CATEGORIES[service.category]
      : SERVICE_TYPES[service.type];
  };

  // useEffect(() => pushDashboardEstimateCard({ ...estimate, cta }));
  return (
    <Box margin="-8px" paddingY="8px">
      <Card
        id={id}
        style={{ backgroundColor: palette.pvcWhite, borderRadius: 8, margin: '8px' }}
        elevation={3}
      >
        <CardContent style={{ padding: '16px 20px' }}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <H5 styleOverride={{ color: palette.marine }}>{serviceProviderName}</H5>
              {services.map((s, idx) => (
                <Fragment key={`text-${idx}`}>
                  <Text customClass="body" style={{ paddingTop: 3 }}>
                    {getServiceText(s)} service
                  </Text>
                </Fragment>
              ))}
            </Box>
            <Box marginLeft="16px" display="flex" flexDirection="column" alignItems="flex-end">
              {(subtotal !== undefined || subtotal !== null) && (
                <>
                  <DollarText textColor={palette.marine} total={total} />
                  {total === 0 && (grossCost || grossCost === 0) && (
                    <DollarText
                      textColor={palette.concrete}
                      fontSize={14}
                      lineThrough
                      total={grossCost}
                    />
                  )}
                </>
              )}
            </Box>
          </Box>
        </CardContent>
        <CardActions style={{ padding: '0px' }}>
          <Grid
            container
            justify="space-between"
            alignItems="baseline"
            style={{ borderTop: `1px solid ${palette.stone}`, padding: '4px 12px 4px 20px' }}
          >
            <P mui muiType="body-bold" styleOverride={{ color: palette.marine }}>
              <i>{action?.status}</i>
            </P>
            <Button
              id={action?.cta?.id}
              endIcon={<ChevronRightSharpIcon style={{ color: palette.caribbean }} />}
              onClick={action?.cta?.onClick}
              style={{ visibility: action?.cta ? 'visible' : 'hidden' }}
            >
              <P mui muiType="tertiary-text-button" styleOverride={{ marginRight: '-8px' }}>
                {action?.cta?.label || 'N/A'}
              </P>
            </Button>
          </Grid>
        </CardActions>
      </Card>
    </Box>
  );
};
