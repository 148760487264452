import React, { useState, useContext, useLayoutEffect } from 'react';
import { navigate } from '@reach/router';
import { Box, ButtonBase, Drawer, IconButton, makeStyles, Theme } from '@material-ui/core';
import { ChevronRightSharp, CloseSharp } from '@material-ui/icons';

import { UserContext } from 'context';
import { MenuItem, P, H5 } from 'components';
import { MAX_WIDTH, MAX_WIDTH_PX } from 'data/constants';
import { BASE_URL } from 'data/env-vars';
import { palette } from 'styles/global';
import { pushGAEvent } from 'utils';

interface IAccountItemProps {
  name: string;
  toggleMenu: (arg0: boolean) => void;
}

const AccountItem = ({ name, toggleMenu }: IAccountItemProps) => (
  <ButtonBase
    id="view-account-menu-lbl"
    onClick={(e) => {
      toggleMenu(false);
      navigate(`${BASE_URL}/account`);
      pushGAEvent({ id: e.currentTarget.id });
    }}
    style={{ maxWidth: '350px' }}
  >
    <Box display="flex" flexDirection="column" padding="0px 32px 0px 20px" flexGrow={1}>
      <P mui muiType="overline-small" styleOverride={{ color: palette.sawdust, textAlign: 'left' }}>
        Your Account
      </P>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
      >
        <H5 styleOverride={{ color: palette.sawdust, textAlign: 'left' }}>{name}</H5>
        <ChevronRightSharp style={{ color: palette.sawdust, paddingLeft: '5px' }} />
      </Box>
      <Box height={12} borderBottom={`1px solid ${palette.stone}`} />
    </Box>
  </ButtonBase>
);

interface IUserMenuProps {
  toggleMenu: (arg0: boolean) => void;
  showMenu: boolean;
}

const useDrawerStyles = makeStyles<Theme, { width: number }>({
  paper: (props) => ({
    height: '100%',
    width: '80%',
    maxWidth: '475px',
    backgroundColor: palette.menuBackground,
    left: props.width > MAX_WIDTH ? `calc((100vw - ${MAX_WIDTH_PX}) / 2)` : 0
  })
});

export const UserMenu = ({ toggleMenu, showMenu }: IUserMenuProps) => {
  const { user, signOutUser } = useContext(UserContext);
  const { firstName, lastName } = user || {};
  const [width, setWidth] = useState(0);

  // This will re-render the app when the window width changes
  useLayoutEffect(() => {
    const updateSize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const drawerClasses = useDrawerStyles({ width });

  return (
    <Drawer classes={drawerClasses} open={showMenu} onClose={() => toggleMenu(false)}>
      <Box display="flex" justifyContent="flex-end" style={{ padding: '16px' }}>
        <IconButton
          size="small"
          aria-label="close menu"
          id="menu-close-btn"
          onClick={(e) => {
            pushGAEvent({ id: e.currentTarget.id });
            toggleMenu(false);
          }}
          style={{ color: palette.sawdust }}
        >
          <CloseSharp />
        </IconButton>
      </Box>
      <AccountItem name={`${firstName} ${lastName}`} toggleMenu={toggleMenu} />
      <Box padding="32px 20px">
        <MenuItem id="home-menu-lbl" title="Home" path="" toggleMenu={toggleMenu} />
        <Box height={20} />
        <MenuItem
          id="payment-services-menu-lbl"
          title="Payment History"
          path="history/services"
          toggleMenu={toggleMenu}
        />
        <Box height={48} />
        <MenuItem
          id="privacy-menu-lbl"
          title="Privacy Policy"
          path="app/privacy"
          newTab
          toggleMenu={toggleMenu}
        />
        <Box height={20} />
        <MenuItem
          id="terms-menu-lbl"
          title="Terms & Conditions"
          path="app/terms"
          newTab
          toggleMenu={toggleMenu}
        />
        <Box height={20} />
        <MenuItem
          id="covid-menu-lbl"
          title="Castle and COVID-19"
          path="app/covid19"
          newTab
          toggleMenu={toggleMenu}
        />
        <Box height={20} />
        <MenuItem
          id="payment-term-menu-lbl"
          title="Payment Terms & Conditions"
          path="app/payment-terms"
          newTab
          toggleMenu={toggleMenu}
        />
        <Box height={40} />
        <ButtonBase
          id="logout-menu-lbl"
          onClick={async (e) => {
            pushGAEvent({ id: e.currentTarget.id });
            await signOutUser();
            navigate(`${BASE_URL}/login`);
          }}
        >
          <P
            mui
            muiType="tertiary-text-button"
            styleOverride={{ color: palette.gold, textTransform: 'uppercase' }}
          >
            Log out
          </P>
        </ButtonBase>
        <Box height={48} />
        <Box display="flex" alignItems="baseline">
          <P mui muiType="body" styleOverride={{ color: palette.stone, paddingRight: '0.25em' }}>
            Learn more at
          </P>
          <ButtonBase
            id="consumer-site-menu-lbl"
            onClick={(e) => {
              pushGAEvent({ id: e.currentTarget.id });
              window.open(window.location.origin);
            }}
          >
            <P mui muiType="body-2-large-bold" styleOverride={{ color: palette.gold }}>
              CallCastle.com
            </P>
          </ButtonBase>
        </Box>
        <P mui muiType="caption-body" styleOverride={{ color: palette.pvcWhite }}>
          &copy; {new Date().getFullYear()} 358 Ventures, patent pending.
        </P>
      </Box>
    </Drawer>
  );
};
