import React, { ReactNode } from 'react';
import { palette } from 'styles/global';

interface IH3Props {
  children?: ReactNode;
  styleOverride?: Object;
  id?: string;
}

export const H4 = ({ children, styleOverride, id }: IH3Props) => (
  <h4 id={id} style={{ ...styles, ...styleOverride }}>
    {children}
  </h4>
);

const styles = {
  fontFamily: 'Assistant',
  fontWeight: 700,
  fontSize: 16,
  color: palette.coal,
  lineHeight: '21px',
  margin: 0
};
