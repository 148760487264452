import React, { ReactNode } from 'react';
import { palette, fontWeights } from 'styles/global';

interface IH2Props {
  children?: ReactNode;
  styleOverride?: Object;
  type?: '1' | '2';
  id?: string;
  mui?: boolean;
}

export const H2 = ({ children, type = '1', styleOverride, id, mui }: IH2Props) => {
  const style = mui ? styles.mui : styles.grommet[type];
  return (
    <h2 id={id} style={{ margin: 0, ...style, ...styleOverride }}>
      {children}
    </h2>
  );
};

const styles = {
  grommet: {
    1: {
      fontFamily: 'Assistant',
      fontWeight: 600,
      fontSize: 24,
      color: palette.elm,
      lineHeight: '28px',
      letterSpacing: '-0.25px'
    },
    2: {
      fontFamily: 'Assistant',
      fontWeight: 600,
      fontSize: 24,
      color: palette.firefly,
      textAlign: 'center' as 'center',
      lineHeight: '28px',
      letterSpacing: '-0.25px'
    }
  },
  mui: {
    fontFamily: 'Manrope',
    fontWeight: fontWeights.semiBold,
    fontSize: 24,
    color: palette.marine,
    lineHeight: '28px',
    letterSpacing: '-0.02em'
  }
};
