import { makeStyles, Theme } from '@material-ui/core';
import { palette } from 'styles/global';
import { MAX_WIDTH, MAX_WIDTH_PX } from 'data/constants';

export const WhatToExpectModalOverrideStyles = makeStyles<Theme, { width: number }>(
  (theme: Theme) => ({
    paperAnchorRight: (props) => ({
      right: props.width > MAX_WIDTH ? `calc((100vw - ${MAX_WIDTH_PX}) / 2)` : 0
    })
  })
);

export const WhatToExpectModalStyles = makeStyles((theme: Theme) => ({
  openLink: {
    boxShadow: 'none',
    fontFamily: 'Manrope',
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: 12,
    border: 'none',
    backgroundColor: 'none',
    color: palette.caribbean,
    margin: '0 auto',
    width: 230,
    display: 'flex',
    padding: '12px 0',
    '&:hover': {
      background: 'none',
      boxShadow: 'none'
    },
    '&:active': {
      background: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      background: 'none',
      boxShadow: 'none'
    }
  },
  openLinkFilled: {
    boxShadow: 'none',
    fontFamily: 'Manrope',
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: 12,
    border: 'none',
    backgroundColor: palette.cement,
    color: palette.caribbean,
    margin: '0 auto',
    width: '100%',
    borderRadius: 0,
    padding: '12px 0',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 'none'
    },
    '&:active': {
      background: palette.cement,
      boxShadow: 'none'
    },
    '&:focus': {
      background: palette.cement,
      boxShadow: 'none'
    }
  }
}));
