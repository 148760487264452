import React, { FC, useContext } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { palette } from 'styles/global';
import { SlashDivider, Text } from 'components/Shared';
import { IssueContext } from 'context';
import { ReactComponent as PromotionIcon } from 'assets/svg/icon-promotion-tag.svg';

interface IEstimateInstructions {
  isMultiService: boolean;
  projectId: string;
}

export const EstimateInstructions: FC<IEstimateInstructions> = ({ isMultiService, projectId }) => {
  const classes = useStyles();
  const { getProjectFromId } = useContext(IssueContext);
  const projectAssessmentFeePromos = getProjectFromId(projectId)?.fees?.find(
    (f) => f?.promotionReference
  )?.promotionReference;
  const hasProjectAssessmentFeePromo =
    projectAssessmentFeePromos && projectAssessmentFeePromos.length > 0;
  const projectFeePromotionTitle = projectAssessmentFeePromos?.find((p) => p?.promotion.title)
    ?.promotion.title;

  return (
    <div className={classes.instructions}>
      <Text element="h3" customClass="body-b">
        What to do next
      </Text>
      <ul className={classes.list}>
        <li>Accept, skip or decline available estimates.</li>
        <li>Confirm & secure your estimate decisions by adding a payment method.</li>

        {!hasProjectAssessmentFeePromo && (
          <>
            {isMultiService ? (
              <li>
                Accept any estimate from a single service provider to waive the $75 assessment fee.
              </li>
            ) : (
              <li>Accept your estimate to waive the $75 assessment fee.</li>
            )}
          </>
        )}
      </ul>

      {hasProjectAssessmentFeePromo && (
        <Grid container direction="row" justify="flex-start" style={{ marginTop: 10 }}>
          <Grid item xs={2} sm={1}>
            <PromotionIcon />
          </Grid>
          <Grid item xs={10} sm={6}>
            <Text customClass="body">
              You've got a{' '}
              <Text element="span" customClass="body-b">
                {projectFeePromotionTitle}{' '}
              </Text>
              discount, so your assessment fee is waived.
            </Text>
          </Grid>
        </Grid>
      )}
      <SlashDivider style={{ marginTop: 10 }} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  instructions: {
    fontSize: 14,
    color: palette.ultraMarine,
    padding: '0 15px'
  },
  list: {
    padding: '0 0 0 25px',
    margin: 0
  }
}));
