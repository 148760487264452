import React, { FC, CSSProperties } from 'react';
import { Text } from 'components/Shared';

interface DividerProps {
  item?: string;
  style?: CSSProperties;
  count?: number;
}

export const SlashDivider: FC<DividerProps> = ({ count = 300, item = '/ ', style }) => {
  return (
    <Text
      customClass="body"
      style={{
        fontSize: 5,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        letterSpacing: '0.5px',
        ...style
      }}
      additionalClasses="slashDivider"
    >
      {new Array(count).join(item)}
    </Text>
  );
};
