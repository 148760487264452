import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from '@reach/router';
import { Subtitle } from 'components';
import { palette } from 'styles/global';
import { pushGAEvent } from 'utils';
import { BASE_URL } from 'data/env-vars';

interface TermsPrivacyProps {
  privacyId?: string;
  termsId?: string;
}
export const TermsPrivacy = ({ privacyId, termsId }: TermsPrivacyProps) => {
  return (
    <Grid container justify="center" direction="row" style={{ padding: '32px' }}>
      <Link
        to=""
        onClick={() => {
          pushGAEvent({ id: termsId || '' });
          window.open(`${window.location.origin}${BASE_URL}/terms`);
        }}
        style={styles.link}
        id={termsId}
      >
        Terms and Conditions
      </Link>
      <Subtitle>|</Subtitle>
      <Link
        to=""
        onClick={() => {
          pushGAEvent({ id: privacyId || '' });
          window.open(`${window.location.origin}${BASE_URL}/privacy`);
        }}
        style={styles.link}
        id={privacyId}
      >
        Privacy Policy
      </Link>
    </Grid>
  );
};

const styles = {
  link: {
    fontFamily: 'Assistant',
    fontWeight: 400,
    fontSize: 14,
    color: palette.coal,
    textDecoration: 'underline',
    padding: '0px 5px'
  }
};
