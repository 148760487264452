import React, { CSSProperties, FC, ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { H5, RadioButton } from 'components';
import { Classes, Text } from 'components/Shared';
import { palette } from 'styles/global';
import { formatMUICurrency } from 'utils';

export const ActionButton: FC<{
  onClick: () => void;
  checked: boolean;
  id: string;
  styleOverride?: CSSProperties;
  disabled?: boolean;
}> = ({ checked, onClick, id, styleOverride, disabled = false }) => (
  <Grid item>
    <RadioButton
      onClick={onClick}
      checked={checked}
      disabled={disabled}
      id={id}
      inputProps={{ 'aria-label': id }}
      style={styleOverride}
    />
  </Grid>
);

export const Title: FC<{ title: string; id: string; styleOverride?: CSSProperties }> = ({
  title,
  id,
  styleOverride
}) => (
  <H5 styleOverride={{ paddingRight: 9, ...styleOverride }} id={id}>
    {title}
  </H5>
);

export const Total: FC<{
  total: number;
  id: string;
  styleOverride?: CSSProperties;
  strikeThrough?: boolean;
}> = ({ total, id, styleOverride, strikeThrough }) => (
  <Grid container item justify="flex-end" direction="row" wrap="nowrap" style={styleOverride}>
    <Text
      customClass="body-b"
      style={{
        paddingRight: 2,
        textDecoration: strikeThrough ? 'line-through' : 'none',
        color: strikeThrough ? palette.concrete : 'inherit',
        fontSize: strikeThrough ? 14 : 'inherit'
      }}
    >
      $
    </Text>
    <H5
      id={id}
      styleOverride={{
        textDecoration: strikeThrough ? 'line-through' : 'none',
        color: strikeThrough ? palette.concrete : 'inherit',
        fontSize: strikeThrough ? 14 : 'inherit'
      }}
    >
      {formatMUICurrency(total)}
    </H5>
  </Grid>
);

export const Description: FC<{
  description: string | ReactNode;
  id?: string;
  styleOverride?: CSSProperties;
  customClass?: Classes;
}> = ({ description, id, styleOverride, customClass = 'body' }) => (
  <>
    {typeof description === 'string' ? (
      <Text customClass={customClass} style={{ padding: '4px 0px 0px', ...styleOverride }} id={id}>
        {description}
      </Text>
    ) : (
      description
    )}
  </>
);
