import React from 'react';
import { Box } from '@material-ui/core';

import { ReactComponent as CastleLogoSm } from 'assets/svg/Castle_Logo_marine_small.svg';
import { FixedContainer, Text } from 'components';
import { ID } from 'data/constants';
import { sticky } from 'core/styles';
import { palette } from 'styles/global';
import { StickerFooterStyles } from './StickerFooterStyles';

export const StickyFooter = () => {
  const classes = StickerFooterStyles();

  return (
    <FixedContainer fixedId={ID.CASTLE_FOOTER}>
      <Box
        id={ID.CASTLE_FOOTER}
        display="flex"
        flexDirection="column"
        className={classes.footerRoot}
        style={sticky}
      >
        <CastleLogoSm style={{ fill: palette.sawdust, margin: 0 }} />
        <Text customClass="body-sm" additionalClasses="copyright">
          &copy; {new Date().getFullYear()} 358 Ventures, Patent #: US10997559B Castle, 800 West St.
          Clair Avenue, 2nd Floor, Cleveland, OH 44113
        </Text>
      </Box>
    </FixedContainer>
  );
};
