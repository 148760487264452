import React, { SVGAttributes, FC } from 'react';

interface IIconDiscountAnimated {
  style?: SVGAttributes<SVGElement>;
}

const IconDiscountAnimated: FC<IIconDiscountAnimated> = ({ style }) => {
  return (
    <svg
      id="prefix__ehs0QCt1BOL1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 480 480"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      {...style}
    >
      <style>
        {
          '@keyframes ehs0QCt1BOL2_tr__tr{0%{transform:translate(187.803381px,153.348855px) rotate(35.446009deg);animation-timing-function:cubic-bezier(.42,0,.58,1)}33.333333%{transform:translate(187.803381px,153.348855px) rotate(41.116877deg);animation-timing-function:cubic-bezier(.455,.03,.515,.955)}66.666667%,to{transform:translate(187.803381px,153.348855px) rotate(9.162702deg)}}@keyframes ehs0QCt1BOL4_c_o{0%{opacity:.66}60%{opacity:.66;animation-timing-function:cubic-bezier(.42,0,1,1)}80%,to{opacity:1}}@keyframes ehs0QCt1BOL5_c_o{0%{opacity:.76}60%{opacity:.76;animation-timing-function:cubic-bezier(.42,0,1,1)}80%,to{opacity:1}}@keyframes ehs0QCt1BOL6_c_o{0%,60%{opacity:.5}80%,to{opacity:1}}@keyframes ehs0QCt1BOL10_tr__tr{0%{transform:translate(130.147059px,138.001126px) rotate(-.084161deg);animation-timing-function:cubic-bezier(.42,0,.58,1)}33.333333%,to{transform:translate(130.147059px,138.001126px) rotate(11.165989deg)}}@keyframes ehs0QCt1BOL14_c_o{0%{opacity:.34}60%,to{opacity:1}}@keyframes ehs0QCt1BOL17_ts__ts{0%{transform:translate(225.850006px,315.012817px) scale(.024015,.024015);animation-timing-function:cubic-bezier(.42,0,1,1)}33.333333%,to{transform:translate(225.850006px,315.012817px) scale(.78427,.78427)}}@keyframes ehs0QCt1BOL20_tr__tr{0%,73.333333%{transform:translate(156.163563px,229.945923px) rotate(0deg)}to{transform:translate(156.163563px,229.945923px) rotate(-92.688987deg)}}@keyframes ehs0QCt1BOL22_tr__tr{0%{transform:translate(297.947665px,388.85744px) rotate(0deg)}73.333333%{transform:translate(297.947665px,388.85744px) rotate(0deg);animation-timing-function:cubic-bezier(.445,.05,.55,.95)}to{transform:translate(297.947665px,388.85744px) rotate(-93.363857deg)}}'
        }
      </style>
      <g
        transform="rotate(35.446 -146.018 370.5)"
        style={{
          animation: 'ehs0QCt1BOL2_tr__tr 1500ms linear infinite alternate forwards'
        }}
      >
        <g id="prefix__ehs0QCt1BOL2" transform="translate(-137.297 -80.79) scale(.75701)">
          <path
            d="M322.9 233.2c3.7 1.3 7.6-.7 8.9-4.4l18.1-53c.8-2.4.3-5-1.3-6.9-1.6-1.9-4.2-2.8-6.6-2.3a7.1 7.1 0 00-5.3 4.6l-18.1 53c-1.3 3.8.7 7.8 4.3 9z"
            transform="translate(48.86 2.005)"
            opacity={0.66}
            fill="#79BD9A"
            style={{
              animation: 'ehs0QCt1BOL4_c_o 1500ms linear infinite alternate forwards'
            }}
          />
          <path
            d="M358.1 260.1c3.7 1.3 7.6-.7 8.9-4.3l22.7-66.2c1.3-3.7-.7-7.6-4.4-8.9-3.7-1.3-7.6.7-8.9 4.4l-22.7 66.2c-1.2 3.5.8 7.5 4.4 8.8z"
            transform="translate(46.068 -1.576)"
            opacity={0.76}
            fill="#79BD9A"
            style={{
              animation: 'ehs0QCt1BOL5_c_o 1500ms linear infinite alternate forwards'
            }}
          />
          <path
            d="M305.8 194c.9-.8 1.6-1.8 2-3l9.1-26.5c.8-2.4.3-5-1.3-6.9-1.6-1.9-4.2-2.8-6.6-2.3a7.1 7.1 0 00-5.3 4.6l-9.1 26.5c-1 3 .1 6.3 2.7 8.1 2.6 1.8 6.1 1.6 8.5-.5z"
            transform="translate(48.438 4.94)"
            opacity={0.5}
            fill="#79BD9A"
            style={{
              animation: 'ehs0QCt1BOL6_c_o 1500ms linear infinite alternate forwards'
            }}
          />
          <path
            id="prefix__ehs0QCt1BOL7"
            d="M402.6 136.8L208.2 70.2l-.1 1-9.4-1.9c-8.2-2.8-17.1-2.5-25.1.8l-26.7 11c-3.5 1.5-5.1 5.6-3.6 9.1 1.4 3.5 5.4 5.2 9 3.9L178.9 83c4.8-2 25.4 1 30.4 2.7l.1-.2L398 150c25.6 8.8 39.3 36.6 30.5 62.2L396.8 305c-1.4 4.1-3.4 7.9-5.8 11.5-2.2 3.2-1.3 7.6 1.9 9.7 3.2 2.2 7.6 1.3 9.7-1.9 3.1-4.6 5.6-9.5 7.4-14.7l31.8-92.7c11.2-33-6.3-68.8-39.2-80.1z"
            transform="rotate(-3.025 459.573 -636.798)"
            fill="#A7872F"
            stroke="none"
            strokeWidth={1}
          />
        </g>
      </g>
      <g
        transform="rotate(-.084 94014.535 -88533.51)"
        style={{
          animation: 'ehs0QCt1BOL10_tr__tr 1500ms linear infinite alternate forwards'
        }}
      >
        <g id="prefix__ehs0QCt1BOL10" transform="matrix(.77475 0 0 .77475 -40.153 -87.412)">
          <g id="prefix__ehs0QCt1BOL11" transform="rotate(.292 44.559 111.645)">
            <path
              id="prefix__ehs0QCt1BOL12"
              d="M192.4 127.1l-114-15.2c-8-1.1-16.2 1.4-22.3 6.7-6.1 5.3-9.7 13-9.8 21.1l-.9 115c-.1 6.9 2.4 13.6 6.9 18.8l143.6 165.3c20.2 23.2 55.3 25.7 78.5 5.5l73.6-63.9c23.2-20.2 25.7-55.3 5.5-78.5L210 136.6c-4.5-5.2-10.8-8.6-17.6-9.5zm-54.6 85.6c-11.6 10.1-29.2 8.8-39.3-2.8-10.1-11.6-8.8-29.2 2.8-39.3 11.6-10.1 29.2-8.8 39.3 2.8 10.1 11.7 8.9 29.3-2.8 39.3z"
              transform="translate(.024 .117)"
              fill="#A8DBA8"
              stroke="none"
              strokeWidth={1}
            />
            <path
              id="prefix__ehs0QCt1BOL13"
              d="M192.4 127.1l-114-15.2c-8-1.1-16.2 1.4-22.3 6.7-6.1 5.3-9.7 13-9.8 21.1l-.3 43.9c60.6-40.6 142-29.8 189.9 25.3l128.3 147.7c6.5-18.8 2.5-39.6-10.6-54.6L210 136.6c-4.5-5.2-10.8-8.6-17.6-9.5z"
              fill="#CFF09E"
              stroke="none"
              strokeWidth={1}
            />
            <path
              d="M358.785 323.579c-68.893 59.886-173.321 52.56-233.207-16.431L45.797 215.29l-.297 41.475c-.099 6.83 2.376 13.462 6.83 18.609l142.141 163.62c19.995 22.965 54.739 25.44 77.703 5.445l72.852-63.251c16.927-14.749 23.262-38.307 15.937-59.49l-2.178 1.881z"
              opacity={0.34}
              fill="#79BD9A"
              style={{
                animation: 'ehs0QCt1BOL14_c_o 1500ms linear infinite alternate forwards'
              }}
            />
            <path
              id="prefix__ehs0QCt1BOL15"
              d="M161.9 199.9c-5.4 3.5-11.7 5.4-18.2 5.5-2.7 5-6.9 8.9-12 11.3.6.3 1.2.7 1.9.9 12.1 3.6 25.2 1.5 35.5-5.7 3.8-2.3 7.4-4.9 10.7-7.9 8-7.2 15-15.4 20.8-24.5-4-2.4-8.2-4.6-12.4-6.6-6.7 10.8-15.7 20-26.3 27z"
              fill="#79BD9A"
              stroke="none"
              strokeWidth={1}
            />
            <path
              id="prefix__ehs0QCt1BOL16"
              d="M206.6 133.4l-1.8 5.7c-.8 2.5-1.8 4.9-2.7 7.3-3.7 9.3-8.4 18.2-13.9 26.6 4.2 2 8.3 4.2 12.4 6.6 5.7-8.8 10.5-18.2 14.4-28 .8-2.1 1.6-4.2 2.4-6.4l-7.4-8.5c-1-1.2-2.2-2.3-3.4-3.3z"
              fill="#A8DBA8"
              stroke="none"
              strokeWidth={1}
            />
          </g>
          <g
            transform="matrix(.02402 0 0 .02402 225.85 315.013)"
            style={{
              animation: 'ehs0QCt1BOL17_ts__ts 1500ms linear infinite alternate forwards'
            }}
          >
            <g id="prefix__ehs0QCt1BOL17" transform="translate(-225.85 -315.013)">
              <path
                id="prefix__ehs0QCt1BOL18"
                d="M194.868 279.455c7.426-6.53 18.82-5.762 25.35 1.664 3.201 3.713 8.962 4.097 12.675.897 3.713-3.201 4.097-8.963.897-12.676-10.755-12.29-28.423-15.748-43.02-8.322l-6.785-7.682c-3.2-3.713-8.962-4.097-12.675-.896-3.713 3.2-4.097 8.962-.896 12.675l6.785 7.682c-10.242 14.852-7.81 34.953 5.762 46.988 13.443 12.035 33.8 12.035 47.372.256 7.426-6.53 18.82-5.761 25.35 1.664 6.53 7.426 5.762 18.821-1.664 25.35-7.426 6.53-18.821 5.762-25.35-1.664-3.201-3.713-8.963-4.097-12.676-.896-3.713 3.201-4.097 8.962-.896 12.675 10.755 12.291 28.423 15.62 43.019 8.322l6.785 7.682c3.201 3.713 8.963 4.097 12.676.897 3.713-3.201 4.097-8.963.896-12.676l-6.658-7.682c10.243-14.851 7.81-35.08-5.633-47.115-13.444-12.035-33.8-12.035-47.372-.128-7.426 6.53-18.82 5.761-25.35-1.665-6.914-7.426-6.146-18.82 1.408-25.35z"
                transform="matrix(.94896 0 0 .94896 10.75 12.274)"
                fill="#18657D"
                stroke="none"
                strokeWidth={1}
              />
              <path
                id="prefix__ehs0QCt1BOL19"
                d="M172.7 222.1L141 249.7c-2.9 2.5-3.2 7-.7 9.9 2.5 2.9 7 3.2 9.9.7l31.7-27.6c2.9-2.5 3.2-7 .7-9.9-2.6-3-7-3.3-9.9-.7z"
                transform="matrix(.8891 0 0 .8891 12.618 15.527)"
                fill="#18657D"
                stroke="none"
                strokeWidth={1}
              />
              <path
                id="prefix__ehs0QCt1BOL21"
                d="M301.5 369.8l-31.7 27.6c-2.9 2.5-3.2 7-.7 9.9 2.5 2.9 7 3.2 9.9.7l31.7-27.6c2.9-2.5 3.2-7 .7-9.9-2.6-2.9-7-3.2-9.9-.7z"
                transform="rotate(-1.048 3935.276 -2835.328) scale(.82823)"
                fill="#18657D"
                stroke="none"
                strokeWidth={1}
              />
            </g>
          </g>
          <path
            id="prefix__ehs0QCt1BOL23"
            d="M207 53.8c-3.6-21.9-13.8-36.8-28.8-41.9-29.7-10.2-66.9 22.6-84.7 74.6-2.3 6.9-4.2 14-5.6 21.2l-8.5-1.1c-10.1-1.3-20.3 1.7-27.9 8.4-7.7 6.7-12.1 16.4-12.2 26.5l-.7 115.6c-.1 8.6 3.1 17 8.7 23.5L192 446.5c22.9 26.2 62.6 28.9 88.9 6.1l73.9-64.4c26.2-22.9 28.9-62.6 6.1-88.9L216.2 133.5c-4.4-5.1-16.7-10.7-16.7-10.7 14.3-45.1 7.5-69 7.5-69zm143.3 254.8c17.8 20.4 15.6 51.3-4.7 69.1l-73.9 64.4c-20.4 17.8-51.3 15.6-69.1-4.7L57.9 271.5c-3.4-3.9-5.3-9-5.3-14.2l.7-115.6c0-6.2 2.7-12 7.3-16.1 4.6-4 10.8-5.9 16.9-5.1l102.3 13.4c-6.9 15.8-17.3 29.9-30.3 41.2-.9-1.5-1.9-3-3.1-4.4-12.7-14.5-34.8-16.1-49.4-3.4-.6.5-1 1.1-1.4 1.8-2.9 2-3.9 5.9-2.2 9 4.4 8.8 12 15.7 21.3 19.2 8.6 2.7 17.8 2.4 26.1-1.1-.9 8.3-6.7 15.2-14.6 17.6-7.9 2.4-16.6 0-22-6.3-2.5-2.9-7-3.2-9.9-.7-2.9 2.5-3.2 7-.7 9.9 10.2 11.7 26.9 15.3 41 8.8 14.1-6.5 22.2-21.5 20-36.8 2.3-1.6 4.5-3.3 6.6-5.1 14.3-13.3 25.6-29.5 33.1-47.6 4.4 1 8.4 3.4 11.4 6.7l144.6 165.9zM135.9 179.9c.7.8 1.2 1.6 1.8 2.5-5.7 2.8-12.3 3.5-18.4 1.7-4.2-1.6-7.9-4.3-10.5-8 8.7-5.6 20.2-4 27.1 3.8zm58-115.8l.7 7.2c.3 2.8.2 5.7-.1 8.5-.5 3.9-1.1 9.1-1.1 9.3-.7 11.2-7.7 30.6-8 31.3l-83.6-11c1.3-6.2 3-12.4 5-18.4 14.7-43.1 45.4-73.2 67-65.8 9.8 3.4 16.8 14.4 19.5 31"
            fill="#A7872F"
            stroke="none"
            strokeWidth={1}
          />
        </g>
      </g>
    </svg>
  );
};

export default IconDiscountAnimated;
