import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg';
import { Subtitle, P } from 'components';
import { palette } from 'styles/global';

interface IAccreditationItemProps {
  content: { description: string };
  isLast: boolean;
  mui: boolean;
}

interface IAccreditationProps {
  mui?: boolean;
}

export const Accreditation: FC<IAccreditationProps> = ({ mui = false }) => {
  const items = [
    { description: 'Licensed with regular license checks' },
    { description: '$2 million in Liability Coverage' },
    { description: '7-year criminal background check' }
  ];
  return (
    <Grid>
      {!!items &&
        items.map((item, idx, arr) => (
          <AccreditationItem content={item} isLast={idx === arr.length - 1} key={idx} mui={mui} />
        ))}
    </Grid>
  );
};

const AccreditationItem: FC<IAccreditationItemProps> = ({
  content: { description },
  isLast,
  mui
}) => {
  const grommetContent = (
    <>
      <CheckCircleIcon style={{ paddingRight: '8px', paddingTop: '3px' }} />
      <Subtitle type="2" styleOverride={{ color: palette.dove }}>
        {description}
      </Subtitle>
    </>
  );

  const muiContent = (
    <>
      <CheckCircleOutline
        style={{ paddingRight: '2px', paddingTop: '3px', color: palette.freon, height: 16 }}
      />
      <P mui muiType="body" styleOverride={{ paddingTop: '2px', color: palette.sawdust }}>
        {description}
      </P>
    </>
  );
  return (
    <Grid
      container
      direction="row"
      alignContent="center"
      justify="flex-start"
      style={{ paddingBottom: !isLast ? '4px' : '0px' }}
    >
      {mui ? muiContent : grommetContent}
    </Grid>
  );
};
