import React, { lazy } from 'react';
import { Router } from '@reach/router';
import { ProvidersContext } from 'context';
import { CastleSnackbar, ProtectedRoute, PublicRoute } from 'components';
import { HeaderAction } from 'data/constants';
import { palette } from 'styles/global';
import { BASE_URL } from 'data/env-vars';

const Account = lazy(() => import('screens/Account/Account')),
  AccountSetup = lazy(() => import('screens/AccountSetup/AccountSetup')),
  Auth = lazy(() => import('screens/Auth/Auth')),
  ServiceDetails = lazy(() => import('screens/ServiceDetails/ServiceDetails')),
  Privacy = lazy(() => import('screens/Privacy/Privacy')),
  Terms = lazy(() => import('screens/Terms/Terms')),
  PaymentTerms = lazy(() => import('screens/PaymentTerms/PaymentTerms')),
  Warranty = lazy(() => import('screens/Warranty/Warranty')),
  Covid = lazy(() => import('screens/Covid/Covid')),
  Confirm = lazy(() => import('screens/Confirm/Confirm')),
  Estimate = lazy(() => import('screens/Estimate/Estimate')),
  Estimates = lazy(() => import('screens/Estimates/Estimates')),
  Dashboard = lazy(() => import('screens/Dashboard/Dashboard')),
  Payment = lazy(() => import('screens/Payment/Payment')),
  PaymentHistory = lazy(() => import('screens/PaymentHistory/PaymentHistory')),
  PaymentReceipt = lazy(() => import('screens/PaymentReceipt/PaymentReceipt')),
  AuthForgotPassword = lazy(() => import('screens/AuthForgotPassword/AuthForgotPassword')),
  AddPaymentMethod = lazy(() => import('screens/AddPaymentMethod/AddPaymentMethod')),
  PaymentMethods = lazy(() => import('screens/PaymentMethods/PaymentMethods')),
  Welcome = lazy(() => import('screens/Welcome/Welcome')),
  ProjectFeePayment = lazy(() => import('screens/Payment/ProjectFeePayment')),
  ProjectPaymentReceipt = lazy(() => import('screens/PaymentReceipt/ProjectPaymentReceipt')),
  NotFound = lazy(() => import('screens/NotFound/NotFound'));

export const App = () => {
  return (
    <ProvidersContext>
      <CastleSnackbar />
      <Router basepath={BASE_URL}>
        <PublicRoute path="/login" component={Auth} showStickyFooter={false} showHeader={false} />
        <PublicRoute
          path="/account-setup"
          component={AccountSetup}
          showHeader
          headerJustify="flex-end"
        />
        <PublicRoute
          path="/welcome"
          component={Welcome}
          showHeader={false}
          showStickyFooter={false}
        />
        <PublicRoute
          path="/forgot-password"
          component={AuthForgotPassword}
          headerAction={HeaderAction.BACK}
          backRoute="/login"
          backButtonId="forgot-password-back-btn"
          showHeader={true}
          headerJustify="space-between"
        />
        <ProtectedRoute path="/" component={Dashboard} />
        <ProtectedRoute
          path="/history/services"
          component={PaymentHistory}
          backgroundColor={palette.sawdust}
        />
        <ProtectedRoute path="/account" component={Account} />
        <ProtectedRoute
          path="/account/payment-methods"
          component={PaymentMethods}
          headerAction={HeaderAction.BACK}
          backRoute={() => '/account'}
          backButtonId="payment-methods-back-btn"
        />
        <ProtectedRoute
          path="/account/payment-methods/add"
          component={AddPaymentMethod}
          headerAction={HeaderAction.BACK}
          backRoute={() => '/account/payment-methods'}
          backButtonId="payment-methods-add-back-btn"
        />
        <ProtectedRoute
          path="/confirm"
          component={Confirm}
          headerAction={HeaderAction.BACK}
          backRoute={() => '/'}
          backButtonId="confirm-back-btn"
        />
        <ProtectedRoute
          path="/:projectId/estimates/:serviceProviderId/"
          component={Estimates}
          headerAction={HeaderAction.BACK}
          backRoute={() => '/'}
          backButtonId="estimates-back-btn"
        />
        <ProtectedRoute
          path="service-details/:serviceId"
          component={ServiceDetails}
          headerAction={HeaderAction.BACK}
          backRoute={() => '/'}
          headerJustify="space-between"
          backgroundColor={palette.sawdust}
          backButtonId="service-details-back-btn"
        />
        <ProtectedRoute
          path="service-details/:serviceId/estimate"
          component={Estimate}
          headerAction={HeaderAction.BACK}
          backRoute={() => '/'}
          backButtonId="estimate-back-btn"
        />
        <ProtectedRoute
          path="service-details/:serviceId/receipt"
          component={PaymentReceipt}
          headerAction={HeaderAction.BACK}
          backRoute={() => '/history/services'}
          backButtonId="receipt-back-btn"
        />
        <ProtectedRoute
          path="service-details/:serviceId/pay"
          component={Payment}
          headerAction={HeaderAction.BACK}
          backRoute={() => '/'}
          backButtonId="pay-back-btn"
        />
        <ProtectedRoute
          path="/project/:projectId/receipt"
          component={ProjectPaymentReceipt}
          headerAction={HeaderAction.BACK}
          backRoute={() => '/'}
          backButtonId="project-fee-pay-back-btn"
        />
        <ProtectedRoute
          path="/project/:projectId/pay"
          component={ProjectFeePayment}
          headerAction={HeaderAction.BACK}
          backRoute={() => '/'}
          backButtonId="project-fee-pay-back-btn"
        />
        <PublicRoute path="privacy" component={Privacy} showHeader headerJustify="flex-end" />
        <PublicRoute path="terms" component={Terms} showHeader headerJustify="flex-end" />
        <PublicRoute path="covid19" component={Covid} showHeader headerJustify="flex-end" />
        <PublicRoute
          path="payment-terms"
          component={PaymentTerms}
          showHeader
          headerJustify="flex-end"
        />
        <PublicRoute path="warranty" component={Warranty} showHeader headerJustify="flex-end" />
        <PublicRoute default component={NotFound} showStickyFooter={false} />
      </Router>
    </ProvidersContext>
  );
};
