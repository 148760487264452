import { Theme, makeStyles } from '@material-ui/core';
import { palette } from 'styles/global';

export const WhatToExpectModalContentStyles = makeStyles((theme: Theme) => ({
  title: {
    background: palette.sawdust,
    padding: '40px 20px',
    marginBottom: 10,
    color: palette.marine,
    display: 'flex'
  },
  titleSlashes: {
    padding: '5px 8px 0 0'
  },
  MuiAccordionroot: {
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white'
    },
    '& .MuiIconButton-root': {
      padding: 0
    }
  },
  accordions: {
    margin: `0 20px 0 30px`
  },
  accordion: {
    borderTop: 'none !important',
    borderBottom: 'none !important',
    boxShadow: 'none',
    background: palette.pvcWhite
  },
  accordionSum: {
    border: 'none',
    padding: '0 10px 0 0',
    '& h2': {
      fontSize: 18,
      fontFamily: 'Manrope',
      fontWeight: 700,
      color: palette.marine
    }
  },
  expandIcon: {
    color: palette.caribbean,
    fontSize: 30
  },
  accordionDet: {
    border: 'none',
    flexDirection: 'column',
    padding: 0,
    '& p': {
      marginBottom: 25
    },
    '& p:last-of-type': {
      marginBottom: 0
    }
  }
}));
