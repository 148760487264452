import { makeStyles, Theme } from '@material-ui/core';
import { palette } from 'styles/global';

export const FooterStyles = makeStyles((theme: Theme) => ({
  footerRoot: {
    bottom: 0,
    height: 86,
    backgroundColor: palette.marine,

    '& > *': {
      margin: '0 18px'
    },

    '& .copyright': {
      fontSize: 10,
      color: palette.sawdust,
      height: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  }
}));
