import React, { CSSProperties, ReactNode } from 'react';
import { FC } from 'react';
import { palette, fontWeights } from 'styles/global';

interface IH3Props {
  children?: ReactNode;
  styleOverride?: CSSProperties;
  mui?: boolean;
  id?: string;
}

export const H3: FC<IH3Props> = ({ children, styleOverride, mui, id }) => {
  const style = mui ? styles.mui : styles.grommet;
  return (
    <h3 style={{ ...style, ...styleOverride }} id={id}>
      {children}
    </h3>
  );
};

const styles = {
  grommet: {
    fontFamily: 'Assistant',
    fontWeight: 600,
    fontSize: 18,
    color: palette.elm,
    lineHeight: '20px',
    letterSpacing: '-0.25px',
    margin: 0
  },
  mui: {
    fontFamily: 'Manrope',
    fontWeight: fontWeights.extraBold,
    fontStyle: 'normal',
    fontSize: 30,
    color: palette.marine,
    lineHeight: '46px',
    letterSpacing: '-1px'
  }
};
