import moment from 'moment-timezone';

import {
  EstimateStatus,
  Issue,
  ServiceStatus,
  ServiceType,
  ServiceWindow,
  ServiceWindowType,
  User
} from 'generated/graphql';
import { Timezone, When } from 'enums';
import { DashboardAppointment, SchedulingDashboardAppointmentGroup } from 'core/interfaces';
import { SERVICE_CATEGORIES, SERVICE_TYPES } from 'data/constants';

export const mapDashboardUserAppointments = (user: User): Required<DashboardAppointment>[] => {
  return user.issues
    ? user.issues
        .map((issue) => (issue ? mapDashboardIssueAppointments(issue) : []))
        .reduce((p, c) => [...p, ...c])
    : [];
};

export const mapDashboardUserAppointmentsScheduling = (
  user: User
): SchedulingDashboardAppointmentGroup[] => {
  const all: SchedulingDashboardAppointmentGroup[] = [];

  if (user.issues && user.issues.length > 0) {
    user.issues.forEach((issue) => {
      if (issue?.projects && issue.projects.length > 0) {
        issue?.projects.map((project) => {
          const validServices = project?.services?.filter(
            (x) => x?.status === ServiceStatus.SchedulingInProgress
          );

          if (!validServices || validServices.length === 0) return;

          const inSchedulingAppointments = validServices.map((service) => {
            const isNewOrOtherType =
              service?.type === ServiceType.NewServiceOffering ||
              service?.type === ServiceType.SmallWork;
            const category = service?.category ? SERVICE_CATEGORIES[service.category] : '';
            const type = service?.type ? SERVICE_TYPES[service.type] : '';

            return {
              title: issue?.title!,
              serviceName: `${isNewOrOtherType ? category : type} service`
            };
          });

          const existing = all.find((group) => group.projectId === project?.id);
          if (existing) {
            existing.appointments.concat(inSchedulingAppointments);
          } else {
            all.push({ projectId: project?.id!, appointments: inSchedulingAppointments });
          }
        });
      }
    });
  }

  return all;
};

const mapDashboardIssueAppointments = (issue: Issue): Required<DashboardAppointment>[] => {
  if (!issue.projects || issue.projects.length === 0) return [];

  const validServices = issue.projects.flatMap((p) =>
    p?.services?.filter(
      (x) =>
        x?.status! === ServiceStatus.EstimateAccepted ||
        x?.status! === ServiceStatus.EstimateReadyForReview ||
        x?.status! === ServiceStatus.Scheduled
    )
  );
  if (!validServices || validServices.length === 0) return [];
  const result = validServices
    .map((service) => {
      const title = issue.title;
      const provider = service!.serviceProvider?.name || '';
      const timezone = issue.address.timezone as Timezone;
      const acceptedEstimate = service?.estimates?.find(
        (estimate) => estimate.status === EstimateStatus.Accepted
      );

      const all: ServiceWindow[] = [];
      const appointment = service!.appointments?.find(
        (a) => a?.type === ServiceWindowType.Assessment
      );
      const additionalAppointments = service!.appointments?.filter(
        (a) => a?.type === ServiceWindowType.Service
      );
      if (appointment) all.push(appointment);
      if (additionalAppointments) additionalAppointments.forEach((x) => (x ? all.push(x) : x));

      return all.map((x) => ({
        title,
        data: {
          ...x,
          timezone,
          provider,
          when: mapWhen(x.startAt, timezone),
          serviceType: service?.type ? SERVICE_TYPES[service.type] : '',
          serviceCategory: service?.category ? SERVICE_CATEGORIES[service.category] : '',
          acceptedEstimateTitle: acceptedEstimate?.title
        }
      }));
    })
    .reduce((prev, curr) => [...prev, ...curr], []);

  return result;
};

const mapWhen = (date: string, timezone: Timezone): When => {
  const target = moment(date).tz(timezone);
  const now = moment().tz(timezone);
  const unit = 'day';
  if (target.isBefore(now, unit)) return When.Past;
  if (target.isSame(now, unit)) return When.Today;
  return When.Future;
};
