import React, { FunctionComponent, Suspense } from 'react';
import { Box } from '@material-ui/core';

import { Spinner } from 'components';

interface ISuspenseLoaderProps {
  children: JSX.Element;
}

export const SuspenseLoader: FunctionComponent<ISuspenseLoaderProps> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <Box justifyItems="center" alignItems="center" style={styles.loader}>
          <Spinner />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
};

const styles = {
  loader: {
    width: '100%',
    height: '100%'
  }
};
