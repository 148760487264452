import { makeStyles, Theme } from '@material-ui/core';
import { palette } from 'styles/global';

export const StickerFooterStyles = makeStyles((theme: Theme) => ({
  footerRoot: {
    bottom: 0,
    padding: '13px 0 10px',
    backgroundColor: palette.marine,

    '& > *': {
      margin: '0 18px'
    },

    '& .copyright': {
      fontSize: 10,
      color: palette.sawdust,
      display: 'flex',
      paddingTop: 5,
      flexDirection: 'column',
      justifyContent: 'center'
    }
  }
}));
