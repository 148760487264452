import React, { FC, ReactNode, useState } from 'react';
import { Card, CardContent, CardActions, Collapse, Grid, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { palette } from 'styles/global';
import {
  P,
  EstimateCardLayoutContainer,
  EstimateDivider,
  ActionButton,
  Title,
  Total,
  Description
} from 'components';

interface IEstimateCardContainer {
  title: string;
  grossCost: number;
  value: boolean;
  subtotal?: number;
  description: string;
  subtitle?: string | ReactNode;
  showActionButton?: boolean;
  flatCard?: boolean;
  promoOn?: boolean | null;
  onClick: () => void;
  expandedButtonClick?: (expanded: boolean) => void;
}

export const EstimateCardContainer: FC<IEstimateCardContainer> = ({
  children,
  description,
  onClick,
  title,
  grossCost,
  value,
  promoOn = false,
  showActionButton = true,
  expandedButtonClick,
  flatCard = false,
  subtotal,
  subtitle
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    expandedButtonClick && expandedButtonClick(expanded);
    setExpanded(!expanded);
  };

  if (flatCard) {
    return (
      <FlatCard
        description={description}
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        title={title}
        onClick={onClick}
        grossCost={grossCost}
        value={value}
        expandedButtonClick={expandedButtonClick}
        showActionButton={showActionButton}
      >
        {children}
      </FlatCard>
    );
  }

  return (
    <>
      <Card
        style={{
          backgroundColor: palette.sawdust,
          borderRadius: 8,
          marginTop: `${promoOn ? '-10' : '20'}px`,
          zIndex: promoOn ? 1 : 0,
          position: promoOn ? 'relative' : 'static'
        }}
        elevation={3}
      >
        <CardContent
          style={{
            padding: `5px ${showActionButton ? '12' : '20'}px 5px ${
              showActionButton ? '0' : '20'
            }px`
          }}
        >
          <EstimateCardLayoutContainer
            actionButton={
              showActionButton && (
                <ActionButton checked={value} onClick={onClick} id="estimate-accepted-btn-0" />
              )
            }
            title={
              <Title
                title={title}
                id="estimate-title-0"
                styleOverride={showActionButton ? undefined : { paddingLeft: 0 }}
              />
            }
            total={
              <>
                {!!subtotal && subtotal !== grossCost ? (
                  <>
                    <Total
                      total={subtotal}
                      id="estimate-total-subtotal"
                      styleOverride={showActionButton ? undefined : { paddingRight: 0 }}
                    />
                    <Total
                      total={grossCost}
                      id="estimate-total-grosscost"
                      styleOverride={
                        showActionButton
                          ? { color: palette.concrete }
                          : { paddingRight: 0, color: palette.concrete }
                      }
                      strikeThrough
                    />
                  </>
                ) : (
                  <>
                    <Total
                      total={subtotal!}
                      id="estimate-total-default"
                      styleOverride={
                        showActionButton
                          ? { color: palette.ultraMarine }
                          : { paddingRight: 0, color: palette.ultraMarine }
                      }
                    />
                  </>
                )}
              </>
            }
            description={
              <>
                {subtitle && (
                  <Description
                    description={subtitle}
                    styleOverride={{ paddingLeft: 0 }}
                    customClass="body-b"
                  />
                )}
                <Description
                  description={description}
                  styleOverride={showActionButton ? undefined : { paddingLeft: 0 }}
                  customClass={subtitle ? 'body-sm' : 'body'}
                />
              </>
            }
          />
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent style={{ padding: '16px 16px 0px' }}>
            <EstimateCardLayoutContainer>{children}</EstimateCardLayoutContainer>
          </CardContent>
        </Collapse>
        <CardActions style={{ padding: '20px 0px 0px' }}>
          <Grid
            container
            justify="flex-end"
            style={{ borderTop: `1px solid ${palette.stone}`, padding: '10px' }}
          >
            <ExpandButton expanded={expanded} handleExpandClick={handleExpandClick} />
          </Grid>
        </CardActions>
      </Card>
    </>
  );
};

interface IExpandButton {
  expanded: boolean;
  handleExpandClick: () => void;
}

const ExpandButton: FC<IExpandButton> = ({ expanded, handleExpandClick }) =>
  expanded ? (
    <Button
      color="primary"
      endIcon={<ExpandLess style={{ color: palette.caribbean, marginLeft: 0 }} />}
      onClick={handleExpandClick}
      id="estimate-details-0"
      style={{ padding: 0 }}
    >
      <P mui muiType="tertiary-text-button">
        Less Details
      </P>
    </Button>
  ) : (
    <Button
      color="primary"
      endIcon={<ExpandMore style={{ color: palette.caribbean, marginLeft: 0 }} />}
      onClick={handleExpandClick}
      id="estimate-details-0"
      style={{ padding: 0 }}
    >
      <P mui muiType="tertiary-text-button">
        See Details
      </P>
    </Button>
  );

const FlatCard: FC<IEstimateCardContainer & IExpandButton> = ({
  children,
  description,
  onClick,
  title,
  grossCost,
  value,
  showActionButton = true,
  expanded,
  handleExpandClick
}) => (
  <>
    <Card style={{ backgroundColor: palette.sawdust }} elevation={0}>
      <CardContent
        style={{
          padding: `5px ${showActionButton ? '12' : '20'}px 5px ${showActionButton ? '0' : '20'}px`
        }}
      >
        <EstimateCardLayoutContainer
          actionButton={
            showActionButton && (
              <ActionButton
                checked={value}
                onClick={onClick}
                id="estimate-accepted-btn-0"
                styleOverride={{ padding: 0, paddingTop: 9 }}
              />
            )
          }
          title={
            <Title
              title={title}
              id="estimate-title-0"
              styleOverride={showActionButton ? undefined : { paddingLeft: 0 }}
            />
          }
          total={
            <Total
              total={grossCost}
              id="estimate-total-0"
              styleOverride={showActionButton ? undefined : { paddingRight: 0 }}
            />
          }
          description={
            <Description
              description={description}
              styleOverride={showActionButton ? undefined : { paddingLeft: 0 }}
            />
          }
        />
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ padding: '16px 14px 0px 30px' }}>
          <EstimateCardLayoutContainer>{children}</EstimateCardLayoutContainer>
        </CardContent>
      </Collapse>
      <CardActions style={{ padding: '10px 0px 0px' }}>
        <Grid container justify="flex-end" style={{ paddingRight: 10 }}>
          <ExpandButton handleExpandClick={handleExpandClick} expanded={expanded} />
        </Grid>
      </CardActions>
    </Card>
    <EstimateDivider styleOverride={{ marginTop: 5 }} />
  </>
);
