import React from 'react';
import { navigate } from '@reach/router';
import { ButtonBase } from '@material-ui/core';

import { P } from 'components';
import { BASE_URL } from 'data/env-vars';
import { palette } from 'styles/global';
import { pushGAEvent } from 'utils';

interface IMenuItemProps {
  id: string;
  title: string;
  path: string;
  toggleMenu: (arg0: boolean) => void;
  newTab?: boolean;
}

export const MenuItem = ({ id, title, path, toggleMenu, newTab = false }: IMenuItemProps) => {
  const onClick = () => {
    pushGAEvent({ id });
    if (newTab) {
      window.open(`${window.location.origin}/${path}`);
    } else {
      navigate(`${BASE_URL}/${path}`);
      toggleMenu(false);
    }
  };

  return (
    <ButtonBase id={id} onClick={onClick}>
      <P mui muiType="body-2-large" styleOverride={{ color: palette.sawdust }}>
        {title}
      </P>
    </ButtonBase>
  );
};
