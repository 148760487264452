import React, { FunctionComponent, useContext } from 'react';
import { RouteComponentProps, Redirect, useParams } from '@reach/router';
import { Box } from '@material-ui/core';

import { UserContext } from 'context';
import { StickyHeader, StickyFooter, SuspenseLoader, Banner, ModalContainer } from 'components';
import { HeaderAction } from 'data/constants';
import { BASE_URL } from 'data/env-vars';

interface IProtectedRouteProps extends RouteComponentProps {
  children?: JSX.Element;
  component: FunctionComponent<any>;
  headerAction?: HeaderAction;
  backRoute?: (issueId?: string) => string;
  backgroundColor?: string;
  backButtonId?: string;
  headerJustify?: headerJustifyType | 'center';
}
type headerJustifyType = 'center' | 'flex-end' | 'flex-start' | 'space-between';

export const ProtectedRoute: FunctionComponent<IProtectedRouteProps> = ({
  component: Component,
  headerAction = HeaderAction.MENU,
  backRoute: backRouteFn,
  backgroundColor,
  backButtonId,
  headerJustify,
  ...rest
}) => {
  const { user, isLoaded } = useContext(UserContext);

  const { serviceId } = useParams() as { [id: string]: string };
  const backRoute = backRouteFn && backRouteFn(serviceId);

  if (!isLoaded) return null;

  if (!user) {
    const search: string[] = window.location.search.replace('?', '').split('&');
    const queryParams: string = [...search, `dest=${window.location.pathname}`]
      .filter((x) => !!x)
      .join('&');
    const redirectUrl: string = `${BASE_URL}/login?${queryParams}`;
    return <Redirect to={redirectUrl} noThrow />;
  }

  return (
    <Box style={backgroundColor ? { backgroundColor, minHeight: '100vh' } : {}}>
      <StickyHeader
        justify={headerJustify}
        action={headerAction}
        backRoute={backRoute}
        backButtonId={backButtonId}
      />
      <Banner />
      <SuspenseLoader>
        <Component {...rest} />
      </SuspenseLoader>
      <ModalContainer />
      <StickyFooter />
    </Box>
  );
};
