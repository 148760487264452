import React, { createContext, useState, useEffect, useContext } from 'react';

import { UserContext } from 'context';
import { Issue, Service, Project, ServiceEstimate } from 'generated/graphql';

export const IssueContext = createContext<IssueContextProps>({} as IssueContextProps);

type IssueProviderProps = {
  children: React.ReactNode | JSX.Element;
};

interface IssueContextProps {
  issueList: Issue[];
  getProjectFromId: (projectId: string) => Project | null | undefined;
  getServicesFromProject: (projectId: string) => (Service | null)[] | undefined | null;
  getServicesFromServiceProviderId: (
    projectId: string,
    serviceProviderId: string
  ) => Service[] | null;
  getServiceFromId: (serviceId: string) => Service;
  getServicesByProjectId: (projectId: string) => Service[];
  getIssueFromService: (serviceId: string) => Issue;
  getIssueFromProjectId: (projectId: string) => Issue;
  getEstimateFromId: (
    projectId: string,
    serviceId: string,
    estimateId: string
  ) => ServiceEstimate | undefined;
}

export const IssueProvider = ({ children }: IssueProviderProps) => {
  const { user } = useContext(UserContext);
  const [issueList, setIssues] = useState<any[]>();

  const getProjectFromId = (projectId: string) => {
    const issue: Issue = issueList?.find((x: Issue) => {
      return !!x.projects?.find((p) => p?.id === projectId);
    });
    return issue?.projects?.[0];
  };

  const getServicesFromProject = (projectId: string) => {
    const issue: Issue = issueList?.find((x: Issue) => {
      return !!x.projects?.find((p) => p?.id === projectId);
    });
    return issue?.projects?.[0]?.services;
  };

  const getServicesFromServiceProviderId = (
    projectId: string,
    serviceProviderId: string
  ): Service[] | null => {
    const issue: Issue = issueList?.find(
      (x: Issue) => !!x.projects?.find((p) => p?.id === projectId)
    );

    const project = issue?.projects?.find((x) => x?.id === projectId);
    const services = project?.services;

    return services?.filter((s) => s?.serviceProvider?.id === serviceProviderId) as
      | Service[]
      | null;
  };

  const getServiceFromId = (serviceId: string) => {
    const issue: Issue = issueList?.find((x: Issue) => {
      return !!x.projects?.find((p) => p?.services?.find((s) => s?.id === serviceId));
    });
    return issue?.projects?.[0]?.services?.find((x) => x?.id === serviceId)!;
  };

  const getServicesByProjectId = (projectId: string) => {
    const projectIssue: Issue = issueList?.find((issue: Issue) => {
      return issue.projects?.find((project) => project && project.id === projectId);
    });

    if (!projectIssue) return [];

    const project = projectIssue.projects && projectIssue.projects[0];

    return project?.services?.filter((service) => !!service) as Service[];
  };

  const getEstimateFromId = (projectId: string, serviceId: string, estimateId: string) => {
    const matchedService = getProjectFromId(projectId)?.services?.find((s) => s?.id === serviceId);
    return matchedService?.estimates.find((e) => e?.id === estimateId);
  };

  const getIssueFromService = (serviceId: string) => {
    return issueList?.find((x: Issue) => {
      return !!x.projects?.find((p) => p?.services?.find((s) => s?.id === serviceId));
    });
  };

  const getIssueFromProjectId = (projectId: string) => {
    return issueList?.find((x: Issue) => {
      return !!x.projects?.find((p) => p?.id === projectId);
    });
  };

  useEffect(() => {
    if (user) {
      setIssues(user.issues!);
    }
  }, [user]);

  return (
    <IssueContext.Provider
      value={{
        issueList: issueList as Issue[],
        getIssueFromProjectId,
        getServiceFromId,
        getServicesByProjectId,
        getEstimateFromId,
        getIssueFromService,
        getProjectFromId,
        getServicesFromProject,
        getServicesFromServiceProviderId
      }}
    >
      {children}
    </IssueContext.Provider>
  );
};
