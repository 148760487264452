import { DashboardEstimate, DashboardServiceProviderEstimates } from 'core/interfaces';
import { Issue, Project, Service, ServiceStatus, User } from 'generated/graphql';

export const mapDashboardUserEstimates = (user: User): DashboardEstimate[] => {
  return user.issues
    ? user.issues
        .map((issue) => (issue ? mapDashboardIssueEstimates(issue) : []))
        .reduce((p, c) => [...p, ...c])
    : [];
};

const mapDashboardIssueEstimates = (issue: Issue): DashboardEstimate[] => {
  if (issue.projects && issue.projects.length > 0) {
    const projects = issue.projects[0];
    return projects!
      .services!.map((service) => {
        switch (service?.status) {
          case ServiceStatus.EstimateReadyForReview:
          case ServiceStatus.EstimateDeclinedPendingPayment:
            const data = mapDashboardEstimateData(service);
            return [
              {
                serviceId: service.id,
                status: service.status,
                lastUpdated: service.updatedAt,
                provider: service.serviceProvider?.name || '',
                description: issue.description,
                count: service.estimates.length,
                subtotal: data?.subtotal,
                grossCost: data?.grossCost
              }
            ];

          default:
            return [];
        }
      })
      .reduce((prev, curr) => [...prev, ...curr], []);
  }
  return [];
};

export const mapDashboardUserServiceProviderEstimates = (
  user: User
): DashboardServiceProviderEstimates[] => {
  return user.issues
    ? user.issues
        .flatMap((i) => i?.projects)
        .map((project) => (project ? mapDashboardIssueServiceProviderEstimates(project) : []))
        .reduce((p, c) => [...p, ...c])
    : [];
};

const mapDashboardIssueServiceProviderEstimates = (
  project: Project
): DashboardServiceProviderEstimates[] => {
  const res: DashboardServiceProviderEstimates[] = [];

  if (
    project.services &&
    project.services.length > 0 &&
    project.services &&
    project.services.some((s) => s?.status === ServiceStatus.EstimateReadyForReview)
  ) {
    const serviceProviders = Array.from(
      new Set(project.services.flatMap((s) => s?.serviceProvider?.id))
    );
    serviceProviders.forEach((sp) => {
      const mappedServices =
        project.services?.filter(
          (s) => s?.status === ServiceStatus.EstimateReadyForReview && s.serviceProvider?.id === sp
        ) || [];

      if (mappedServices.length === 0) {
        return;
      }

      const services = mappedServices.map((s) => ({
        type: s!.type,
        category: s!.category,
        lastUpdated: s!.updatedAt
      }));

      const serviceProvider = project.services?.find((s) => s?.serviceProvider?.id === sp)
        ?.serviceProvider;

      const projectId = project.id || '';
      const serviceProviderName = serviceProvider?.name || '';
      const serviceProviderId = serviceProvider?.id || '';
      const isSingleService = mappedServices.length === 1;
      const [singleService] = isSingleService ? mappedServices : [];
      const [singleEstimate] =
        singleService?.estimates?.length === 1 ? singleService.estimates : [];
      const subtotal = singleEstimate?.subtotal;
      const grossCost = singleEstimate?.grossCost;

      res.push({
        services,
        projectId,
        serviceProviderName,
        serviceProviderId,
        subtotal,
        grossCost
      });
    });

    return res;
  } else {
    return res;
  }
};

export const mapDashboardUserServiceProviderDeclinedEstimates = (
  user: User
): DashboardServiceProviderEstimates[] => {
  return user.issues
    ? user.issues
        .flatMap((i) => i?.projects)
        .map((project) =>
          project ? mapDashboardIssueServiceProviderDeclinedEstimates(project) : []
        )
        .reduce((p, c) => [...p, ...c])
    : [];
};

export const mapDashboardProjectFeeServices = (user: User): DashboardServiceProviderEstimates[] => {
  return user.issues
    ? user.issues
        .flatMap((i) => i?.projects)
        .map((project) => (project ? mapDashboardProjectFees(project) : []))
        .reduce((p, c) => [...p, ...c])
    : [];
};

const mapDashboardProjectFees = (project: Project): DashboardServiceProviderEstimates[] => {
  if (
    project.services &&
    project.services.length > 0 &&
    project.services &&
    project.services.some((s) => s?.status === ServiceStatus.CanceledPendingPayment)
  ) {
    const [canceledService, ...additionalServices] = project.services.reduce(
      (result: Service[], service) => {
        if (!result) result = [];
        if (!service) return result;

        if (service.status === ServiceStatus.CanceledPendingPayment) {
          result = [service, ...result];
        } else {
          result = [...result, service];
        }
        return result;
      },
      []
    );

    const serviceProvider = canceledService?.serviceProvider;
    const mappedServices = {
      type: canceledService.type,
      category: canceledService.category,
      lastUpdated: canceledService.updatedAt
    };
    const additionalServicesMetadata = additionalServices.map((service) => ({
      type: service.type,
      category: service.category,
      lastUpdated: service.updatedAt
    }));

    const projectId = project.id || '';
    const serviceProviderName = serviceProvider?.name || '';
    const serviceProviderId = serviceProvider?.id || '';

    const subtotal = project?.fees?.find((f) => f?.type === 'ASSESSMENT')?.subtotal || undefined;
    return [
      {
        services: [mappedServices],
        projectId,
        serviceProviderName,
        serviceProviderId,
        subtotal,
        additionalServices: additionalServicesMetadata
      }
    ];
  } else {
    return [];
  }
};

const mapDashboardIssueServiceProviderDeclinedEstimates = (
  project: Project
): DashboardServiceProviderEstimates[] => {
  if (
    project.services &&
    project.services.length > 0 &&
    project.services &&
    project.services.some((s) => s?.status === ServiceStatus.EstimateDeclinedPendingPayment)
  ) {
    const [service, ...additionalServices] = project.services.reduce(
      (result: Service[], service) => {
        if (!result) result = [];
        if (!service) return result;

        if (service.status === ServiceStatus.EstimateDeclinedPendingPayment) {
          result = [service, ...result];
        } else {
          result = [...result, service];
        }
        return result;
      },
      []
    );

    const serviceProvider = service?.serviceProvider;
    const serviceMetadata = {
      type: service.type,
      category: service.category,
      lastUpdated: service.updatedAt
    };
    const additionalServicesMetadata = additionalServices.map((service) => ({
      type: service.type,
      category: service.category,
      lastUpdated: service.updatedAt
    }));

    const projectId = project.id || '';
    const serviceProviderName = serviceProvider?.name || project?.title || '';
    const serviceProviderId = serviceProvider?.id || '';
    const subtotal = project?.fees?.find((f) => f?.type === 'ASSESSMENT')?.subtotal || undefined;
    return [
      {
        services: [serviceMetadata],
        projectId,
        serviceProviderName,
        serviceProviderId,
        subtotal,
        additionalServices: additionalServicesMetadata
      }
    ];
  } else {
    return [];
  }
};

interface iMapDashboardEstimate {
  subtotal?: number;
  grossCost?: number;
}

const mapDashboardEstimateData = (service: Service): iMapDashboardEstimate | null => {
  if (!service) return null;
  let mappedEstimateObj = null;
  switch (service.status) {
    case ServiceStatus.EstimateReadyForReview:
      mappedEstimateObj = {
        subtotal: service.estimates[0].subtotal,
        grossCost: service.estimates[0].grossCost
      };
      break;
    case ServiceStatus.EstimateDeclinedPendingPayment:
      mappedEstimateObj = {
        subtotal: service.fee?.subtotal,
        grossCost: service.fee?.grossCost
      };
      break;
    default:
      mappedEstimateObj = null;
      break;
  }
  return mappedEstimateObj;
};
