import React, { ReactNode } from 'react';
import { palette, fontWeights } from 'styles/global';

interface IH3Props {
  children?: ReactNode;
  styleOverride?: React.CSSProperties;
  id?: string;
}

export const H5 = ({ children, styleOverride, id }: IH3Props) => (
  <h5 id={id} style={{ ...styles, ...styleOverride }}>
    {children}
  </h5>
);

const styles = {
  fontFamily: 'Manrope',
  fontWeight: fontWeights.bold,
  fontStyle: 'normal',
  fontSize: 18,
  color: palette.marine,
  lineHeight: '24px',
  letterSpacing: '0px',
  margin: 0
};
