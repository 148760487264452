import React, { FunctionComponent, useState, useContext } from 'react';
import { Auth as AmplifyAuth } from 'aws-amplify';
import { Redirect } from '@reach/router';
import { Box } from '@material-ui/core';

import { UserContext, SnackbarContext } from 'context';
import { AuthChallenges, AuthErrors, AuthTypes } from 'enums';
import { AuthSignIn, AuthSetPassword, AuthConfirm } from 'components';
import { removePhoneFormatting } from 'utils/phoneUtils';
import { SNACKBAR_OPTIONS } from 'data/snackbar-options';
import { BASE_URL } from 'data/env-vars';

export const Authenticator: FunctionComponent<{}> = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [username, setUsername] = useState('');
  const [challenge, setChallenge] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { user, tokenUser, isLoaded, updateCurrentUser } = useContext(UserContext);
  const { updateSnackbar, closeSnackbar } = useContext(SnackbarContext);

  if (!isLoaded) return null;

  const resetStatus = () => {
    setChallenge('');
    setErrorCode('');
    setErrorMessage('');
  };

  const handleError = (err: any) => {
    setErrorCode(err.code);
    setErrorMessage(err.message);
  };

  const onSubmitSignIn = async (phoneNumber: string, password: string) => {
    let unformattedPhone = removePhoneFormatting(phoneNumber);
    closeSnackbar(SNACKBAR_OPTIONS.loginError.key);
    setLoading(true);
    try {
      const userInformation = await AmplifyAuth.signIn(unformattedPhone, password);
      setCurrentUser(userInformation);
      setUsername(userInformation.username);
      setChallenge(userInformation.challengeName);
      if (!userInformation.challengeName) {
        await updateCurrentUser();
      }
    } catch (err) {
      setLoading(false);
      setErrorCode(err.code);
      setErrorMessage(err.message);
      updateSnackbar(SNACKBAR_OPTIONS.loginError);
    }
  };

  const clearError = () => {
    setErrorMessage('');
    closeSnackbar(SNACKBAR_OPTIONS.loginError.key);
  };

  const onSubmitConfirmSignIn = async (verificationCode: string) => {
    try {
      await AmplifyAuth.confirmSignIn(currentUser, verificationCode, AuthChallenges.SMS_MFA);
      updateCurrentUser();
    } catch (err) {
      handleError(err);
    }
  };

  const onSubmitCompleteNewPassword = async (password: string) => {
    try {
      await AmplifyAuth.completeNewPassword(currentUser, password, {});
      resetStatus();
    } catch (err) {
      handleError(err);
    }
  };

  const resendVerificationCode = async () => {
    try {
      await AmplifyAuth.resendSignUp(username);
    } catch (err) {
      handleError(err);
    }
  };

  if (!isLoaded) return null;

  const urlParams = new URLSearchParams(window.location.search);
  let dest = urlParams.get('dest');
  if (dest) {
    // remove "/app" from beginning of the dest url since BASE_URL already contains "/app"
    const destParts = dest.split('/app');

    // add the base url to the destination to prevent malicious redirects to external domains
    dest = `${BASE_URL}${destParts?.length >= 1 ? destParts[1] : dest}`;
  }

  if (user) {
    // If the user is logged in, redirect them to their destination
    // If no destination, default to fallback home screen
    return <Redirect to={dest || BASE_URL} noThrow />;
  } else if (tokenUser) {
    // If a valid token is passed in the URL, redirect to account-setup
    // Append query parameters if they exist
    return <Redirect to={`${BASE_URL}/welcome${window.location.search}`} noThrow />;
  }

  if (errorCode === AuthErrors.PASSWORD_RESET_REQUIRED) {
    return <Redirect to={`${BASE_URL}/forgot-password`} noThrow />;
  }

  if (challenge === AuthChallenges.SMS_MFA) {
    return (
      <Box>
        <AuthConfirm
          authType={AuthTypes.LOGIN}
          onSubmit={onSubmitConfirmSignIn}
          resendVerificationCode={resendVerificationCode}
          errorMessage={errorMessage}
        ></AuthConfirm>
      </Box>
    );
  }

  if (challenge === AuthChallenges.NEW_PASSWORD_REQUIRED) {
    return (
      <Box>
        <AuthSetPassword
          onSubmit={onSubmitCompleteNewPassword}
          errorMessage={errorMessage}
        ></AuthSetPassword>
      </Box>
    );
  }

  return <AuthSignIn onSubmit={onSubmitSignIn} clearError={clearError} loading={loading} />;
};
