import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';

import { P, H4, Text } from 'components';
import { palette } from 'styles/global';
import { PromotionReference, Invoice } from 'generated/graphql';
import { formatMUICurrency } from 'utils';

import './ProjectList.css';

interface IProjectListProps {
  invoice: Invoice;
  feePromos: (PromotionReference | null | undefined)[];
  serviceNames?: string[];
  onClick?: () => void;
}

export const ProjectList = ({ invoice, feePromos, serviceNames }: IProjectListProps) => {
  const subTotal = invoice?.subtotal;
  const grossCost = invoice?.grossCost;
  const salesTax = invoice?.salesTax;
  const customerCost = invoice?.customerCost;
  const discount = invoice?.discount;

  return (
    <DeclinedCancelledServiceList
      assessmentFee={grossCost}
      customerCost={customerCost}
      salesTax={salesTax}
      subTotal={subTotal}
      serviceNames={serviceNames}
      feePromos={feePromos}
      discount={discount}
    />
  );
};

interface DeclinedCancelledServiceList {
  assessmentFee: number;
  feePromos?: (PromotionReference | null | undefined)[];
  subTotal: number;
  salesTax: number;
  discount: number;
  customerCost: number;
  serviceNames?: string[];
}

const DeclinedCancelledServiceList: FC<DeclinedCancelledServiceList> = ({
  assessmentFee,
  customerCost,
  salesTax,
  subTotal,
  serviceNames,
  feePromos,
  discount
}) => (
  <>
    <Grid container item direction="column" style={{ marginTop: -20, marginBottom: 20 }}>
      {serviceNames &&
        serviceNames.map((name, index) => (
          <Text customClass="body" element="p" key={`service-${name}-${index}`}>
            {name}
          </Text>
        ))}
    </Grid>
    <Grid container item direction="row" justify="space-between">
      <P mui muiType="sub-header" styleOverride={style.rowHeader}>
        Details
      </P>
    </Grid>
    <Grid container item direction="row" justify="space-between" style={style.row}>
      <H4 styleOverride={{ color: palette.marine }}>In-Person Assessment</H4>
      <Box display="flex" alignItems="flex-end">
        {assessmentFee === 0 ? (
          <Text customClass="body-i" element="p" style={{ color: palette.marine }}>
            waived
          </Text>
        ) : (
          <P
            mui
            muiType="sub-header-bold"
            id="estimate-details-assessment-fee-0"
            styleOverride={{
              marginLeft: 5
            }}
          >
            ${formatMUICurrency(assessmentFee)}
          </P>
        )}
      </Box>
    </Grid>

    {((feePromos && feePromos.length > 0) || (!!discount && discount > 0)) && (
      <>
        <Grid container item direction="row" justify="space-between" style={{ margin: '25px 0 0' }}>
          <P mui muiType="sub-header" styleOverride={style.rowHeader}>
            Discounts
          </P>
        </Grid>
        {feePromos?.map((promo, index) => (
          <Grid
            container
            item
            direction="row"
            justify="space-between"
            style={style.row}
            key={`issuePromo-${index}`}
          >
            <Grid container item direction="row" justify="space-between">
              <P mui muiType="sub-header-bold">
                {promo?.promotion.title}
              </P>
              <P mui muiType="sub-header-bold" id="estimate-details-discount-0">
                –${formatMUICurrency(promo?.totalDollarAmount)}
              </P>
            </Grid>
          </Grid>
        ))}
        {!!discount && discount > 0 && (
          <Grid container item direction="row" justify="space-between" style={style.row}>
            <P mui muiType="sub-header-bold">
              Promotion
            </P>
            <P mui muiType="sub-header-bold" id="estimate-details-discount-0">
              –${formatMUICurrency(discount)}
            </P>
          </Grid>
        )}
      </>
    )}
    <Grid container item direction="row" justify="space-between" style={style.subTitleRow}>
      <P mui muiType="sub-header-bold">
        Subtotal
      </P>
      <P
        mui
        muiType="sub-header-bold"
        id="estimate-details-subtotal"
        styleOverride={{ lineHeight: '24px' }}
      >
        ${formatMUICurrency(subTotal)}
      </P>
    </Grid>
    {salesTax !== 0 && (
      <Grid container item direction="row" justify="space-between" style={style.taxRow}>
        <H4>Tax</H4>
        <H4 id="estimateTax">${formatMUICurrency(salesTax)}</H4>
      </Grid>
    )}
    <Grid
      container
      item
      direction="row"
      justify="space-between"
      style={{ ...style.rowNoBorder, paddingTop: '12px' }}
    >
      <Text customClass="headline-5" element="h4" style={{ color: palette.marine }}>
        Total
      </Text>
      <Text customClass="headline-5" element="p" style={{ color: palette.marine }}>
        ${formatMUICurrency(customerCost)}
      </Text>
    </Grid>
  </>
);

const style = {
  row: { borderBottom: `1px solid ${palette.tide}`, padding: '8px 0px' },
  rowNoBorder: { borderBottom: 'none', padding: '8px 0px' },
  subTitleRow: {
    marginTop: 25
  },
  taxRow: {
    borderBottom: `1px solid ${palette.tide}`,
    padding: '0 0 8px 0'
  },
  rowHeader: {
    borderBottom: `1px solid ${palette.tide}`,
    padding: '8px 0px',
    fontFamily: 'Manrope',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1,
    textTransform: 'uppercase' as 'uppercase',
    width: '100%'
  }
};
