import React, { FC, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';

interface IFixedContainerProps {
  fixedId: string;
  id?: string;
}

/**
 * Container designed to wrap a fixed element and mirror its height so that fixed elements do not overlap scrollable page content.
 * @param fixedId id of child element with fixed position
 * @example
 * <FixedContainer fixedId="jabroni">
 *   <h1 id="jabroni" style={{ position: fixed }}>Don't be a jabroni</h1>
 * </FixedContainer>
 */

export const FixedContainer: FC<IFixedContainerProps> = ({ children, id, fixedId }) => {
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    const height = document.getElementById(fixedId)?.clientHeight;
    setHeight(height);
  }, [fixedId]);

  return (
    <Box height={height} id={id}>
      {children}
    </Box>
  );
};
