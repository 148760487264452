import React, { FC } from 'react';
import { Radio, RadioProps, withStyles } from '@material-ui/core';
import { ReactComponent as CheckedRadioButton } from 'assets/icons/radio_button_checked.svg';
import { palette } from 'styles/global';

const CastleRadio = withStyles({
  colorPrimary: {
    '&$disabled': {
      color: palette.concrete
    },
    color: palette.marine
  },
  disabled: {}
})(Radio);

export const RadioButton: FC<RadioProps> = ({ onClick, checked, id, ...props }) => (
  <CastleRadio
    style={{ paddingRight: 10 }}
    color="primary"
    onClick={onClick}
    checkedIcon={<CheckedRadioButton />}
    checked={checked}
    id={id}
    {...props}
  />
);
