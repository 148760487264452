import React, { ReactNode, CSSProperties } from 'react';
import { palette, fontWeights } from 'styles/global';

interface ISubtitleProps {
  id?: string;
  children?: ReactNode;
  styleOverride?: CSSProperties;
  type?: '1' | '2' | '3' | '4' | '5';
  mui?: boolean;
  muiStyle?: 'regular' | 'bold';
}

export const Subtitle = ({
  children,
  type = '1',
  styleOverride,
  id,
  mui,
  muiStyle
}: ISubtitleProps) => {
  const style = mui && muiStyle ? styles.mui[muiStyle] : styles.grommet[type];
  return (
    <p id={id} style={{ ...style, ...styleOverride }}>
      {children}
    </p>
  );
};

Subtitle.defaultProps = { type: '1' };

const styles = {
  grommet: {
    1: {
      fontFamily: 'Assistant',
      fontWeight: 400,
      fontSize: 16,
      color: palette.slate,
      lineHeight: '20px',
      margin: 0
    },
    2: {
      fontFamily: 'Assistant',
      fontWeight: 400,
      fontSize: 14,
      color: palette.slate,
      lineHeight: '16px',
      margin: 0
    },
    3: {
      fontFamily: 'Assistant',
      fontWeight: 700,
      fontSize: 16,
      color: palette.white,
      lineHeight: '21px',
      margin: 0
    },
    4: {
      fontFamily: 'Assistant',
      fontWeight: 400,
      fontSize: 16,
      textAlign: 'center' as 'center',
      color: palette.firefly,
      lineHeight: '21px',
      margin: 0
    },
    5: {
      fontFamily: 'Assistant',
      fontWeight: 400,
      fontSize: 16,
      color: palette.white,
      lineHeight: '21px',
      margin: 0
    }
  },
  mui: {
    regular: {
      fontFamily: 'Manrope',
      fontWeight: fontWeights.regular,
      fontSize: 16,
      color: palette.marine,
      lineHeight: '20px',
      letterSpacing: '0px'
    },
    bold: {
      fontFamily: 'Manrope',
      fontWeight: fontWeights.bold,
      fontSize: 16,
      color: palette.marine,
      lineHeight: '20px',
      letterSpacing: '0px'
    }
  }
};
