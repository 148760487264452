import React, { useState, FC, useContext, useEffect, FormEvent } from 'react';
import { navigate } from '@reach/router';
import {
  Grid,
  Box,
  withStyles,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
  Button,
  FormHelperText
} from '@material-ui/core';
import { PhoneSharp, LockSharp, ErrorSharp } from '@material-ui/icons';
import MaskedInput from 'react-text-mask';

import { H1, H3, P, MUIButton, CastleBackdrop, A, CovidBannerSetter } from 'components';
import { fontWeights, palette } from 'styles/global';
import { pushGAEvent, removePhoneFormatting } from 'utils';
import { SnackbarContext } from 'context';

import { ReactComponent as CastleLogo } from 'assets/svg/Castle_Logo_marine.svg';
import BackgroundImage from 'assets/images/bg.png';
import { SNACKBAR_OPTIONS } from 'data/snackbar-options';
import './AuthSignIn.css';
import { BASE_URL } from 'data/env-vars';

interface IAuthSignInProps {
  onSubmit: (phoneNumber: string, password: string) => Promise<void>;
  clearError: () => void;
  loading: boolean;
}

export const AuthSignIn = ({ onSubmit, clearError, loading }: IAuthSignInProps) => {
  const { updateSnackbar, closeAllSnackbars } = useContext(SnackbarContext);

  const [phoneFocus, setPhoneFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [formInfo, setFormInfo] = useState<{ phoneNumber: string; password: string }>({
    phoneNumber: '',
    password: ''
  });

  useEffect(() => () => closeAllSnackbars(), [closeAllSnackbars]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    pushGAEvent({ id: 'login' });
    if (!phoneError && !passwordError && formInfo.password !== '' && formInfo.phoneNumber !== '') {
      await onSubmit(formInfo.phoneNumber, formInfo.password);
    } else {
      passwordError && setPasswordError(true);
      phoneError && setPhoneError(true);
      updateSnackbar(SNACKBAR_OPTIONS.loginError);
    }
  };

  return (
    <Box
      style={{
        backgroundColor: palette.marine,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        padding: '0px 18px 0px 18px'
      }}
    >
      <CovidBannerSetter />
      <form onSubmit={handleSubmit} noValidate>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item container justify="center" style={{ paddingTop: 40 }}>
            <CastleLogo />
          </Grid>
          <Grid item container justify="center" style={{ paddingTop: 102 }}>
            <H3
              mui
              styleOverride={{
                fontWeight: fontWeights.bold,
                fontSize: 14,
                color: palette.sawdust,
                letterSpacing: '1px',
                textTransform: 'uppercase',
                lineHeight: 0
              }}
            >
              Welcome Home
            </H3>
          </Grid>
          <Grid item container justify="center" style={{ paddingTop: 5 }}>
            <H1 mui styleOverride={{ color: palette.sawdust, lineHeight: 0 }}>
              Log In
            </H1>
          </Grid>
          <Grid item container justify="center" style={{ paddingTop: 70 }} sm={6}>
            <PhoneNumberCustomField
              error={phoneError}
              shrink={phoneFocus || formInfo.phoneNumber !== ''}
              onBlur={() => {
                if (
                  formInfo.phoneNumber === '' ||
                  removePhoneFormatting(formInfo.phoneNumber).length !== 12
                ) {
                  setPhoneError(true);
                }
                setPhoneFocus(false);
              }}
              onFocus={() => {
                setPhoneFocus(true);
                setPhoneError(false);
                clearError();
              }}
              onChange={(e) => {
                setFormInfo({
                  ...formInfo,
                  phoneNumber: e.currentTarget.value
                });
              }}
            />
          </Grid>
          <Grid item container justify="center" style={{ paddingTop: 15 }} sm={6}>
            <PasswordCustomField
              error={passwordError}
              shrink={passwordFocus || formInfo.password !== ''}
              onBlur={() => {
                if (formInfo.password === '') {
                  setPasswordError(true);
                }
                setPasswordFocus(false);
              }}
              onFocus={() => {
                setPasswordFocus(true);
                setPasswordError(false);
                clearError();
              }}
              onChange={(e) => {
                setFormInfo({
                  ...formInfo,
                  password: e.currentTarget.value
                });
              }}
            />
          </Grid>
          <Grid item container justify="flex-end" sm={6} style={{ paddingTop: 15 }}>
            <Button
              id="forgot-password-lbl"
              style={{ textTransform: 'none', marginLeft: 10 }}
              onClick={() => {
                pushGAEvent({ id: 'forgot-password-lbl' });
                navigate(`${BASE_URL}/forgot-password`);
              }}
            >
              <P mui muiType="sub-header-bold" styleOverride={{ color: palette.sawdust }}>
                Forgot Password?
              </P>
            </Button>
          </Grid>
          <Grid item container sm={6} style={{ paddingTop: 65 }}>
            <MUIButton
              label="Log In"
              fullWidth={true}
              buttonType="submit"
              type="outline"
              id="login"
              buttonStyleOverride={{
                backgroundColor: palette.sawdust,
                paddingBottom: 13,
                paddingTop: 8
              }}
              textStyleOverride={{
                fontFamily: 'Manrope',
                fontWeight: fontWeights.bold,
                fontSize: 18
              }}
            />
          </Grid>
          <Grid item container justify="center" sm={6} style={{ paddingTop: 20 }}>
            <LoginLink id="terms-login" destination="terms" text="Terms & Conditions" />
            <LinkSeparator />
            <LoginLink id="privacy-login" destination="privacy" text="Privacy Policy" />
            <LinkSeparator />
            <LoginLink id="payment-terms-login" destination="payment-terms" text="Payment Terms" />
          </Grid>
        </Grid>
      </form>
      <CastleBackdrop open={loading} />
    </Box>
  );
};

interface IPhoneNumberPasswordCustomField {
  shrink: boolean;
  error: boolean;
  onFocus: () => void;
  onBlur: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const PasswordCustomField: FC<IPhoneNumberPasswordCustomField> = ({
  error,
  shrink,
  onFocus,
  onChange,
  onBlur
}) => {
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  return (
    <CssTextField variant="outlined" className="altInputFill" fullWidth>
      <InputLabel htmlFor="password" shrink={shrink} style={{ marginLeft: 30 }} error={error}>
        <P
          mui
          muiType="body-bold"
          styleOverride={{
            color: 'inherit',
            padding: '0px 12px'
          }}
        >
          Password
        </P>
      </InputLabel>
      <OutlinedInput
        required
        error={error}
        onFocus={onFocus}
        onBlur={onBlur}
        type={showConfirmPassword ? 'text' : 'password'}
        onChange={onChange}
        id="password"
        startAdornment={
          <InputAdornment position="end">
            <LockSharp />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <Button onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              <P mui muiType="caption-body-bold" styleOverride={{ color: palette.sawdust }}>
                {showConfirmPassword ? 'HIDE' : 'SHOW'}
              </P>
            </Button>
          </InputAdornment>
        }
      />
      {error && (
        <FormHelperText>
          <ErrorSharp style={{ fontSize: 10, padding: '3px 5px 0px' }} />
          Password is required
        </FormHelperText>
      )}
    </CssTextField>
  );
};

const PhoneNumberCustomField: FC<IPhoneNumberPasswordCustomField> = ({
  error,
  shrink,
  onFocus,
  onChange,
  onBlur
}) => (
  <CssTextField variant="outlined" className="altInputFill" fullWidth>
    <InputLabel htmlFor="phoneNumber" shrink={shrink} style={{ marginLeft: 30 }} error={error}>
      <P
        mui
        muiType="body-bold"
        styleOverride={{
          color: 'inherit',
          padding: '0px 12px'
        }}
      >
        Phone Number
      </P>
    </InputLabel>
    <OutlinedInput
      required
      error={error}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      type="tel"
      id="phoneNumber"
      startAdornment={
        <InputAdornment position="end">
          <PhoneSharp />
        </InputAdornment>
      }
      inputComponent={TextMaskCustom as any}
    />
    {error && (
      <FormHelperText>
        <ErrorSharp style={{ fontSize: 10, padding: '3px 5px 0px' }} />
        Phone Number is required
      </FormHelperText>
    )}
  </CssTextField>
);

const LinkSeparator: FC = () => (
  <Grid item style={{ padding: '0px 8px' }}>
    <P mui muiType="caption-body-bold" styleOverride={{ color: palette.granite }}>
      //
    </P>
  </Grid>
);

interface ILoginLink {
  destination: string;
  text: string;
  id: string;
}

const LoginLink: FC<ILoginLink> = ({ destination, text, id }) => (
  <Grid item>
    <A
      mui
      styleOverride={{ textDecoration: 'none', cursor: 'pointer' }}
      onClick={() => {
        window.open(`${window.location.origin}${BASE_URL}/${destination}`);
        pushGAEvent({ id });
      }}
    >
      <P
        mui
        muiType="caption-body-bold"
        styleOverride={{ color: palette.stone, textTransform: 'none' }}
      >
        {text}
      </P>
    </A>
  </Grid>
);

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  showMask: boolean;
}

const TextMaskCustom: FC<TextMaskCustomProps> = ({ inputRef, showMask, ...other }) => (
  <MaskedInput
    {...other}
    ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask={showMask}
  />
);

const CssTextField = withStyles({
  root: {
    borderColor: palette.sawdust,
    fontFamily: 'Source Sans Pro',
    fontWeight: fontWeights.regular,
    fontSize: 16,
    lineHeight: 22,
    letterSpacing: 0,
    '& .MuiInputLabel-shrink': {
      transform: `translate(-15px, -6px) scale(0.75)`,
      backgroundColor: palette.marine
    },
    '& .MuiInputLabel-outlined': {
      color: palette.sawdust,
      '&.Mui-error': {
        color: palette.brick
      }
    },
    '& .MuiFormHelperText-contained': {
      color: palette.brick,
      margin: 0,
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.normal,
      fontSize: 12,
      lineHeight: '16px',
      paddingTop: 5
    },
    '& .MuiOutlinedInput-root': {
      borderColor: palette.sawdust,
      '& fieldset': {
        borderColor: palette.sawdust
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.sawdust
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.tarnishedGold
      },
      '& .MuiOutlinedInput-input': {
        color: palette.sawdust,
        paddingLeft: 16
      },
      '& .MuiInputAdornment-root': {
        color: palette.sawdust
      },
      '&.Mui-error': {
        borderColor: palette.brick,
        color: palette.brick,
        '& .MuiInputAdornment-root': {
          color: palette.brick
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.brick
        }
      }
    }
  }
})(FormControl);
