import React, { FC, useEffect } from 'react';

import { CardServiceCanceled, CardServiceCompleted, CardServiceInProgress } from 'components';
import { DashboardService } from 'core/interfaces';
import { ServiceStatus } from 'generated/graphql';
import { pushDashboardServiceCard } from 'utils';
interface CardServiceProps {
  id: string;
  service: DashboardService;
}

export const CardService: FC<CardServiceProps> = ({ id, service }) => {
  useEffect(() => pushDashboardServiceCard({ ...service }));

  switch (service.status) {
    case ServiceStatus.EstimateAccepted:
      return <CardServiceInProgress id={id} service={service} />;
    case ServiceStatus.ServiceCompletedPendingPayment:
      return <CardServiceCompleted id={id} service={service} />;
    case ServiceStatus.CanceledPendingPayment:
      return <CardServiceCanceled id={id} service={service} />;
    default:
      return <></>;
  }
};
