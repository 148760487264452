import React, { FC } from 'react';

import { P } from 'components';

interface DividerProps {
  item?: string;
  style?: React.CSSProperties;
}

export const Divider: FC<DividerProps> = ({ item = '/ ', style }) => {
  return (
    <P mui muiType="divider" styleOverride={{ ...style, overflow: 'hidden', whiteSpace: 'nowrap' }}>
      {new Array(300).join(item)}
    </P>
  );
};
