import React, { CSSProperties, FC } from 'react';
import { Box } from '@material-ui/core';

import { palette } from 'styles/global';

interface IEstimateDivider {
  styleOverride?: CSSProperties;
}

export const EstimateDivider: FC<IEstimateDivider> = ({ styleOverride }) => (
  <Box
    style={{
      borderTop: `2px dotted ${palette.ultraMarine}`,
      marginTop: 27,
      opacity: 0.2,
      ...styleOverride
    }}
  ></Box>
);
