import React, { FC } from 'react';
import { Payment } from '@material-ui/icons';
import { Box, Button, Card, CardActions, CardContent } from '@material-ui/core';

import { palette } from 'styles/global';
import { Text } from 'components';

interface CardAddPaymentMethodProps {
  id: string;
  action: {
    id: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
}

export const CardAddPaymentMethod: FC<CardAddPaymentMethodProps> = ({ id, action }) => (
  <Box maxWidth="484px" maxHeight="200px" paddingY="8px">
    <Card id={id} style={{ backgroundColor: palette.caribbean, borderRadius: 8 }} elevation={3}>
      <CardContent style={{ padding: '16px 20px' }}>
        <Box display="flex" flexDirection="column">
          <Text
            element="h4"
            customClass="headline-5"
            style={{ maxWidth: '250px', marginBottom: '4px', color: palette.sawdust }}
          >
            Prepare for Your Estimate
          </Text>
          <Text element="p" customClass="body" style={{ color: palette.sawdust }}>
            A payment method is required to accept your estimate and begin service. Adding a payment
            method to your account now can streamline your review process.
          </Text>
        </Box>
      </CardContent>
      <CardActions style={{ padding: '8px 20px 20px', justifyContent: 'center' }}>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            id={action.id}
            startIcon={<Payment style={{ color: palette.sawdust }} />}
            onClick={action.onClick}
            style={{
              border: '2px solid white',
              width: '100%',
              maxWidth: '300px'
            }}
          >
            <Text
              element="p"
              customClass="headline-5"
              style={{ color: palette.sawdust, textTransform: 'none' }}
            >
              Add Payment Method
            </Text>
          </Button>
        </Box>
      </CardActions>
    </Card>
  </Box>
);
