import React, { FC, useState, Fragment } from 'react';
import { Box, Button, Collapse, Grid } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Text } from 'components';
import { palette } from 'styles/global';
import { pushGAEvent } from 'utils';
import IconDiscountAnimated from 'components/icons/IconDiscountAnimated';

interface IDiscountBar {
  title: string | undefined;
  terms: string | 'N/A' | null | undefined;
  variant?: 'single' | '';
}

export const DiscountBar: FC<IDiscountBar> = ({ title, terms, variant }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={variant === 'single' ? styles.discountBarSingle : styles.discountBar}
      >
        <Grid item style={{ padding: '0 10px' }}>
          <IconDiscountAnimated style={{ height: 35 }} />
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Text customClass="overline" style={styles.title}>
            Discounts
          </Text>
        </Grid>

        <Grid item>
          <Button
            style={styles.details}
            onClick={() => {
              setExpanded(!expanded);
              !expanded
                ? pushGAEvent({ id: 'discount-bar-expand-lbl', type: 'link' })
                : pushGAEvent({ id: 'discount-bar-close-lbl', type: 'link' });
            }}
          >
            Details
            {!expanded ? <ExpandMore /> : <ExpandLess />}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={expanded}>
        <Box
          style={variant === 'single' ? styles.collapseContainerSingle : styles.collapseContainer}
        >
          <Text customClass="body" style={styles.descTitle}>
            {title}
          </Text>
          <Text customClass="body" style={{ lineHeight: 1.3 }}>
            {terms?.split('\\n').map((x, index) => (
              <Fragment key={`title-${index}`}>
                {x}
                <br />
              </Fragment>
            ))}
          </Text>
        </Box>
      </Collapse>
    </>
  );
};

const styles = {
  discountBarSingle: {
    backgroundColor: palette.caribbean,
    display: 'flex',
    flexWrap: 'nowrap' as 'nowrap',
    borderRadius: '8px 8px 0 0'
  },
  discountBar: {
    backgroundColor: palette.caribbean,
    margin: '25px 0  0',
    padding: '5px 0 15px',
    display: 'flex',
    minHeight: 30,
    flexWrap: 'nowrap' as 'nowrap',
    borderRadius: '8px 8px 0 0'
  },

  title: {
    color: palette.pvcWhite,
    fontSize: 14,
    fontWeight: 600,
    paddingTop: 7,
    paddingBottom: 5,
    paddingRight: 10,
    fontFamily: 'Manrope',
    textTransform: 'uppercase' as 'uppercase'
  },
  details: {
    color: palette.pvcWhite,
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase' as 'uppercase',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Manrope',
    letterSpacing: 1,
    padding: '0 5px 0',

    '&:hover': {
      color: 'red',
      textDecoration: 'none !important',
      cursor: 'pointer'
    }
  },
  collapseContainer: {
    backgroundColor: palette.caribbean,
    color: palette.pvcWhite,
    padding: '0 15px 30px'
  },
  collapseContainerSingle: {
    backgroundColor: palette.caribbean,
    color: palette.pvcWhite,
    padding: '0 15px 30px',
    borderRadius: '0'
  },
  descTitle: {
    fontWeight: 700,
    paddingBottom: 8
  }
};
