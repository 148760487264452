import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { EstimateStatus, Service } from 'generated/graphql';
import { formatCurrency, pushGAEvent } from 'utils';
import { SERVICE_CATEGORIES, SERVICE_TYPES } from 'data/constants';
import {
  CastleDialog,
  EstimateInstructions,
  EstimateItems,
  EstimatesActions,
  EstimatesBreadcrumb,
  EstimatesNav,
  P
} from 'components';
import { Text } from 'components/Shared';
import { EstimatesStepperStyles } from './EstimatesStepperStyles';
import { IAction } from 'core/interfaces';
import { Button, Grid } from '@material-ui/core';
import { IssueContext } from 'context';
import { palette } from 'styles/global';
import { navigate } from '@reach/router';
import { BASE_URL } from 'data/env-vars';

interface IEstimateStepper {
  serviceList: Service[];
  projectId: string;
  onNavigateToConfirm: (actions: IAction[]) => void;
}

export const EstimateStepper: FC<IEstimateStepper> = ({
  serviceList,
  onNavigateToConfirm,
  projectId
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [currentServiceSelectedEstimate, setCurrentServiceSelectedEstimate] = useState('');
  const [estimatesGrossCost, setEstimatesGrossCost] = useState(0);
  const [estimatesSubtotal, setEstimatesSubtotal] = useState(0);
  const [actions, setActions] = useState<IAction[]>([]);
  const [navigateToConfirm, setNavigateToConfirm] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { getEstimateFromId } = useContext(IssueContext);

  const classes = EstimatesStepperStyles();
  const maxSteps = serviceList!.length;
  const hasOptions = currentServiceSelectedEstimate === '';
  const serviceProvider = serviceList[activeStep].serviceProvider?.name;
  const hasServiceLevelPromotion = serviceList[activeStep]!.promotionReference!.length > 0;
  const serviceType = SERVICE_TYPES[serviceList[activeStep].type];
  const categoryName = SERVICE_CATEGORIES[serviceList[activeStep].category];

  const handleNext = () => {
    activeStep !== maxSteps - 1
      ? setActiveStep((prevActiveStep) => prevActiveStep + 1)
      : setNavigateToConfirm(true);
  };

  const handlePrev = () => {
    activeStep !== 0 && setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleActions = (status: EstimateStatus.Accepted | EstimateStatus.Declined) => {
    let action: IAction = {
      serviceId: serviceList[activeStep].id,
      status
    };
    action =
      status === EstimateStatus.Accepted
        ? { ...action, estimateId: currentServiceSelectedEstimate }
        : action;
    const actionsArray = Object.assign([], actions) as IAction[];
    const matchedIndex = actionsArray.findIndex((x) => x.serviceId === serviceList[activeStep].id);
    if (matchedIndex >= 0) {
      const filteredActions = actionsArray.filter((_action, index) => index !== matchedIndex);
      filteredActions.push(action);
      setActions(filteredActions);
    } else {
      actionsArray.push(action);
      setActions(actionsArray);
    }
    handleNext();
    setCurrentServiceSelectedEstimate('');
  };

  const handleSkip = () => {
    const actionsArray = Object.assign([], actions) as IAction[];
    const matchedIndex = actionsArray.findIndex((x) => x.serviceId === serviceList[activeStep].id);
    const filteredActions = actionsArray.filter((_action, index) => index !== matchedIndex);
    setActions(filteredActions);
    handleNext();
    setCurrentServiceSelectedEstimate('');
  };

  const getEstimatesTotal = (estimateId: string) => {
    setEstimatesSubtotal(
      getEstimateFromId(projectId, serviceList[activeStep].id, estimateId)?.subtotal!
    );
    setEstimatesGrossCost(
      getEstimateFromId(projectId, serviceList[activeStep].id, estimateId)?.grossCost!
    );
  };

  const getMatchedAction = useCallback(() => {
    return actions.find((x) => x.serviceId === serviceList[activeStep]?.id);
  }, [actions, activeStep, serviceList]);

  const setMatchedActionEstimate = useCallback((action?: IAction) => {
    if (!!action && !!action.estimateId && action.status === EstimateStatus.Accepted) {
      setCurrentServiceSelectedEstimate(action.estimateId);
    }
  }, []);

  useEffect(() => {
    const matchedAction = getMatchedAction();
    if (!currentServiceSelectedEstimate && !!matchedAction) {
      setMatchedActionEstimate(matchedAction);
    }
  }, [getMatchedAction, setMatchedActionEstimate, currentServiceSelectedEstimate]);

  useEffect(() => {
    if (actions.length === 0 && navigateToConfirm) {
      setOpenModal(true);
    } else if (navigateToConfirm) {
      onNavigateToConfirm(actions);
    }
  }, [navigateToConfirm, onNavigateToConfirm, actions]);

  return (
    <>
      <Text customClass="headline-3" style={{ color: palette.marine, margin: '35px 15px 10px' }}>
        {`Review Estimate${serviceList.length > 1 ? 's' : ''}`}
      </Text>

      {activeStep === 0 && (
        <EstimateInstructions isMultiService={serviceList.length > 1} projectId={projectId} />
      )}
      <EstimatesBreadcrumb plural={serviceList.length > 1} variant="estimate" />
      <div className={classes.estimateStepper}>
        <EstimatesNav
          maxSteps={maxSteps}
          activeStep={activeStep}
          classes={classes}
          handleNext={() => {
            handleNext();
            setCurrentServiceSelectedEstimate('');
          }}
          handlePrev={() => {
            handlePrev();
            setCurrentServiceSelectedEstimate('');
          }}
        />
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          style={{ marginBottom: 20 }}
        >
          <Grid item>
            <Text element="h4" customClass="headline-4" additionalClasses={`${classes.headline}`}>
              {serviceType === 'Small work' ? categoryName : serviceType} service
            </Text>
          </Grid>
          <Grid item style={{ textAlign: 'right' }}>
            <Text customClass="body" additionalClasses={`${classes.estimateSubtotal}`}>
              {currentServiceSelectedEstimate ? formatCurrency(estimatesSubtotal) : '$ – – –'}
            </Text>
            {/* If there is a serviceLevelPromotion && currentServiceSelectedEstimate */}
            {hasServiceLevelPromotion && currentServiceSelectedEstimate && (
              <Text customClass="body" additionalClasses={`${classes.estimateGrossCost}`}>
                {currentServiceSelectedEstimate ? formatCurrency(estimatesGrossCost) : '$ – – –'}
              </Text>
            )}
          </Grid>
        </Grid>
        <Text customClass="headline-5" additionalClasses={`${classes.subHeadline}`}>
          {serviceProvider}
        </Text>

        <EstimateItems
          service={serviceList[activeStep]}
          onEstimateSelect={(estimateId: string) => {
            pushGAEvent({ id: `estimate-selection-${estimateId}-btn` });
            setCurrentServiceSelectedEstimate(estimateId);
            getEstimatesTotal(estimateId);
          }}
          selectedEstimate={currentServiceSelectedEstimate}
        />
        <EstimatesActions
          classes={classes}
          handleActions={handleActions}
          handleSkip={handleSkip}
          accepted={
            getMatchedAction()?.status === EstimateStatus.Accepted &&
            getMatchedAction()?.estimateId === currentServiceSelectedEstimate
          }
          declined={getMatchedAction()?.status === EstimateStatus.Declined}
          hasOptions={hasOptions}
        />
        <EstimatesNav
          maxSteps={maxSteps}
          activeStep={activeStep}
          classes={classes}
          handleNext={() => {
            handleNext();
            setCurrentServiceSelectedEstimate('');
          }}
          handlePrev={() => {
            handlePrev();
            setCurrentServiceSelectedEstimate('');
          }}
        />
      </div>
      <ExitDialog
        onExit={() => {
          pushGAEvent({ id: 'estimate-exit-lnk' });
          navigate(`${BASE_URL}/`);
        }}
        onKeepReview={() => {
          pushGAEvent({ id: 'estimate-keep-reviewing-lnk' });
          setActiveStep(0);
          setOpenModal(false);
        }}
        open={openModal}
      />
    </>
  );
};

interface IExitDialog {
  open: boolean;
  onExit: () => void;
  onKeepReview: () => void;
}

const ExitDialog: FC<IExitDialog> = ({ open, onExit, onKeepReview }) => (
  <CastleDialog
    open={open}
    title={'Confirm Exit'}
    actions={
      <Grid item container style={{ paddingTop: 21 }} justify="flex-end">
        <Button style={{ textTransform: 'none' }} onClick={onKeepReview}>
          <P mui muiType="sub-header-bold" styleOverride={{ color: palette.granite }}>
            Keep Reviewing
          </P>
        </Button>
        <Button style={{ textTransform: 'none', marginLeft: 10 }} onClick={() => onExit()}>
          <P mui muiType="sub-header-bold" styleOverride={{ color: palette.caribbean }}>
            Exit
          </P>
        </Button>
      </Grid>
    }
  >
    <P mui muiType="body-2-large">
      Exit without confirming your estimate decisions? Changes will not be saved.
    </P>
  </CastleDialog>
);
