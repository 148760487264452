import React, { forwardRef } from 'react';

import { palette } from 'styles/global';
import { H3, Spinner } from 'components';

interface ICastleButtonProps {
  id?: string;
  a11yTitle?: string;
  type: 'primary' | 'secondary';
  label: string | React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export type Ref = HTMLButtonElement;

export const CastleButton = forwardRef<Ref, ICastleButtonProps>(
  ({ id, a11yTitle, type, label, onClick, loading, disabled, style }, ref) => {
    return (
      <button
        ref={ref}
        id={id}
        aria-label={a11yTitle}
        onClick={onClick}
        disabled={disabled}
        style={{ ...styles.button.base, ...styles.button[type], ...style }}
      >
        <Spinner hide={!loading} />
        <H3 styleOverride={{ ...styles.label.base, ...styles.label[type] }}>{label}</H3>
        <Spinner hide />
      </button>
    );
  }
);

CastleButton.defaultProps = { type: 'primary' };

const styles = {
  button: {
    base: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: 'none',
      padding: '0px 10px',
      borderRadius: '55px',
      height: '40px',
      cursor: 'pointer'
    },
    primary: {
      backgroundColor: palette.ocean
    },
    secondary: {
      backgroundColor: palette.white,
      border: `1px solid ${palette.edward}`
    }
  },
  label: {
    base: {
      fontSize: '19px',
      fontWeight: 'bold' as 'bold',
      margin: '0px 10px'
    },
    primary: {
      color: palette.white
    },
    secondary: {
      color: palette.coal
    }
  }
};
