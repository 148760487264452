import { CSSProperties } from 'react';
import { MAX_WIDTH_PX } from 'data/constants';

export const sticky: CSSProperties = {
  position: 'fixed',
  margin: '0 auto',
  left: 0,
  right: 0,
  width: '100%',
  maxWidth: MAX_WIDTH_PX,
  zIndex: 10
};

export const textNoWrapEllipsis: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  width: '100%',
  minWidth: '1px'
};
