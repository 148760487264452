import React, { FC } from 'react';
import { Box, Button } from '@material-ui/core';
import { pushGAEvent } from 'utils';
import { EstimateStatus } from 'generated/graphql';

interface IEstimatesActions {
  classes: any;
  handleActions: (actionType: EstimateStatus.Accepted | EstimateStatus.Declined) => void;
  handleSkip: () => void;
  accepted: boolean;
  declined: boolean;
  hasOptions: boolean;
}

export const EstimatesActions: FC<IEstimatesActions> = ({
  classes,
  handleActions,
  handleSkip,
  accepted,
  declined,
  hasOptions
}) => {
  return (
    <Box style={{ padding: '10px 0', marginTop: 10, marginBottom: 40 }}>
      <Button
        className={`${classes.btnAccept}  ${accepted && classes.btnAccepted}`}
        TouchRippleProps={{ classes: { rippleVisible: classes.buttonAcceptRipple } }}
        onClick={() => {
          pushGAEvent({ id: 'accept-estimate', type: 'button' });
          handleActions(EstimateStatus.Accepted);
        }}
        disabled={hasOptions}
        style={{ textTransform: 'none' }}
      >
        <span>{accepted ? 'Accepted' : 'Accept'}</span>
      </Button>

      <Button
        className={`${classes.btnDecline}  ${declined && classes.btnDeclined}`}
        TouchRippleProps={{ classes: { rippleVisible: classes.buttonDeclineRipple } }}
        onClick={() => {
          pushGAEvent({ id: 'decline-estimate', type: 'button' });
          handleActions(EstimateStatus.Declined);
        }}
        style={{ textTransform: 'none' }}
      >
        {declined ? 'Declined' : 'Decline'}
      </Button>

      <Button
        className={classes.btnSkip}
        fullWidth
        disableRipple
        onClick={() => {
          pushGAEvent({ id: 'estimate-skip-lnk', type: 'link' });
          handleSkip();
        }}
      >
        Skip for now
      </Button>
    </Box>
  );
};
