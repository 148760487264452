// Apollo Boost

// NOTE: Apollo Boost is the new recommended framework for using Apollo.  It simplifies
// the configuration overall, but we should be aware that the price of this is
// reduced flexibility.  The main limitation is lack of support for Subscriptions
// (live websocket connections).  If we decide that we need Subscriptions, we will need
// to do some manual labor to migrate to a custom configuration.  See:
// https://www.apollographql.com/docs/react/advanced/boost-migration/.

import { Auth } from 'aws-amplify';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_BROWSER
});

const getToken = async () => {
  try {
    const session = await Auth.currentSession();
    if (session && session.getIdToken()) {
      return session.getIdToken().getJwtToken();
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : ''
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client;
