import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';

import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import awsConfig from './aws-exports';

console.log('App Version:', process.env.REACT_APP_GIT_HASH);

Amplify.configure({
  ...awsConfig,
  authenticationFlowType: 'USER_PASSWORD_AUTH'
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
