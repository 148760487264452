import React, { FC, useEffect } from 'react';
import { Box, Card, CardContent } from '@material-ui/core';

import { P, Text } from 'components';
import { DashboardAppointment, SchedulingDashboardAppointment } from 'core/interfaces';
import { formatDateWithTimezone, formatTimeWindowWithTimezone } from 'core/utils';
import { Variant, When } from 'enums';
import { palette } from 'styles/global';
import { pushDashboardAppointmentCard } from 'utils';
import { SERVICE_TYPES } from 'data/constants';

interface CardAppointmentProps {
  id: string;
  groupedAppointments?: DashboardAppointment[];
  inSchedulingAppointments?: SchedulingDashboardAppointment[];
}

const SCHEDULING_LABEL = 'Scheduling in Progress';

export const CardAppointment: FC<CardAppointmentProps> = ({
  id,
  groupedAppointments,
  inSchedulingAppointments
}) => {
  const [appointment] = groupedAppointments || [];
  const [inSchedulingAppointment] = inSchedulingAppointments || [];
  const title = appointment?.title || inSchedulingAppointment?.title;
  const data = appointment?.data;
  const { provider, startAt, endAt, timezone, when } = data || {};

  let date: string | undefined;
  let time: string | undefined;
  let variant = Variant.Light;

  if (startAt && endAt && timezone) {
    const formatString = new Date(startAt).getMonth() === 4 ? 'MMM DD, YYYY' : 'MMM. DD, YYYY';

    date = formatDateWithTimezone(startAt, formatString, timezone);
    time = formatTimeWindowWithTimezone(startAt, endAt, timezone);
  }

  if (when) {
    variant = when === When.Today ? Variant.Dark : Variant.Light;
  }

  const backgroundColor = variant === Variant.Dark ? palette.ultraMarine : palette.sawdust;
  const color = variant === Variant.Dark ? palette.sawdust : palette.ultraMarine;

  useEffect(() =>
    pushDashboardAppointmentCard({ variant, title, provider, date, time: time || SCHEDULING_LABEL })
  );

  const groupedAppointmentMap = (appointment: DashboardAppointment, index: number) => {
    const serviceText =
      appointment?.data?.serviceType === SERVICE_TYPES.NEW_SERVICE_OFFERING ||
      appointment?.data?.serviceType === SERVICE_TYPES.SMALL_WORK
        ? appointment?.data?.serviceCategory
        : appointment?.data?.serviceType;
    const estimateTitle = appointment.data?.acceptedEstimateTitle;

    return (
      <Text key={index} customClass="body" element="p" style={{ color }}>
        {estimateTitle ? estimateTitle : `${serviceText} service`}
      </Text>
    );
  };

  return (
    <Box width="280px" display="flex">
      <Card
        id={id}
        style={{ backgroundColor, borderRadius: 8, margin: '8px', display: 'flex', width: 280 }}
        elevation={3}
      >
        <CardContent
          style={{
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100% - 32px)'
          }}
        >
          <Box style={{ marginBottom: 24 }}>
            {groupedAppointments && <Provider {...{ provider, color }} />}
            {inSchedulingAppointments &&
              inSchedulingAppointments.map((data, index) => (
                <Text key={index} customClass="body" element="p" style={{ color }}>
                  {data.serviceName}
                </Text>
              ))}
            {groupedAppointments && groupedAppointments.map(groupedAppointmentMap)}
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <DateComponent {...{ date, color }} />
            <Time {...{ time, color }} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

interface ProviderProps {
  provider?: string;
  color: string;
}

const Provider: FC<ProviderProps> = ({ provider, color }) => {
  const visibility = !provider ? 'hidden' : undefined;
  return (
    <Text customClass="body-b" element="p" style={{ color, visibility }}>
      {provider || 'TBD'}
    </Text>
  );
};

interface DateProps {
  date?: string;
  color: string;
}

const DateComponent: FC<DateProps> = ({ date, color }) => {
  const visibility = !date ? 'hidden' : undefined;
  return (
    <P mui muiType="body-bold" styleOverride={{ color, visibility }}>
      {date || 'TBD'}
    </P>
  );
};

interface TimeProps {
  time?: string;
  color: string;
}

const Time: FC<TimeProps> = ({ time, color }) => {
  return (
    <P mui muiType="body-bold" styleOverride={{ color }}>
      {time || <i>{SCHEDULING_LABEL}</i>}
    </P>
  );
};
