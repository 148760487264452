import React, { CSSProperties } from 'react';
import { Typography, withStyles, Theme, StyledComponentProps } from '@material-ui/core';
import { CSSProperties as MuiCSSProperties } from '@material-ui/core/styles/withStyles';

//#region - Text Types and Interfaces
interface ITextStyles {
  customClass: Classes;
  element?: ElementType;
  style?: CSSProperties;
  additionalClasses?: string;
  id?: string;
}

type ElementType = 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span';

export type Classes =
  | 'headline-1'
  | 'headline-2'
  | 'headline-3'
  | 'headline-4'
  | 'headline-4-b'
  | 'headline-5'
  | 'headline-5-i'
  | 'overline'
  | 'overline-b'
  | 'body'
  | 'body-b'
  | 'body-i'
  | 'body-bi'
  | 'body-lg'
  | 'body-lg-b'
  | 'body-lg-i'
  | 'body-sm'
  | 'body-sm-b'
  | 'body-sm-i'
  | 'button';
//#endregion

//#region - Component Type
export type TextCompType = React.ComponentType<
  Pick<
    {
      classes: any;
    },
    never
  > &
    StyledComponentProps<Classes> &
    ITextStyles
>;
//#endregion

//#region - Text styles
export const TextStyles: (theme: Theme) => Record<Classes, MuiCSSProperties> = (theme: Theme) => ({
  'headline-1': {
    fontSize: 42,
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: 1.15
  },
  'headline-2': {
    fontSize: 30,
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: 1.2
  },
  'headline-3': {
    fontSize: 30,
    fontFamily: 'Manrope',
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: -1
  },
  'headline-4': {
    fontSize: 24,
    fontFamily: 'Manrope',
    fontWeight: 400,
    lineHeight: 1.25
  },
  'headline-4-b': {
    fontSize: 24,
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: 1.25
  },
  'headline-5': {
    fontSize: 18,
    fontFamily: 'Manrope',
    fontWeight: 700,
    lineHeight: 1.25
  },
  'headline-5-i': {
    fontSize: 20,
    fontFamily: 'Source Sans Pro',
    fontWeight: 700,
    lineHeight: 1.25
  },
  overline: {
    fontSize: 14,
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: 1.25,
    letterSpacing: 1.4,
    textTransform: 'uppercase'
  },
  'overline-b': {
    fontSize: 14,
    fontFamily: 'Manrope',
    fontWeight: 700,
    lineHeight: 1.25,
    letterSpacing: 1.4,
    textTransform: 'uppercase'
  },
  body: {
    fontSize: 14,
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    lineHeight: 1.25
  },
  'body-b': {
    fontSize: 14,
    fontFamily: 'Source Sans Pro',
    fontWeight: 700,
    lineHeight: 1.25
  },
  'body-i': {
    fontSize: 14,
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontStyle: 'italic',
    lineHeight: 1.25
  },
  'body-bi': {
    fontSize: 14,
    fontFamily: 'Source Sans Pro',
    fontWeight: 700,
    fontStyle: 'italic',
    lineHeight: 1.25
  },
  'body-lg': {
    fontSize: 16,
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    lineHeight: 1.25
  },
  'body-lg-b': {
    fontSize: 16,
    fontFamily: 'Source Sans Pro',
    fontWeight: 700,
    lineHeight: 1.25
  },
  'body-lg-i': {
    fontSize: 16,
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontStyle: 'italic',
    lineHeight: 1.25
  },
  'body-sm': {
    fontSize: 12,
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    lineHeight: 1.25
  },
  'body-sm-b': {
    fontSize: 12,
    fontFamily: 'Source Sans Pro',
    fontWeight: 700,
    lineHeight: 1.25
  },
  'body-sm-i': {
    fontSize: 12,
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    fontStyle: 'italic',
    lineHeight: 1.25
  },
  button: {
    fontSize: 12,
    fontFamily: 'Manrope',
    fontWeight: 700,
    lineHeight: 1.5,
    textTransform: 'uppercase'
  }
});

//#endregion

const TextComp: TextCompType = ({
  children,
  classes,
  customClass,
  element = 'p',
  style,
  additionalClasses,
  id
}) => (
  <Typography
    component={element}
    id={id}
    className={`${classes && classes[customClass]} ${additionalClasses}`}
    style={style}
  >
    {children}
  </Typography>
);
/**
 * Text Component used globally and cross application for all heading and body copy.
 *
 * @param {Classes} customClasses - Takes in default classnames that match type styles from component library
 * @param {ElementType} [element] - Type of html element to use.
 * @param {CSSProperties} [style] - Custom style overrides
 * @param {string} [additionalClasses] - Add additional classes that are component specific
 */
export const Text = withStyles(TextStyles)(TextComp);
