import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';

import './ScrollHorizontal.css';

interface ScrollHorizontalProps {
  marginX?: string;
  paddingX?: string;
}

export const ScrollHorizontal: FC<PropsWithChildren<ScrollHorizontalProps>> = props => {
  const [dragging, setDragging] = useState(false);
  const count = React.Children.count(props.children);

  useEffect(() => {
    document.body.classList[dragging ? 'add' : 'remove']('indiana-dragging-override');
  }, [dragging]);

  return (
    <Box marginX={props.marginX}>
      <Box onMouseDown={() => setDragging(true)} onMouseUp={() => setDragging(false)}>
        <ScrollContainer
          horizontal={true}
          hideScrollbars={true}
          onEndScroll={() => setDragging(false)}
          style={{ overflow: 'auto', cursor: dragging ? 'grabbing' : 'grab' }}
        >
          <Grid container direction="row" wrap="nowrap">
            {React.Children.map(props.children, (child, idx) => {
              const first = { paddingLeft: idx === 0 ? props.paddingX : undefined };
              const last = { paddingRight: idx === count - 1 ? props.paddingX : undefined };
              return (
                <Grid item key={idx} style={{ ...first, ...last, display: 'flex' }}>
                  {child}
                </Grid>
              );
            })}
          </Grid>
        </ScrollContainer>
      </Box>
    </Box>
  );
};
