import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import Markdown from 'markdown-to-jsx';

import { P, H4, H5, Text } from 'components';
import { palette, fontWeights } from 'styles/global';
import {
  Service,
  ServiceEstimate,
  ServiceStatus,
  Invoice,
  Issue,
  PromotionReference
} from 'generated/graphql';
import { formatMUICurrency, pushGAEvent } from 'utils';
import { BASE_URL } from 'data/env-vars';

import './EstimateList.css';

interface IEstimateListProps {
  issue?: Issue;
  service: Service;
  estimate?: ServiceEstimate;
  receipt?: Invoice;
  variant?: 'estimatesStepper';
  onClick?: () => void;
}

export const EstimateList = ({
  issue,
  service,
  estimate,
  receipt,
  variant
}: IEstimateListProps) => {
  const project =
    issue && issue.projects?.find((p) => p?.services?.find((s) => s?.id === service.id));
  const feePromo =
    project && project.fees?.flatMap((f) => f!.promotionReference).filter((x) => !!x);
  const estimatePromo = estimate && estimate.promotionReference;
  const subTotal = receipt?.subtotal !== undefined ? receipt.subtotal : estimate?.subtotal || 0;
  const laborSubtotal =
    receipt?.laborSubtotal !== undefined ? receipt.laborSubtotal : estimate?.laborSubtotal || 0;
  const partsSubtotal =
    receipt?.partsSubtotal !== undefined ? receipt.partsSubtotal : estimate?.partsSubtotal || 0;
  const assessmentFee =
    receipt?.assessmentFee !== undefined
      ? receipt.assessmentFee
      : project?.fees?.find((f) => f?.type === 'ASSESSMENT')?.fee || 0;
  const discount = receipt?.discount !== undefined ? receipt.discount : estimate?.discount || 0;
  const salesTax = receipt?.salesTax !== undefined ? receipt.salesTax : estimate?.salesTax || 0;
  const customerCost =
    receipt?.customerCost !== undefined ? receipt?.customerCost : estimate?.customerCost || 0;
  const partsArray = estimate?.parts && estimate?.parts.split(',');
  const guarantee = service.guarantee;
  const serviceGuaranteeJSON: { title: string; content: string }[] =
    guarantee && guarantee.description ? JSON.parse(guarantee.description) : [];
  const serviceGuarantee = serviceGuaranteeJSON.map((item) => item.content).join('');
  const servicePaid =
    service.status === ServiceStatus.EstimateDeclinedPaid ||
    service.status === ServiceStatus.CanceledPaid ||
    service.status === ServiceStatus.WorkApprovedPaid;

  switch (service.status) {
    case ServiceStatus.EstimateDeclinedPendingPayment:
    case ServiceStatus.EstimateDeclinedPaid:
    case ServiceStatus.CanceledPendingPayment:
    case ServiceStatus.CanceledPaid:
      return (
        <DeclinedCancelledEstimateList
          assessmentFee={assessmentFee}
          customerCost={customerCost}
          salesTax={salesTax}
          subTotal={subTotal}
          issuePromo={feePromo}
        />
      );

    default:
      break;
  }

  return (
    <>
      <Grid container item direction="row" justify="space-between">
        <P mui muiType="sub-header" styleOverride={style.rowHeader}>
          Details
        </P>
      </Grid>
      <Grid container item direction="row" justify="space-between" style={style.row}>
        <P mui muiType="sub-header-bold">
          Labor
        </P>
        <P mui muiType="sub-header-bold" id="estimate-details-labor-0">
          ${formatMUICurrency(laborSubtotal)}
        </P>
      </Grid>
      <Grid container item direction="row" justify="space-between" style={style.rowNoBorder}>
        <P mui muiType="sub-header-bold">
          Parts
        </P>
        <P mui muiType="sub-header-bold" id="estimate-details-parts-0">
          ${formatMUICurrency(partsSubtotal)}
        </P>
      </Grid>
      {partsArray && partsArray.length > 0 && (
        <Grid
          container
          item
          direction="row"
          justify="flex-start"
          style={{ padding: '0px 0px 15px 15px' }}
        >
          <P id="estimate-details-part-description-0">{partsArray.join(', ')}</P>
        </Grid>
      )}
      {variant !== 'estimatesStepper' && (
        <Grid
          container
          item
          direction="row"
          alignItems="stretch"
          justify="space-between"
          style={style.row}
        >
          <Grid item xs={7} sm={7} md={7}>
            <P mui muiType="sub-header-bold">
              In-Person Assessment
            </P>
          </Grid>
          <Grid item xs sm md container direction="row" justify="flex-end" alignContent="center">
            <P mui muiType="body-italic">
              waived
            </P>
            <P
              mui
              muiType="sub-header-bold"
              id="estimate-details-assessment-fee-0"
              styleOverride={{ textDecoration: 'line-through', marginLeft: 5 }}
            >
              ${formatMUICurrency(assessmentFee)}
            </P>
          </Grid>
        </Grid>
      )}
      {((estimatePromo && estimatePromo.length > 0) || (!!discount && discount > 0)) && (
        <Box style={{ margin: '25px 0 0', width: '100%' }}>
          {estimatePromo && (
            <>
              <Grid container item direction="row" justify="space-between">
                <P mui muiType="sub-header" styleOverride={style.rowHeader}>
                  Discounts
                </P>
              </Grid>
              {estimatePromo.map((promo, index) => (
                <Grid
                  container
                  item
                  direction="row"
                  justify="space-between"
                  style={style.row}
                  key={`estimatePromo-${index}`}
                >
                  <Grid container item direction="row" justify="space-between">
                    <P mui muiType="sub-header-bold">
                      {promo?.promotion.title}
                    </P>
                    <P mui muiType="sub-header-bold" id="estimate-details-discount-0">
                      –${formatMUICurrency(promo?.totalDollarAmount)}
                    </P>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
          {!!discount && discount > 0 && (
            <Grid container item direction="row" justify="space-between" style={style.row}>
              <P mui muiType="sub-header-bold">
                Promotion
              </P>
              <P mui muiType="sub-header-bold" id="estimate-details-discount-0">
                –${formatMUICurrency(discount)}
              </P>
            </Grid>
          )}
        </Box>
      )}
      <Grid container item direction="row" justify="space-between" style={style.subTitleRow}>
        <P mui muiType="sub-header-bold">
          Subtotal
        </P>
        <P
          mui
          muiType="sub-header-bold"
          id="estimate-details-subtotal"
          styleOverride={{ lineHeight: '24px' }}
        >
          ${formatMUICurrency(subTotal)}
        </P>
      </Grid>
      <Grid container item direction="row" justify="space-between" style={style.taxRow}>
        <P mui muiType="sub-header-bold">
          Tax
        </P>
        <P mui muiType="sub-header-bold" id="estimate-details-tax">
          ${formatMUICurrency(salesTax)}
        </P>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        style={{ ...style.rowNoBorder, paddingTop: 14 }}
      >
        <H5>Total {servicePaid ? 'Paid' : 'Cost'}</H5>
        <H5 id="estimate-details-total-0">${formatMUICurrency(customerCost)}</H5>
      </Grid>
      <Grid container item direction="column" style={{ paddingTop: 30 }}>
        <P mui muiType="body-bold" id="estimate-details-guarantee-title-0">
          Included: 3-Year Workmanship Warranty
        </P>
        {serviceGuarantee ? (
          <Markdown
            id="guarantee"
            style={{
              marginTop: '1em',
              maxWidth: 500,
              fontFamily: 'Source Sans Pro',
              fontWeight: fontWeights.normal,
              fontSize: 9,
              color: palette.marine,
              lineHeight: '15px'
            }}
          >
            {serviceGuarantee}
          </Markdown>
        ) : (
          <P mui muiType="small-paragraph" id="guarantee" styleOverride={{ maxWidth: 500 }}>
            Castle provides a warranty of workmanship for a period of 3 years from the date of
            completed service. This warranty ensures that the work is completed in a good,
            workmanlike manner. This warranty is non-transferable and excludes issues or claims
            arising out of (a) normal wear and tear; (b) unreasonable use; and (c) improper
            maintenance.{' '}
            <a
              href={`${BASE_URL}/warranty`}
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => {
                pushGAEvent({ id: '3-year-estimate-warranty' });
              }}
            >
              See full terms.
            </a>
          </P>
        )}
      </Grid>
    </>
  );
};

interface DeclinedCancelledEstimateList {
  assessmentFee: number;
  issuePromo?: (PromotionReference | null | undefined)[] | null;
  subTotal: number;
  salesTax: number;
  customerCost: number;
}

const DeclinedCancelledEstimateList: FC<DeclinedCancelledEstimateList> = ({
  assessmentFee,
  customerCost,
  salesTax,
  subTotal,
  issuePromo
}) => (
  <>
    <Grid container item direction="row" justify="space-between">
      <P mui muiType="sub-header" styleOverride={style.rowHeader}>
        Details
      </P>
    </Grid>
    <Grid container item direction="row" justify="space-between" style={style.row}>
      <H4 styleOverride={{ color: palette.marine }}>In-Person Assessment</H4>
      <Box display="flex" alignItems="flex-end">
        {assessmentFee === 0 ? (
          <Text customClass="body-i" element="p" style={{ color: palette.marine }}>
            waived
          </Text>
        ) : (
          <P
            mui
            muiType="sub-header-bold"
            id="estimate-details-assessment-fee-0"
            styleOverride={{
              marginLeft: 5
            }}
          >
            ${formatMUICurrency(assessmentFee)}
          </P>
        )}
      </Box>
    </Grid>
    {issuePromo && issuePromo.length > 0 && (
      <>
        <Grid container item direction="row" justify="space-between" style={{ margin: '25px 0 0' }}>
          <P mui muiType="sub-header" styleOverride={style.rowHeader}>
            Discounts
          </P>
        </Grid>
        {issuePromo.map((promo, index) => (
          <Grid
            container
            item
            direction="row"
            justify="space-between"
            style={style.row}
            key={`issuePromo-${index}`}
          >
            <Grid container item direction="row" justify="space-between">
              <P mui muiType="sub-header-bold">
                {promo?.promotion.title}
              </P>
              <P mui muiType="sub-header-bold" id="estimate-details-discount-0">
                –${formatMUICurrency(promo?.totalDollarAmount)}
              </P>
            </Grid>
          </Grid>
        ))}
      </>
    )}
    <Grid container item direction="row" justify="space-between" style={style.subTitleRow}>
      <P mui muiType="sub-header-bold">
        Subtotal
      </P>
      <P
        mui
        muiType="sub-header-bold"
        id="estimate-details-subtotal"
        styleOverride={{ lineHeight: '24px' }}
      >
        ${formatMUICurrency(subTotal)}
      </P>
    </Grid>
    {salesTax !== 0 && (
      <Grid container item direction="row" justify="space-between" style={style.taxRow}>
        <H4>Tax</H4>
        <H4 id="estimateTax">${formatMUICurrency(salesTax)}</H4>
      </Grid>
    )}
    <Grid
      container
      item
      direction="row"
      justify="space-between"
      style={{ ...style.rowNoBorder, paddingTop: '12px' }}
    >
      <Text customClass="headline-5" element="h4" style={{ color: palette.marine }}>
        Total
      </Text>
      <Text customClass="headline-5" element="p" style={{ color: palette.marine }}>
        ${formatMUICurrency(customerCost)}
      </Text>
    </Grid>
  </>
);

const style = {
  row: { borderBottom: `1px solid ${palette.tide}`, padding: '8px 0px' },
  rowNoBorder: { borderBottom: 'none', padding: '8px 0px' },
  subTitleRow: {
    marginTop: 25
  },
  taxRow: {
    borderBottom: `1px solid ${palette.tide}`,
    padding: '0 0 8px 0'
  },
  rowHeader: {
    borderBottom: `1px solid ${palette.tide}`,
    padding: '8px 0px',
    fontFamily: 'Manrope',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1,
    textTransform: 'uppercase' as 'uppercase',
    width: '100%'
  }
};
