import {
  Market,
  IssueStatus,
  SystemType,
  ServiceStatus,
  ServiceStatusReason,
  ServiceType,
  ServiceEstimateDeclinedReason,
  ServiceWindowType,
  ServiceCategory
} from 'generated/graphql';

export const CASTLE_NUMBER = '+18554270033';
export const MAX_WIDTH = 768;
export const MAX_WIDTH_PX = '768px';
export const COGNITO_NOT_AUTHENTICATED_ERROR = [
  'not authenticated',
  'The user is not authenticated'
];

export const MARKETS = {
  [Market.Cleveland]: 'Cleveland',
  [Market.Austin]: 'Austin',
  [Market.Tampa]: 'Tampa'
};

// ISSUES
export const ISSUE_STATUSES = {
  [IssueStatus.Open]: 'Open',
  [IssueStatus.Closed]: 'Closed'
};

export const SYSTEM_TYPE = {
  [SystemType.Alacnet]: 'Alacnet',
  [SystemType.XactAnalysis]: 'XactAnalysis'
};

// SERVICES
export const SERVICE_STATUSES = {
  [ServiceStatus.SchedulingInProgress]: 'Scheduling in Progress',
  [ServiceStatus.Scheduled]: 'Service Scheduled',
  [ServiceStatus.EstimateReadyForReview]: 'Estimate Ready for Review',
  [ServiceStatus.EstimateAccepted]: 'Estimate Accepted',
  [ServiceStatus.EstimateDeclinedPendingPayment]: 'Estimate Declined, Pending Payment',
  [ServiceStatus.EstimateDeclinedPaid]: 'Estimate Declined, Paid',
  [ServiceStatus.ServiceCompletedPendingPayment]: 'Service Completed, Pending Payment',
  [ServiceStatus.WorkApprovedPaid]: 'Work Approved, Paid',
  [ServiceStatus.CanceledPendingPayment]: 'Canceled, Pending Payment',
  [ServiceStatus.CanceledPaid]: 'Canceled, Paid'
};

export const SERVICE_STATUS_REASONS = {
  [ServiceStatusReason.NoLongerNeeded]: 'No longer needed',
  [ServiceStatusReason.CustomerRequest]: 'Customer request',
  [ServiceStatusReason.SchedulingIssues]: 'Scheduling issues',
  [ServiceStatusReason.CustomerNoShow]: 'Customer no show',
  [ServiceStatusReason.AssessmentFeeTooExpensive]: 'Assessment fee too expensive',
  [ServiceStatusReason.CastleServiceDissatisfaction]: 'Castle service dissatisfaction',
  [ServiceStatusReason.ServiceProviderSelection]: 'Service provider selection',
  [ServiceStatusReason.ServiceProviderNoShow]: 'Service provider no show',
  [ServiceStatusReason.ActsOfGod]: 'Acts of God',
  [ServiceStatusReason.CastleExpertMistake]: 'Castle Expert mistake',
  [ServiceStatusReason.Other]: 'Other'
};

export const SERVICE_ESTIMATE_DECLINED_REASONS: Record<ServiceEstimateDeclinedReason, string> = {
  [ServiceEstimateDeclinedReason.TooExpensive]: 'Too expensive',
  [ServiceEstimateDeclinedReason.UnsatisfiedWithPaymentOptions]: 'Unsatisfied with payment options',
  [ServiceEstimateDeclinedReason.UnsatisfiedWithServiceTechnician]:
    'Unsatisfied with service technician',
  [ServiceEstimateDeclinedReason.UnsatisfiedWithCastleService]: 'Unsatisfied with Castle service',
  [ServiceEstimateDeclinedReason.DecidedNotToFix]: 'Decided not to fix',
  [ServiceEstimateDeclinedReason.SchedulingIssues]: 'Scheduling issues',
  [ServiceEstimateDeclinedReason.NoLongerNeeded]: 'No longer needed',
  [ServiceEstimateDeclinedReason.Other]: 'Other',
  [ServiceEstimateDeclinedReason.Inactive_14Days]: ''
};

export const SERVICE_CATEGORIES: Record<ServiceCategory, string> = {
  [ServiceCategory.DoorsWindows]: 'Doors/Windows',
  [ServiceCategory.DrywallPaint]: 'Drywall/Paint',
  [ServiceCategory.Electrical]: 'Electrical',
  [ServiceCategory.Exterior]: 'Exterior',
  [ServiceCategory.Flooring]: 'Flooring',
  [ServiceCategory.General]: 'General',
  [ServiceCategory.Hvac]: 'HVAC',
  [ServiceCategory.Millwork]: 'Millwork',
  [ServiceCategory.Plumbing]: 'Plumbing',
  [ServiceCategory.RoofingGutter]: 'Roofing/Gutter'
};

export const SERVICE_TYPES: Record<ServiceType, string> = {
  [ServiceType.NewServiceOffering]: 'New service offering',
  [ServiceType.SmallWork]: 'Small work',
  [ServiceType.CabinetInstallation]: 'Cabinet installation',
  [ServiceType.CabinetRepair]: 'Cabinet repair',
  [ServiceType.Carpet]: 'Carpet',
  [ServiceType.CaulkingGrout]: 'Caulking/grout',
  [ServiceType.Ceiling]: 'Ceiling',
  [ServiceType.CeilingFan]: 'Ceiling fan',
  [ServiceType.Chimney]: 'Chimney',
  [ServiceType.CleanAirVentsDuctwork]: 'Clean air vents/ductwork',
  [ServiceType.Drain]: 'Drain',
  [ServiceType.Drywall]: 'Drywall',
  [ServiceType.ElectricalBreaker]: 'Electrical breaker',
  [ServiceType.ExteriorDoor]: 'Exterior door',
  [ServiceType.ExteriorPaint]: 'Exterior paint',
  [ServiceType.FoundationCrawlSpace]: 'Foundation/crawl space',
  [ServiceType.GarageDoor]: 'Garage door',
  [ServiceType.GeneralContractor]: 'General contractor',
  [ServiceType.Gutter]: 'Gutter',
  [ServiceType.Hardscape]: 'Hardscape',
  [ServiceType.HvacInstall]: 'HVAC install',
  [ServiceType.HvacRepair]: 'HVAC repair',
  [ServiceType.Insulation]: 'Insulation',
  [ServiceType.InteriorDoor]: 'Interior door',
  [ServiceType.InteriorPaint]: 'Interior paint',
  [ServiceType.LeakDetection]: 'Leak detection',
  [ServiceType.Lighting]: 'Lighting',
  [ServiceType.OutletSwitchWork]: 'Outlet/switch work',
  [ServiceType.Remodel]: 'Remodel',
  [ServiceType.RoofRepair]: 'Roof repair',
  [ServiceType.RoofReplace]: 'Roof replace',
  [ServiceType.SidewalkAsphalt]: 'Sidewalk/asphalt',
  [ServiceType.Siding]: 'Siding',
  [ServiceType.SinkFaucet]: 'Sink/faucet',
  [ServiceType.SoffitFascia]: 'Soffit/fascia',
  [ServiceType.Solar]: 'Solar',
  [ServiceType.Specialty]: 'Specialty',
  [ServiceType.SupplyLine]: 'Supply line',
  [ServiceType.Texturing]: 'Texturing',
  [ServiceType.Tile]: 'Tile',
  [ServiceType.TileGroutRepair]: 'Tile/grout repair',
  [ServiceType.Toilet]: 'Toilet',
  [ServiceType.Tree]: 'Tree',
  [ServiceType.TrimMolding]: 'Trim/molding',
  [ServiceType.Venting]: 'Venting',
  [ServiceType.WaterHeater]: 'Water heater',
  [ServiceType.WindowsSlidingGlassDoor]: 'Window/sliding glass door',
  [ServiceType.WoodFlooring]: 'Wood flooring'
};

export const CUSTOMER_AVAILABILITIES: Record<ServiceWindowType, string> = {
  [ServiceWindowType.Preferred]: 'Preferred',
  [ServiceWindowType.Alternate_1]: 'Alternate 1',
  [ServiceWindowType.Alternate_2]: 'Alternate 2',
  [ServiceWindowType.Assessment]: 'Assessment',
  [ServiceWindowType.Service]: 'Service'
};

export enum ID {
  CASTLE_FOOTER = 'castle-footer',
  CASTLE_HEADER = 'castle-header',
  ACTION_FOOTER = 'action-footer'
}

export enum HeaderAction {
  MENU = 'menu',
  BACK = 'back',
  NONE = 'none'
}

export const declineEstimateOptions = [
  'Too expensive',
  'Unsatisfied with payment options',
  'Unsatisfied with service technician',
  'Unsatisfied with Castle service',
  'Decided not to fix',
  'Scheduling issues',
  'No longer needed',
  'Other'
];

export enum CastleStorage {
  COVID19 = 'CASTLE-COVID19'
}

export enum BannerState {
  DISMISSED = 'banner dismissed'
}

export const ServiceHistoryStatus: Record<string, string> = {
  [ServiceStatus.WorkApprovedPaid]: 'Completed',
  [ServiceStatus.EstimateDeclinedPaid]: 'Declined',
  [ServiceStatus.CanceledPaid]: 'Canceled'
};

export const PAYPAL_MERCHANT_TERMS =
  'By selecting ‘Agree and Continue’ below, I agree to Castle’s Payment Terms and Conditions (https://www.callcastle.com/support/payment-terms).';
