import React, { FC } from 'react';
import { OutlinedTextFieldProps, TextField, withStyles } from '@material-ui/core';
import { palette, fontWeights } from 'styles/global';

interface InputTextProps extends Omit<OutlinedTextFieldProps, 'variant'> {}

export const InputText: FC<InputTextProps> = props => {
  return <CustomTextField {...props} variant="outlined" />;
};

const CustomTextField = withStyles({
  root: {
    '& label.MuiInputLabel-root': {
      fontFamily: 'Source Sans Pro',
      color: palette.concrete,
      fontWeight: fontWeights.normal,
      '&.Mui-focused, &.MuiFormLabel-filled': {
        color: palette.marine,
        fontWeight: fontWeights.bold
      },
      '&.MuiFormLabel-filled:not(.Mui-focused):not(.Mui-error)': {
        '& ~ .MuiOutlinedInput-root:not(:hover) fieldset': {
          borderColor: palette.marine
        }
      }
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        color: palette.marine
      },
      '& fieldset': {
        color: palette.marine,
        fontFamily: 'Source Sans Pro',
        fontWeight: fontWeights.bold
      },
      '&:hover, &.Mui-focused': {
        '& fieldset': {
          borderColor: palette.tarnishedGold,
          fontWeight: fontWeights.bold,
          borderWidth: '1px'
        }
      },
      '&.Mui-error': {
        borderColor: palette.electricalWire
      }
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: palette.electricalWire
    }
  }
})(TextField);
