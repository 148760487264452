import React, { ReactNode } from 'react';
import { palette, fontWeights } from 'styles/global';

interface IH1Props {
  children?: ReactNode;
  styleOverride?: React.CSSProperties;
  id?: string;
  mui?: boolean;
  muiType?: 'large' | 'small';
}

export const H1 = ({ children, styleOverride, id, mui = false, muiType = 'large' }: IH1Props) => {
  const style = mui ? styles.mui[muiType] : styles.grommet;

  return (
    <h1 id={id} style={{ ...style, ...styleOverride }}>
      {children}
    </h1>
  );
};

const styles: { grommet: React.CSSProperties; mui: { [key: string]: React.CSSProperties } } = {
  grommet: {
    fontFamily: 'Assistant',
    fontWeight: 600,
    fontSize: 34,
    color: palette.firefly,
    lineHeight: '36px',
    letterSpacing: '-0.25px',
    margin: 0
  },
  mui: {
    large: {
      fontFamily: 'Manrope',
      fontWeight: fontWeights.medium,
      fontSize: 48,
      color: palette.marine,
      lineHeight: '56px',
      letterSpacing: '-1px'
    },
    small: {
      fontFamily: 'Manrope',
      fontWeight: fontWeights.medium,
      fontSize: 30,
      color: palette.marine,
      lineHeight: '36px',
      letterSpacing: '-1px',
      margin: 0
    }
  }
};
