import React, { FC, useEffect } from 'react';

import { Service, ServiceEstimate } from 'generated/graphql';
import { pushGAEvent } from 'utils';
import { DiscountBar, EstimateCardContainer, EstimateList } from 'components';

interface IEstimateItems {
  service: Service;
  onEstimateSelect: (estimateId: string) => void;
  selectedEstimate: string;
}

export const EstimateItems: FC<IEstimateItems> = ({
  service,
  onEstimateSelect,
  selectedEstimate
}) => {
  useEffect(() => {
    if (service.estimates.length === 1 && selectedEstimate !== service.estimates[0].id) {
      onEstimateSelect(service.estimates[0].id);
    }
  }, [service, onEstimateSelect, selectedEstimate]);
  return (
    <>
      {service.estimates.map((estimate: ServiceEstimate, index) => (
        <div key={index}>
          <div>
            {service.promotionReference && service.promotionReference?.length > 0 && (
              <DiscountBar
                title={
                  service?.promotionReference?.find(
                    (p) => p?.serviceId === service.id && !p?.estimateId
                  )?.promotion?.title
                }
                terms={
                  service?.promotionReference?.find(
                    (p) => p?.serviceId === service.id && !p?.estimateId
                  )?.promotion?.shortTerms
                }
              />
            )}
          </div>

          <EstimateCardContainer
            description={estimate.description}
            title={estimate.title}
            grossCost={estimate.grossCost}
            onClick={() => {
              onEstimateSelect(estimate.id);
            }}
            expandedButtonClick={(expanded) => {
              pushGAEvent({
                id: expanded ? 'estimate-see-details-close-lbl' : 'estimate-see-details-expand-lbl'
              });
            }}
            value={selectedEstimate === estimate.id}
            key={estimate.id}
            subtotal={estimate.subtotal}
            showActionButton={service.estimates.length > 1}
            promoOn={service.promotionReference && service.promotionReference?.length > 0}
          >
            <EstimateList service={service} estimate={estimate} variant="estimatesStepper" />
          </EstimateCardContainer>
        </div>
      ))}
    </>
  );
};
