import React, { FC, RefObject, useCallback, useContext } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { CastleCheckBox, P, A, BraintreeHostedFields, Text, BraintreeClient } from 'components';
import { BASE_URL } from 'data/env-vars';
import { palette } from 'styles/global';
import { pushGAEvent } from 'utils';
import { useQuery, useMutation } from '@apollo/client';
import { SnackbarContext } from 'context';
import { SNACKBAR_OPTIONS, SNACKBAR_OPTIONS_FUNCTION } from 'data/snackbar-options';
import { Query, Mutation, MutationSavePaymentMethodArgs } from 'generated/graphql';
import { SAVE_PAYMENT_METHOD } from 'graphql/mutations';
import { GET_PAYMENT_TOKEN_FOR_PAYMENT_METHOD } from 'graphql/queries';

interface IConfirmPayment {
  hasPaymentMethod: boolean;
  showCheckbox: boolean;
  onConfirmClicked: () => void;
  submitButton: RefObject<HTMLButtonElement>;
  onCheckboxClick: () => void;
}

export const ConfirmPayment: FC<IConfirmPayment> = ({
  hasPaymentMethod,
  onConfirmClicked,
  showCheckbox,
  submitButton,
  onCheckboxClick,
  children
}) => {
  const classes = useStyles();

  return (
    <>
      {!hasPaymentMethod && (
        <AddPaymentMethod
          onSavePaymentMethodComplete={() => onConfirmClicked()}
          submitButton={submitButton}
        />
      )}
      {showCheckbox && (
        <Grid item container spacing={1} wrap="nowrap" className={classes.terms}>
          <Grid item>
            <CastleCheckBox
              id="accept-payment-terms-btn"
              onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                pushGAEvent({ id: 'accept-payment-terms-btn' });
                onCheckboxClick();
              }}
            />
          </Grid>
          <Grid item>
            <P mui muiType="body">
              By checking this box you agree that you will pay for any labor and parts or materials
              provided under this estimate when due and that you agree to the{' '}
              <A
                id="payment-terms-lbl"
                mui
                muiType="semi-bold"
                href={`${BASE_URL}/payment-terms`}
                target="_blank"
                styleOverride={{ color: palette.marine }}
                onClick={() => pushGAEvent({ id: 'payment-terms-lbl' })}
              >
                Castle Payment Terms and Conditions.
              </A>
            </P>
          </Grid>
        </Grid>
      )}
      {children}
    </>
  );
};
interface IAddPaymentMethod {
  onSavePaymentMethodComplete: () => void;
  submitButton: RefObject<HTMLButtonElement>;
}

export const AddPaymentMethod: FC<IAddPaymentMethod> = ({
  onSavePaymentMethodComplete,
  submitButton
}) => {
  const { updateSnackbar, closeSnackbar } = useContext(SnackbarContext);

  const { data } = useQuery<Query>(GET_PAYMENT_TOKEN_FOR_PAYMENT_METHOD);
  const [savePaymentMethod] = useMutation<Mutation, MutationSavePaymentMethodArgs>(
    SAVE_PAYMENT_METHOD,
    {
      onCompleted: async () => {
        updateSnackbar(SNACKBAR_OPTIONS.paymentMethodAdded);
        onSavePaymentMethodComplete();
      },
      onError: (e) => {
        updateSnackbar(SNACKBAR_OPTIONS_FUNCTION.savePaymentMethodFail(e.graphQLErrors[0].message));
      }
    }
  );

  const savePayment = useCallback(
    async (nonce: string) => {
      await savePaymentMethod({ variables: { nonce, termsAccepted: true } });
    },
    [savePaymentMethod]
  );

  return (
    <Grid style={{ padding: '32px 0 0' }}>
      <Text customClass="overline-b" style={{ color: palette.marine, paddingBottom: 5 }}>
        Add Payment Method <span style={{ whiteSpace: 'nowrap' }}>to Secure Service</span>
      </Text>
      <Text customClass="body" style={{ color: palette.marine }}>
        A valid payment method is required to accept estimates and begin service. Your payment is
        securely stored, and when your service is complete you pay through your Castle account. This
        allows us to proceed with your service.
      </Text>
      <Grid>
        {!!data && (
          <BraintreeClient token={data.getPaymentTokenForPaymentMethod}>
            <BraintreeHostedFields
              redeemNonce={async (nonce) => {
                pushGAEvent({ id: 'add-payment-save-success', type: 'formSubmit' });
                savePayment(nonce);
              }}
              onError={(_message) => {
                updateSnackbar(
                  SNACKBAR_OPTIONS_FUNCTION.braintreeError(
                    'Unable to add payment method. Please try again or call for assistance.'
                  )
                );
              }}
              submitButton={submitButton}
              onFocus={() => closeSnackbar(SNACKBAR_OPTIONS_FUNCTION.braintreeError('').key)}
            />
          </BraintreeClient>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  confirmPayment: {
    padding: '15px 18px'
  },
  terms: {
    padding: '40px 0 30px'
  }
}));
