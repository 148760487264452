import React, { FC } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  SvgIconTypeMap
} from '@material-ui/core';
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';

import { H5, MUIButton, P } from 'components';
import { palette } from 'styles/global';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { formatMUICurrency } from 'utils';

interface CardDashboardActionCta {
  id: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
}

interface CardDashboardAction {
  status: string;
  cta?: CardDashboardActionCta;
}

interface CardDashboardButton {
  id: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  subText?: string;
}

interface CardDashboardProps {
  id: string;
  backgroundColor: string;
  textColor: string;
  button?: CardDashboardButton;
  action?: CardDashboardAction;
  provider: string | JSX.Element;
  technician?: string;
  description: string;
  subtotal?: number;
  grossCost?: number;
}

export const CardDashboard: FC<CardDashboardProps> = (props) => {
  const {
    id,
    backgroundColor,
    textColor,
    button,
    action,
    provider,
    technician,
    description,
    subtotal,
    grossCost
  } = props;
  const total = subtotal || '- - -';

  return (
    <Box margin="-8px" paddingY="8px">
      <Card id={id} style={{ backgroundColor, borderRadius: 8, margin: '8px' }} elevation={3}>
        <CardContent style={{ padding: '16px 20px' }}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
              <H5 styleOverride={{ color: textColor }}>{provider}</H5>
              <Box height={4} />
              <P mui muiType="body" styleOverride={{ color: textColor }}>
                {description}
              </P>
            </Box>

            <Box marginLeft="16px" display="flex" flexDirection="column" alignItems="flex-end">
              {!!subtotal && !!grossCost && subtotal !== grossCost ? (
                <>
                  <DollarText textColor={textColor} total={total} />
                  <DollarText
                    textColor={palette.concrete}
                    fontSize={14}
                    lineThrough
                    total={grossCost}
                  />
                </>
              ) : (
                <DollarText textColor={textColor} total={total} />
              )}
            </Box>
          </Box>
          {!!technician && (
            <>
              <Box height={8} />
              <P mui muiType="body" styleOverride={{ color: textColor }}>
                <b>Service Tech:</b> {technician}
              </P>
            </>
          )}
          <Box height={8} />
          {!!button && (
            <>
              <Box height={8} />
              <Box display="flex" flexDirection="column" alignItems="center">
                <MUIButton
                  id={button.id}
                  Icon={button.Icon}
                  label={button.label}
                  onClick={button.onClick}
                  fullWidth
                  buttonStyleOverride={{
                    backgroundColor: textColor,
                    borderColor: textColor,
                    maxWidth: '300px'
                  }}
                  textStyleOverride={{ color: backgroundColor }}
                />
                {!!button.subText && (
                  <>
                    <Box height={4} />
                    <P
                      mui
                      muiType="caption-body"
                      styleOverride={{ color: textColor, maxWidth: '300px' }}
                    >
                      {button.subText}
                    </P>
                  </>
                )}
              </Box>
            </>
          )}
          {props.children}
        </CardContent>
        {!!action && (
          <CardActions style={{ padding: '0px' }}>
            <Grid
              container
              justify="space-between"
              alignItems="baseline"
              style={{ borderTop: `1px solid ${palette.stone}`, padding: '4px 12px 4px 20px' }}
            >
              <P mui muiType="body-bold" styleOverride={{ color: palette.marine }}>
                <i>{action.status}</i>
              </P>
              <Button
                id={action.cta?.id}
                endIcon={<ChevronRightSharpIcon style={{ color: palette.caribbean }} />}
                onClick={action.cta?.onClick}
                style={{ visibility: action.cta ? 'visible' : 'hidden' }}
              >
                <P mui muiType="tertiary-text-button" styleOverride={{ marginRight: '-8px' }}>
                  {action.cta?.label || 'N/A'}
                </P>
              </Button>
            </Grid>
          </CardActions>
        )}
      </Card>
    </Box>
  );
};

export const DollarText: FC<{
  textColor: string;
  fontSize?: number;
  total: '- - -' | number;
  lineThrough?: boolean;
}> = ({ textColor, fontSize = 18, total, lineThrough }) => {
  return (
    <>
      <Box display="flex">
        <P mui muiType="body-bold" styleOverride={{ color: textColor, paddingRight: '1px' }}>
          $
        </P>
        <H5
          styleOverride={{
            color: textColor,
            whiteSpace: 'nowrap',
            fontSize: fontSize,
            textDecoration: lineThrough ? 'line-through' : ''
          }}
        >
          {total === '- - -' ? total : formatMUICurrency(total)}
        </H5>
      </Box>
    </>
  );
};
