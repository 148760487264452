import React, { FC, useEffect } from 'react';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import { navigate } from '@reach/router';

import { CardDashboard, withPaymentToken } from 'components';
import { DashboardService } from 'core/interfaces';
import { BASE_URL } from 'data/env-vars';
import { palette } from 'styles/global';
import { pushGAEvent } from 'utils';

interface CardServiceCanceledProps {
  id: string;
  service: DashboardService;
}

export const CardServiceCanceled: FC<CardServiceCanceledProps> = withPaymentToken(
  ({ id, service, paymentToken, getPaymentToken }) => {
    const { serviceId, provider, description, subtotal, grossCost } = service;

    useEffect(() => {
      if (paymentToken) {
        navigate(`${BASE_URL}/service-details/${serviceId}/pay`, { state: { token: paymentToken } });
      }
    }, [paymentToken, serviceId]);

    return (
      <CardDashboard
        id={id}
        backgroundColor={palette.white}
        textColor={palette.marine}
        provider={<i>{provider}</i>}
        description={description}
        subtotal={subtotal}
        grossCost={grossCost}
        action={{ status: 'Canceled' }}
        button={{
          id: `pay-assessment-${serviceId}-btn`,
          Icon: AttachMoneySharpIcon,
          label: 'Pay Assessment Fee',
          onClick: (e) => {
            pushGAEvent({ id: e.currentTarget.id });
            getPaymentToken();
          }
        }}
      />
    );
  }
);
