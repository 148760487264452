import React, { FC } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary as MUIAccordionSummary,
  AccordionDetails,
  withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { palette } from 'styles/global';
import { P, Accreditation, A } from 'components';
import { pushGAEvent } from 'utils';

const AccordionSummary = withStyles({
  root: {
    margin: '0px 20px',
    padding: '6px 0px',
    borderBottom: `1px solid ${palette.stone}`,
    marginBottom: -1,
    minHeight: 20,
    '&$expanded': {
      margin: '0px 20px',
      minHeight: 20
    }
  },
  content: {
    margin: '0px',
    '&$expanded': {
      margin: '0px'
    }
  },
  expandIcon: {
    padding: '0px 12px',
    '&$expanded': {
      padding: 0
    }
  },
  expanded: {
    margin: 0
  }
})(MUIAccordionSummary);

export const EstimateServiceGuarantee: FC = () => {
  return (
    <>
      <Grid container justify="space-between">
        <Accordion
          square
          elevation={0}
          style={{ width: '100%', backgroundColor: palette.pvcWhite }}
          onChange={(_e, expanded) => {
            pushGAEvent({
              id: expanded
                ? 'castle-service-difference-expand-lbl'
                : 'castle-service-difference-close-lbl'
            });
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ padding: 0, color: palette.caribbean }} />}
            aria-controls="service-guarantee-content"
            id="service-guarantee-header"
          >
            <P mui muiType="sub-header-bold" styleOverride={{ color: palette.caribbean }}>
              The Castle service difference
            </P>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: palette.marine, padding: 24 }}>
            <Grid container direction="column">
              <Grid style={{ paddingBottom: 14 }}>
                <P mui muiType="sub-header-bold" styleOverride={{ color: palette.sawdust }}>
                  All service providers meet the following criteria:
                </P>
              </Grid>
              <Accreditation mui />
              <Grid style={{ paddingBottom: 14, paddingTop: 20 }}>
                <A
                  mui
                  muiType="semi-bold"
                  styleOverride={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(window.location.origin);
                  }}
                >
                  Learn more about how we vet our service providers
                </A>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};
