import React, { FC } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { palette } from 'styles/global';

interface ICastleBackdrop {
  open: boolean;
  zIndex?: number;
}

export const CastleBackdrop: FC<ICastleBackdrop> = ({ open, zIndex = 99 }) => {
  return (
    <Backdrop
      style={{
        zIndex,
        color: palette.white
      }}
      open={open}
    >
      <CircularProgress color="inherit" size={60} thickness={5} />
    </Backdrop>
  );
};
