import React, { FC } from 'react';
import { Grid, Button } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import { navigate } from '@reach/router';

import { P } from 'components';
import { palette } from 'styles/global';
import { pushGAEvent } from 'utils';
import { BASE_URL } from 'data/env-vars';

export const AddPaymentMethodButton: FC = () => (
  <Grid container direction="row" alignItems="stretch" style={{ paddingTop: 25 }}>
    <Button
      startIcon={<AddCircle />}
      style={{ textTransform: 'none' }}
      onClick={() => {
        pushGAEvent({ id: 'add-payment-lbl' });
        navigate(`${BASE_URL}/account/payment-methods/add`);
      }}
    >
      <P mui muiType="body-2-large" styleOverride={{ color: palette.marine, paddingRight: 4 }}>
        Add a Payment Method
      </P>
    </Button>
  </Grid>
);
