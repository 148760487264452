import React, { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Text } from 'components';
import { palette } from 'styles/global';

interface ICastleDialog {
  open: boolean;
  onClose?: () => void;
  ariaLabelledby?: string;
  ariaDescribedby?: string;
  title?: JSX.Element | string;
  titleId?: string;
  contentId?: string;
  actions: JSX.Element;
}

export const CastleDialog: FC<ICastleDialog> = ({
  open,
  onClose,
  actions,
  children,
  ariaDescribedby,
  ariaLabelledby,
  title,
  titleId,
  contentId
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby={ariaLabelledby}
    aria-describedby={ariaDescribedby}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle id={titleId}>
      <Text element="span" customClass="body-lg-b" style={{color:palette.marine}}>
        {title}
      </Text>
    </DialogTitle>
    <DialogContent id={contentId}>{children}</DialogContent>
    <DialogActions style={{ paddingTop: 16, paddingBottom: 16, paddingLeft:24, paddingRight:24 }}>{actions}</DialogActions>
  </Dialog>
);
