import React, { FC } from 'react';
import MaskedInput from 'react-text-mask';
import { PhoneSharp, ErrorSharp } from '@material-ui/icons';
import {
  withStyles,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core';

import { fontWeights, palette } from 'styles/global';
interface IPhoneNumberCustomField {
  shrink: boolean;
  error: boolean;
  onFocus: () => void;
  onBlur: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  showMask: boolean;
}

export const PhoneNumberCustomField: FC<IPhoneNumberCustomField> = ({
  error,
  shrink,
  onFocus,
  onChange,
  onBlur
}) => (
  <CssTextField variant="outlined" fullWidth>
    <InputLabel
      htmlFor="phoneNumber"
      shrink={shrink}
      style={{ marginLeft: 30, padding: '0px 12px' }}
      error={error}
    >
      Phone Number
    </InputLabel>
    <OutlinedInput
      required
      error={error}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      type="tel"
      id="phoneNumber"
      startAdornment={
        <InputAdornment position="end">
          <PhoneSharp />
        </InputAdornment>
      }
      inputComponent={TextMaskCustom as any}
    />
    {error && (
      <FormHelperText>
        <ErrorSharp style={{ fontSize: 10, padding: '3px 5px 0px' }} />
        Phone Number is required
      </FormHelperText>
    )}
  </CssTextField>
);

const TextMaskCustom: FC<TextMaskCustomProps> = ({ inputRef, showMask, ...other }) => (
  <MaskedInput
    {...other}
    ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask={showMask}
  />
);
const CssTextField = withStyles({
  //Default
  root: {
    fontFamily: 'Source Sans Pro',
    fontWeight: fontWeights.regular,
    fontSize: 16,
    lineHeight: 22,
    letterSpacing: 0,

    //Shrink label
    '& .MuiInputLabel-shrink': {
      //Default
      transform: `translate(-15px, -6px) scale(0.75)`,
      backgroundColor: palette.pvcWhite,
      fontWeight: 700,

      //error
      '&.Mui-error p': {
        color: palette.electricalWire
      },
      //focused
      '&.Mui-focused': {
        color: palette.tarnishedGold
      },
      //filled
      '&.MuiFormLabel-filled': {
        color: palette.marine,
        fontWeight: 700
      },
      //filled
      '&.MuiFormLabel-filled.Mui-error': {
        color: palette.electricalWire,
        fontWeight: 700
      }
    },

    //Regular Label
    '& .MuiInputLabel-outlined': {
      //Default
      color: palette.concrete,

      //Error
      '&.Mui-error': {
        color: palette.electricalWire
      },

      //phone on filled
      '&.MuiFormLabel-filled + .MuiInputBase-formControl svg': {
        fill: palette.marine
      },
      //Outline on filled
      '&.MuiFormLabel-filled + .MuiInputBase-formControl fieldset': {
        borderColor: palette.marine
      },
      //phone if filled and error
      '&.MuiFormLabel-filled.Mui-error + .MuiInputBase-formControl svg': {
        fill: palette.electricalWire
      },
      //outline if filled and error
      '&.MuiFormLabel-filled.Mui-error + .MuiInputBase-formControl fieldset': {
        borderColor: palette.electricalWire
      }
    },

    //Error Caption Below Field
    '& .MuiFormHelperText-contained': {
      color: palette.electricalWire,
      margin: 0,
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.normal,
      fontSize: 12,
      lineHeight: '16px',
      paddingTop: 5
    },

    //Main Input Box
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.concrete
      },
      //Focused
      '&.Mui-focused fieldset': {
        borderColor: palette.tarnishedGold,
        borderWidth: 1
      },

      //Typed in number
      '& .MuiOutlinedInput-input': {
        paddingLeft: 16
      },
      //phone icon
      '& .MuiInputAdornment-root': {
        color: palette.concrete
      },
      //phone icon focused
      '&.Mui-focused .MuiInputAdornment-root': {
        color: palette.marine
      },
      '&.Mui-error': {
        borderColor: palette.electricalWire,
        color: palette.electricalWire,
        '& .MuiInputAdornment-root': {
          color: palette.electricalWire
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.electricalWire
        }
      }
    }
  }
})(FormControl);
