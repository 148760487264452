import React, { FC, useContext } from 'react';
import { Button, Card, Grid, IconButton } from '@material-ui/core';
import { ChevronRightSharp, Close, ErrorOutline } from '@material-ui/icons';

import { Banner as BannerType, BannerContext } from 'context';
import { palette } from 'styles/global';
import { A, P } from 'components';
import { navigate } from '@reach/router';
import { BannerState } from 'data/constants';
import { pushGAEvent } from 'utils';
import { BANNER_OPTIONS } from 'data/banner-options';
import { BannerTypes } from 'enums';
import { BASE_URL } from 'data/env-vars';

export const Banner = () => {
  const { banners, closeBanner } = useContext(BannerContext);

  if (banners.length === 0) return null;

  const banner = banners.find((x) => !x.closed);

  if (!banner) return null;

  return (
    <Card
      style={{
        boxShadow: '0px 0.5px 6px -2px rgba(0, 0, 0, 0.31)',
        zIndex: 1,
        position: 'relative',
        borderRadius: 0
      }}
    >
      {banner.title === BANNER_OPTIONS[BannerTypes.COVID].title ? (
        <CovidBanner banner={banner} closeBanner={closeBanner} />
      ) : (
        <RegularBanner banner={banner} closeBanner={closeBanner} />
      )}
    </Card>
  );
};

interface IBanner {
  banner: BannerType;
  closeBanner: () => void;
}

const RegularBanner: FC<IBanner> = ({ banner, closeBanner }) => (
  <Grid
    container
    direction="column"
    alignContent="space-between"
    style={{ ...styles[banner.type] }}
  >
    <Grid
      item
      container
      xs
      direction="row"
      justify="space-between"
      wrap="nowrap"
      style={{ padding: '20px 16px', flex: 1 }}
    >
      <Grid item container wrap="nowrap">
        {banner.type === 'error' && (
          <Grid item>
            <ErrorOutline style={{ color: palette.electricalWire, paddingRight: '11px' }} />
          </Grid>
        )}
        <Grid item>
          <P
            mui
            muiType="body-2-large"
            id="banner-title"
            styleOverride={{ color: palette.marine, paddingRight: 10 }}
          >
            {banner.title}
          </P>
        </Grid>
      </Grid>
      <Grid item style={{ textAlign: 'right' }}>
        <IconButton
          size="small"
          style={{ padding: 0 }}
          aria-label="close banner alert"
          onClick={() => {
            pushGAEvent({ id: 'info-banner-close-btn' });
            if (banner.dismissStorageID) {
              localStorage.setItem(banner.dismissStorageID, BannerState.DISMISSED);
            }
            closeBanner();
          }}
        >
          <Close style={{ fontSize: 20 }} />
        </IconButton>
      </Grid>
    </Grid>
    <Grid
      item
      container
      xs
      justify="flex-end"
      alignContent="flex-end"
      style={{ padding: '0px 20px 20px 0px', flex: 1 }}
    >
      <Button
        style={{ textTransform: 'none', padding: 0 }}
        onClick={() => {
          pushGAEvent({ id: 'info-banner-cta-lbl' });
          banner.newTab
            ? window.open(`${window.location.origin}${BASE_URL}${banner.link}`)
            : navigate(`${BASE_URL}${banner.link}`);
        }}
      >
        <P mui muiType="sub-header-bold" styleOverride={{ color: palette.marine }}>
          {banner.desc}
        </P>
      </Button>
    </Grid>
  </Grid>
);

const CovidBanner: FC<IBanner> = ({ banner, closeBanner }) => (
  <Grid container direction="row" justify="space-evenly" style={{ backgroundColor: palette.sand }}>
    <Grid item container xs={1} alignItems="center">
      <CloseButton banner={banner} closeBanner={closeBanner} hidden />
    </Grid>
    <Grid
      item
      container
      xs={9}
      direction="row"
      alignItems="center"
      justify="center"
      wrap="nowrap"
      style={{ padding: 10 }}
    >
      <Grid item container wrap="nowrap" direction="column" alignItems="center">
        <Grid item>
          <P
            mui
            muiType="body-2-large-bold"
            id="banner-title"
            styleOverride={{ color: palette.marine }}
          >
            {banner.title}
          </P>
        </Grid>
        <Grid container item justify="center">
          <A
            mui
            muiType="semi-bold"
            id="banner-description"
            href={`${BASE_URL}${banner.link}`}
            target="_blank"
            styleOverride={{ color: palette.marine }}
            onClick={() => {
              pushGAEvent({ id: 'info-banner-cta-lb', type: 'link' });
            }}
          >
            {banner.desc}
          </A>
          <ChevronRightSharp />
        </Grid>
      </Grid>
    </Grid>
    <Grid item container xs={1} alignItems="center">
      <CloseButton banner={banner} closeBanner={closeBanner} />
    </Grid>
  </Grid>
);

interface ICloseButton extends IBanner {
  hidden?: boolean;
}

const CloseButton: FC<ICloseButton> = ({ banner, closeBanner, hidden }) => (
  <IconButton
    style={{ visibility: hidden ? 'hidden' : 'visible' }}
    size="small"
    aria-label="close banner alert"
    aria-disabled={hidden}
    onClick={() => {
      pushGAEvent({ id: 'info-banner-close-btn' });
      if (banner.dismissStorageID) {
        localStorage.setItem(banner.dismissStorageID, BannerState.DISMISSED);
      }
      closeBanner();
    }}
  >
    <Close style={{ fontSize: 20 }} />
  </IconButton>
);

const styles: Record<BannerType['type'], React.CSSProperties> = {
  error: {
    backgroundColor: palette.dustMUI
  },
  info: {
    backgroundColor: palette.white
  },
  success: {
    backgroundColor: palette.polymer
  }
};
