import React, { createContext, useState, FunctionComponent } from 'react';

interface ModalContextProps {
  modalGlobal: FunctionComponent | undefined;
  showModalGlobal: boolean;
  setModalGlobal: (value: any) => void;
  setShowModalGlobal: (value: boolean) => void;
}

export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

export const ModalProvider = ({ children }: any) => {
  const [modalGlobal, setModalGlobal] = useState();
  const [showModalGlobal, setShowModalGlobal] = useState(false);

  return (
    <ModalContext.Provider
      value={{ modalGlobal, showModalGlobal, setModalGlobal, setShowModalGlobal }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const ModalConsumer = ModalContext.Consumer;
