import React, { FC, useContext, useEffect } from 'react';

import { BannerContext, UserContext } from 'context';
import { PRIMARY_CARD_WILL_EXPIRE, BANNER_OPTIONS } from 'data/banner-options';
import { BannerTypes } from 'enums';
import { CreditCard } from 'generated/graphql';

export const CovidBannerSetter: FC = () => {
  const { banners, pushBanner } = useContext(BannerContext);

  useEffect(() => {
    if (!banners.some((x) => BANNER_OPTIONS[BannerTypes.COVID].title === x.title)) {
      pushBanner(BANNER_OPTIONS[BannerTypes.COVID]);
    }
  }, [pushBanner, banners]);

  return <></>;
};

export const PaymentsBannerSetter: FC = () => {
  const { banners, pushBanner } = useContext(BannerContext);
  const { user } = useContext(UserContext);

  const primaryCardWillExpire = user.paymentMethods.creditCards?.find((x) => {
    const today = new Date();
    const monthValue = today.getMonth();
    // + 2 because getMonth() returns months from 0, and we want to check for the next month
    const month = `${monthValue + 2}`.length === 1 ? `0${monthValue + 2}` : `${monthValue + 2}`;
    const year = `${today.getFullYear()}`;

    return (
      x?.default &&
      x.hasOwnProperty('expired') &&
      !(x as CreditCard).expired &&
      (x as CreditCard).expirationMonth === month &&
      (x as CreditCard).expirationYear === year
    );
  });

  const expiredDefaultPaymentMethod = user.paymentMethods.creditCards?.some(
    (x) => !!x && x.default && x.hasOwnProperty('expired') && (x as CreditCard).expired
  );

  useEffect(() => {
    if (!!primaryCardWillExpire) {
      const pm = PRIMARY_CARD_WILL_EXPIRE('1234');
      if (!!primaryCardWillExpire && !banners.some((x) => pm.title === x.title)) {
        pushBanner(pm);
      }
    }
  }, [primaryCardWillExpire, banners, pushBanner]);

  useEffect(() => {
    if (expiredDefaultPaymentMethod) {
      const b = BANNER_OPTIONS.primary_card_expired;
      if (!banners.some((x) => b.title === x.title)) {
        pushBanner(b);
      }
    }
  }, [expiredDefaultPaymentMethod, banners, pushBanner]);

  return <></>;
};
