import moment from 'moment-timezone';

/**
 * Formats an ISO date and converts it to user's local timezone.
 * @param {string} date ISO date
 * @param {string} format Moment.js format string
 * @returns {string} formatted date in user's local timezone
 * @example
 * getDate('2020-01-28T20:34:12.022Z', 'MMM. Do, YYYY')
 * return 'Jan. 28th, 2020'
 * @example
 * getDate('2020-01-28T20:34:12.022Z', 'h:mma')
 * return '3:34pm'
 * @link https://momentjs.com/docs/#/displaying/format/
 */
export const formatDate = (date: string, format: string): string => {
  return moment(new Date(date))
    .local()
    .format(format);
};

/**
 * Formats a time window using two ISO dates and converts it to user's local timezone.
 * @param {string} startDate ISO date
 * @param {string} endDate ISO date
 * @returns {string} formatted time window in user's local timezone
 * @example
 * getTime('2020-01-28T16:30:18.225Z', '2020-01-28T19:00:00.000Z')
 * return '1:30pm – 4:00pm' // GMT-0500 Eastern Standard Time
 * return '10:30am – 1:00pm' // GMT-0800 Pacific Standard Time
 */
export const formatTimeWindow = (startDate: string, endDate: string): string => {
  const format = 'h:mma';
  const startTime = formatDate(startDate, format);
  const endTime = formatDate(endDate, format);
  return `${startTime} – ${endTime}`;
};

export const formatDateWithTimezone = (date: string, format: string, timezone: string): string => {
  return moment.tz(date, timezone).format(format);
};

export const formatTimeWindowWithTimezone = (
  startDate: string,
  endDate: string,
  timezone: string
): string => {
  const format = 'h:mma';
  const startTime = formatDateWithTimezone(startDate, format, timezone);
  const endTime = formatDateWithTimezone(endDate, format, timezone);
  return `${startTime} – ${endTime}`;
};
