import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  Children,
  cloneElement,
  ReactElement
} from 'react';
import { client as BTClient, Client } from 'braintree-web';

interface IBraintreeClient {
  token: string;
  onSetClient?: (client: Client) => void;
}

export const BraintreeClient: FC<IBraintreeClient> = ({ token, children, onSetClient }) => {
  const [client, setClient] = useState<Client>();

  const createClient = useCallback(async () => {
    if (!client) {
      const c = await BTClient.create({
        authorization: token
      });
      setClient(c);
      !!onSetClient && onSetClient(c);
    }
  }, [client, token, onSetClient]);

  useEffect(() => {
    if (!client) {
      createClient();
    }
  }, [createClient, client]);

  return (
    <>
      {Children.map(children, (child) =>
        !!child ? cloneElement(child as ReactElement, { client }) : <></>
      )}
    </>
  );
};
