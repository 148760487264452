import React, { FC } from 'react';
import { Grid, Button } from '@material-ui/core';
import { CreditCard, PayPalAccount } from 'generated/graphql';
import { P } from 'components';
import { palette } from 'styles/global';
import { Text } from 'components/Shared';

interface ICreditCardContainer {
  paymentMethod: CreditCard;
  showDeleteButton?: boolean;
  showOnMakePrimary?: boolean;
  onDelete: (token: string) => void;
  onMakePrimary: (token: string) => void;
}

export const CreditCardContainer: FC<ICreditCardContainer> = ({
  paymentMethod,
  showDeleteButton = false,
  showOnMakePrimary = true,
  onDelete,
  onMakePrimary
}) => (
  <Grid container direction="row" alignItems="stretch" style={{ paddingTop: 32 }}>
    <Grid item container xs={1} style={{ paddingTop: 2 }}>
      <img
        src={paymentMethod.imageUrl!}
        style={{ width: 30, height: 19 }}
        alt={(paymentMethod as CreditCard)?.cardType || ''}
      />
    </Grid>
    <Grid xs item container direction="column" style={{ paddingLeft: 8 }}>
      <Grid item container direction="row">
        <P mui muiType="body-2-large" styleOverride={{ color: palette.slate, paddingRight: 4 }}>
          {paymentMethod.hasOwnProperty('cardType') ? (
            <>
              {(paymentMethod as CreditCard)?.cardType} ending in{' '}
              {(paymentMethod as CreditCard)?.last4}
            </>
          ) : (
            <>Paypal account: {(paymentMethod as PayPalAccount)?.email}</>
          )}
        </P>
        {!!paymentMethod?.default && (
          <P mui muiType="body-2-large-italic" styleOverride={{ color: palette.tarnishedGold }}>
            (Primary)
          </P>
        )}
      </Grid>
      <Grid item>
        <P mui muiType="body">
          {paymentMethod.hasOwnProperty('cardType') ? (
            <>Exp. {(paymentMethod as CreditCard)?.expirationDate}</>
          ) : (
            ''
          )}
        </P>
      </Grid>
      <Grid item container style={{ paddingTop: 13 }} justify="flex-end">
        {!(paymentMethod as CreditCard)?.default && showOnMakePrimary && (
          <Button
            style={{ textTransform: 'none' }}
            onClick={() => onMakePrimary((paymentMethod as CreditCard)?.token || '')}
          >
            <P mui muiType="sub-header-bold" styleOverride={{ color: palette.caribbean }}>
              Make Primary
            </P>
          </Button>
        )}
        {showDeleteButton && (
          <Button
            style={{ textTransform: 'none', marginLeft: 10 }}
            onClick={() => onDelete((paymentMethod as CreditCard)?.token || '')}
          >
            <P mui muiType="sub-header-bold" styleOverride={{ color: palette.caribbean }}>
              Delete
            </P>
          </Button>
        )}
      </Grid>
    </Grid>
  </Grid>
);

interface IPaypalAccountContainer {
  paymentMethod: PayPalAccount;
  showDeleteButton?: boolean;
  showOnMakePrimary?: boolean;
  onDelete: (token: string) => void;
  onMakePrimary: (token: string) => void;
}

export const PaypalAccountContainer: FC<IPaypalAccountContainer> = ({
  paymentMethod,
  showDeleteButton = false,
  showOnMakePrimary = true,
  onDelete,
  onMakePrimary
}) => (
  <Grid container direction="row" alignItems="stretch" style={{ paddingTop: 32 }}>
    <Grid item container xs={1} style={{ paddingTop: 2 }}>
      <img src={paymentMethod.imageUrl!} style={{ width: 30, height: 19 }} alt={'paypal-account'} />
    </Grid>
    <Grid xs item container direction="column" style={{ paddingLeft: 8 }}>
      <Grid item container direction="row">
        <P mui muiType="body-2-large" styleOverride={{ color: palette.slate, paddingRight: 4 }}>
          Paypal:{' '}
        </P>
        {!!paymentMethod?.default && (
          <P mui muiType="body-2-large-italic" styleOverride={{ color: palette.tarnishedGold }}>
            (Primary)
          </P>
        )}
      </Grid>
      <Grid item container direction="row">
        <P mui muiType="body-2-large-italic" styleOverride={{ color: palette.slate }}>
          {paymentMethod?.email}
        </P>
      </Grid>
      <Grid xs item container direction="column" style={{ paddingTop: 10 }}>
        <Text customClass="body-sm-i" style={{ color: palette.slate }}>
          Check your{' '}
          <a
            rel="noopener noreferrer"
            href="https://www.paypal.com/myaccount/summary"
            target="_blank"
          >
            PayPal account
          </a>{' '}
          to verify your selected payment method.
        </Text>
      </Grid>
      <Grid item container style={{ paddingTop: 13 }} justify="flex-end">
        {!paymentMethod?.default && showOnMakePrimary && (
          <Button
            style={{ textTransform: 'none' }}
            onClick={() => onMakePrimary(paymentMethod?.token || '')}
          >
            <P mui muiType="sub-header-bold" styleOverride={{ color: palette.caribbean }}>
              Make Primary
            </P>
          </Button>
        )}
        {showDeleteButton && (
          <Button
            style={{ textTransform: 'none', marginLeft: 10 }}
            onClick={() => onDelete(paymentMethod?.token || '')}
          >
            <P mui muiType="sub-header-bold" styleOverride={{ color: palette.caribbean }}>
              Delete
            </P>
          </Button>
        )}
      </Grid>
    </Grid>
  </Grid>
);
