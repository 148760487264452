import React from 'react';

interface IFormErrorProps {
  errorMessage: string;
}

export const FormError = (props: IFormErrorProps) => {
  const { errorMessage } = props;
  return <div>{errorMessage && <p>{errorMessage}</p>}</div>;
};
