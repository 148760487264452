import React, { FC, useEffect } from 'react';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import { navigate } from '@reach/router';

import { CardDashboard } from 'components';
import { DashboardEstimate } from 'core/interfaces';
import { BASE_URL } from 'data/env-vars';
import { palette } from 'styles/global';
import { pushDashboardEstimateCard, pushGAEvent } from 'utils';

interface CardEstimateReadyForReviewProps {
  id: string;
  estimate: DashboardEstimate;
}

export const CardEstimateReadyForReview: FC<CardEstimateReadyForReviewProps> = ({
  id,
  estimate
}) => {
  const cta = `Review Estimate${estimate.count > 1 ? 's' : ''}`;
  const { provider, description, subtotal, grossCost, serviceId } = estimate;

  useEffect(() => pushDashboardEstimateCard({ ...estimate, cta }));
  return (
    <CardDashboard
      id={id}
      backgroundColor={palette.sawdust}
      textColor={palette.marine}
      provider={provider}
      description={description}
      subtotal={estimate.count > 1 ? undefined : subtotal}
      grossCost={grossCost}
      button={{
        id: `review-estimate-${serviceId}-btn`,
        Icon: DescriptionSharpIcon,
        label: cta,
        onClick: (e) => {
          pushGAEvent({ id: e.currentTarget.id });
          navigate(`${BASE_URL}/service-details/${serviceId}/estimate`);
        }
      }}
    />
  );
};
