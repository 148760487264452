import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@material-ui/core';

import { FormError } from 'components';

interface IAuthSetPasswordProps {
  onSubmit: (password: string) => void;
  errorMessage: string;
}

export const AuthSetPassword = ({ onSubmit, errorMessage }: IAuthSetPasswordProps) => {
  const [formInfo, setFormInfo] = useState<{ [key: string]: string }>({
    password: ''
  });

  const handleChange = (e: any) => {
    e.preventDefault();
    const property: string = e.target.id;
    const value: string = e.target.value;
    setFormInfo({ ...formInfo, [property]: value });
  };

  const onSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    const { password } = formInfo;
    onSubmit(password);
  };

  return (
    <form onSubmit={onSubmitForm}>
      <Box flexDirection="row" alignItems="start" justifyItems="start" p="large">
        <Box p="medium" flexDirection="column" flex>
          <h1>Set your password</h1>
          <FormError errorMessage={errorMessage} />
          <TextField
            placeholder="Enter Password"
            required
            label="Password"
            name="password"
            type="password"
            value={formInfo.password}
            id="password"
            onChange={handleChange}
          />
          <Typography>
            Contact{' '}
            <strong>
              <a href="mailto: customer@castleprototype.com<">customer@castleprototype.com</a>
            </strong>{' '}
            if you want help to log in.
          </Typography>
        </Box>
      </Box>
      <Box flexDirection="row" alignItems="center" justifyItems="center">
        <Button type="submit">SUBMIT</Button>
      </Box>
    </form>
  );
};
