import React, { useCallback, useEffect, useState, useRef, FC, RefObject } from 'react';
import {
  hostedFields as BTHostedFields,
  HostedFields,
  BraintreeError,
  Client
} from 'braintree-web';
import { Grid } from '@material-ui/core';
import {
  CreditCardSharp,
  PersonOutlineSharp,
  CalendarTodaySharp,
  LockSharp,
  LocationOnSharp
} from '@material-ui/icons';

import { P } from 'components';
import './BraintreeHostedFields.css';

interface IBraintreeHostedFields {
  client?: Client;
  vault?: boolean;
  submitButton?: React.RefObject<HTMLButtonElement>;
  onFocus: () => void;
  onError: (error: Pick<BraintreeError, 'message'>) => void;
  redeemNonce: (nonce: string) => Promise<void>;
}

export const BraintreeHostedFields: FC<IBraintreeHostedFields> = ({
  client,
  submitButton,
  redeemNonce,
  onError,
  onFocus,
  vault = false
}) => {
  const cardHolderNameRefLabel = useRef<HTMLLabelElement>(null);
  const cardNumberRefLabel = useRef<HTMLLabelElement>(null);
  const cvvRefLabel = useRef<HTMLLabelElement>(null);
  const expirationDateRefLabel = useRef<HTMLLabelElement>(null);
  const postalCodeRefLabel = useRef<HTMLLabelElement>(null);
  const cardHolderNameInput = useRef<HTMLInputElement>(null);

  const [cardholderName, setCardholderName] = useState<string>('');
  const [hostedFields, setHostedFields] = useState<HostedFields>();

  const createHostedFields = useCallback(async () => {
    if (client && !hostedFields) {
      try {
        const fields = await BTHostedFields.create({
          client,
          fields: {
            number: {
              selector: '#card-number',
              placeholder: '٭٭٭٭ ٭٭٭٭ ٭٭٭٭ ٭٭٭٭'
            },
            cvv: {
              selector: '#cvv',
              placeholder: '٭٭٭'
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: '00 / 0000'
            },
            postalCode: {
              selector: '#postal-code',
              placeholder: '00000'
            }
          },
          styles: {
            input: {
              'font-family': 'sans-serif',
              'font-weight': 400,
              'font-size': '16px',
              color: '#14313a',
              'line-height': '20px',
              'letter-spacing': '0px'
            }
          }
        });
        setHostedFields(fields);
      } catch (e) {
        console.log(e);
        // onError(e);
      }
    }
  }, [setHostedFields, hostedFields, client]);

  const setupEvents = useCallback(
    async (f: HostedFields) => {
      const labelMap: Record<string, RefObject<HTMLLabelElement>> = {
        'card-number': cardNumberRefLabel,
        'expiration-date': expirationDateRefLabel,
        cvv: cvvRefLabel,
        'postal-code': postalCodeRefLabel
      };

      f.on('focus', (e) => {
        onFocus();
        const field = e.fields[e.emittedBy];
        const fieldLabel = labelMap[field.container.id];
        if (!!fieldLabel && !!fieldLabel.current) {
          field.container.className = 'hosted-field focused';
          fieldLabel.current.className = 'hosted-field--label focused';
        }
      });

      f.on('blur', (e) => {
        const field = e.fields[e.emittedBy];
        const fieldLabel = labelMap[field.container.id];
        if (!!fieldLabel && !!fieldLabel.current) {
          if (!field.isValid) {
            field.container.className = 'hosted-field invalid';
            fieldLabel.current.className = 'hosted-field--label invalid';
          } else {
            field.container.className = 'hosted-field';
            fieldLabel.current.className = 'hosted-field--label';
          }
        }
      });
    },
    [onFocus]
  );

  const tokenize = useCallback(
    async (f: HostedFields) => {
      try {
        if (!cardholderName) {
          if (
            cardHolderNameInput &&
            cardHolderNameInput.current &&
            cardHolderNameRefLabel &&
            cardHolderNameRefLabel.current
          ) {
            cardHolderNameRefLabel.current.className = `hosted-field--label invalid`;
            cardHolderNameInput.current.className = `hosted-field invalid`;
          }
          onError({ message: '' });
          return;
        }
        const token = await f.tokenize({
          cardholderName,
          vault
        });
        if (!!token && !!token.nonce) {
          redeemNonce(token.nonce);
        }
      } catch (error) {
        onError({ message: '' });
      }
    },
    [cardholderName, vault, redeemNonce, onError]
  );

  const setClasses: (e: React.FocusEvent<HTMLInputElement>, type: 'focus' | 'blur') => void = (
    e,
    type
  ) => {
    if (!cardholderName && type === 'blur') {
      setClass(e, 'invalid');
    } else if (type === 'focus') {
      setClass(e, 'focused');
    } else {
      setClass(e);
    }
  };

  const setClass = (e: React.FocusEvent<HTMLInputElement>, type = '') => {
    e.currentTarget.className = `hosted-field ${type}`;
    if (!!cardHolderNameRefLabel && !!cardHolderNameRefLabel.current) {
      cardHolderNameRefLabel.current.className = `hosted-field--label ${type}`;
    }
  };

  useEffect(() => {
    if (!!client && !hostedFields) {
      createHostedFields();
    }
  }, [client, hostedFields, createHostedFields]);

  useEffect(() => {
    if (hostedFields) {
      if (submitButton && submitButton.current) {
        setupEvents(hostedFields);
        submitButton.current.onclick = (_e) => tokenize(hostedFields);
      }
    }
  }, [hostedFields, setupEvents, submitButton, tokenize]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div style={{ ...style.labelContainer, display: 'flex' }}>
          <FormLabel
            labelRef={cardHolderNameRefLabel}
            htmlFor="cardholder-name"
            title="Name on Card"
            icon={<PersonOutlineSharp />}
            styleOverride={{ zIndex: 1 }}
          />
        </div>
        <div style={{ width: '100%', display: 'flex' }}>
          <input
            id="cardholder-name"
            className="hosted-field"
            style={{ flexGrow: 1 }}
            onChange={(event) => setCardholderName(event.currentTarget.value)}
            onFocus={(e) => {
              onFocus();
              setClasses(e, 'focus');
            }}
            onBlur={(e) => setClasses(e, 'blur')}
            placeholder="Name on Card"
            ref={cardHolderNameInput}
          />
        </div>
      </Grid>
      <BraintreeField
        labelRef={cardNumberRefLabel}
        htmlFor="card-number"
        title="Card Number"
        icon={<CreditCardSharp />}
      />
      <BraintreeField
        labelRef={expirationDateRefLabel}
        htmlFor="expiration-date"
        title="Expiration Date"
        icon={<CalendarTodaySharp />}
      />
      <Grid item container spacing={2} direction="row">
        <BraintreeField
          labelRef={cvvRefLabel}
          htmlFor="cvv"
          title="CVV"
          icon={<LockSharp />}
          size={6}
        />
        <BraintreeField
          labelRef={postalCodeRefLabel}
          htmlFor="postal-code"
          title="Postal Code"
          icon={<LocationOnSharp />}
          size={6}
        />
      </Grid>
    </Grid>
  );
};

interface IBraintreeField {
  size?: 12 | 6;
  title: string;
  labelRef: RefObject<HTMLLabelElement>;
  htmlFor: string;
  icon: JSX.Element;
}

const BraintreeField: FC<IBraintreeField> = ({ size = 12, title, labelRef, htmlFor, icon }) => (
  <Grid item xs={size}>
    <div style={style.labelContainer}>
      <FormLabel labelRef={labelRef} htmlFor={htmlFor} title={title} icon={icon} />
    </div>
    <div id={htmlFor} className="hosted-field"></div>
  </Grid>
);

interface IFormLabel {
  title: string;
  labelRef: RefObject<HTMLLabelElement>;
  htmlFor: string;
  icon: JSX.Element;
  styleOverride?: React.CSSProperties;
}

const FormLabel: FC<IFormLabel> = ({ title, labelRef, htmlFor, icon, styleOverride }) => (
  <label className="hosted-field--label" htmlFor={htmlFor} ref={labelRef} style={styleOverride}>
    <span className="icon">{icon}</span>{' '}
    <P mui muiType="body-bold" styleOverride={{ paddingTop: 7, color: 'inherit' }}>
      {title}
    </P>
  </label>
);

const style = {
  labelContainer: {
    padding: '0px 10px 0px 10px'
  }
};
