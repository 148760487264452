import React, { FC } from 'react';
import { ApolloProvider } from '@apollo/client';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';

import client from 'graphql/client';
import { BannerProvider } from './BannerContext';
import { ModalProvider } from './ModalContext';
import { UserProvider } from './UserContext';
import { IssueProvider } from './IssueContext';
import { SnackbarProvider } from './SnackbarContext';
import { FeaturesProvider } from './FeaturesContext';
import { palette } from 'styles/global';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: palette.marine
    }
  }
});

export const ProvidersContext: FC = ({ children }) => (
  <ApolloProvider client={client}>
    <BannerProvider>
      <ModalProvider>
        <SnackbarProvider>
          <FeaturesProvider>
            <UserProvider>
              <IssueProvider>
                <div id="app">
                  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
                </div>
              </IssueProvider>
            </UserProvider>
          </FeaturesProvider>
        </SnackbarProvider>
      </ModalProvider>
    </BannerProvider>
  </ApolloProvider>
);
