import React, { FC, useState } from 'react';
import { Grid, InputAdornment, Button, FormControl, withStyles } from '@material-ui/core';
import { ErrorSharp } from '@material-ui/icons';

import { P, InputText, Text } from 'components';
import { palette, fontWeights } from 'styles/global';

interface ResetPasswordProps {
  newPassword: string;
  confirmPassword: string;
  verificationCode: string;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  isPasswordValid: boolean;
  doesPasswordMatch: boolean;
  isVerificationCodeValid: boolean;
  setShowNewPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setPasswordValidity: React.Dispatch<React.SetStateAction<boolean>>;
  setDoesPasswordMatch: React.Dispatch<React.SetStateAction<boolean>>;
  setVerificationCodeValidity: React.Dispatch<React.SetStateAction<boolean>>;
  onFieldChange: (field: string, value: string) => void;
}
const PasswordCriteria: FC = () => (
  <Grid
    container
    direction="row"
    style={{
      color: palette.electricalWire,
      paddingTop: 5
    }}
  >
    <ErrorSharp style={{ fontSize: 11, padding: '3px 5px 0px' }}></ErrorSharp>
    <Grid item>
      <Text customClass="body-sm">at least 8 characters</Text>
      <Text customClass="body-sm">at least 1 number</Text>
      <Text customClass="body-sm">at least 1 character is a special character</Text>
      <Text customClass="body-sm">at least 1 character is an uppercase letter</Text>
      <Text customClass="body-sm">at least 1 character is a lowercase letter</Text>
    </Grid>
  </Grid>
);

export const ResetPassword: FC<ResetPasswordProps> = ({
  newPassword,
  confirmPassword,
  showNewPassword,
  showConfirmPassword,
  isPasswordValid,
  isVerificationCodeValid,
  doesPasswordMatch,
  setPasswordValidity,
  setDoesPasswordMatch,
  setShowNewPassword,
  setShowConfirmPassword,
  setVerificationCodeValidity,
  onFieldChange
}) => {
  const validatePassword = (password: string) => {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    const isPasswordValid = regex.test(password);
    setPasswordValidity(isPasswordValid);
  };

  const validateVerificationCode = (verificationCode: string) => {
    const regex = /^[0-9]{6,}$/;
    const isVerificationCodeValid = regex.test(verificationCode);
    setVerificationCodeValidity(isVerificationCodeValid);
  };

  const onChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onFieldChange('password', value);
    validatePassword(value);
    if (!confirmPassword) return;
    setDoesPasswordMatch(value === confirmPassword);
  };

  const onChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onFieldChange('confirmPassword', value);
    setDoesPasswordMatch(value === newPassword);
  };

  const onChangeVerificationCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onFieldChange('verificationCode', value);
    validateVerificationCode(value);
  };

  const [passwordFocused, setPasswordFocused] = useState(false);
  const [VerificationCodeFocused, setVerificationCodeFocused] = useState(false);
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <CssTextField variant="outlined" fullWidth>
          <InputText
            id="verification-code"
            label="Verification Code"
            fullWidth
            onChange={onChangeVerificationCode}
            autoComplete="off"
            onBlur={() => setVerificationCodeFocused(true)}
            error={!isVerificationCodeValid && VerificationCodeFocused}
            helperText={!isVerificationCodeValid && VerificationCodeFocused && 'Please enter a valid 6-digit code.'}
            FormHelperTextProps={{ id: 'error-verification-code' }}
          ></InputText>
        </CssTextField>
      </Grid>
      <Grid item>
        <CssTextField variant="outlined" fullWidth>
          <InputText
            id="create-password"
            label="New Password"
            type={showNewPassword ? 'text' : 'password'}
            fullWidth
            error={!isPasswordValid && passwordFocused}
            onBlur={() => setPasswordFocused(true)}
            FormHelperTextProps={{ id: 'error-create-password' }}
            defaultValue={newPassword}
            onChange={onChangeNewPassword}
            autoComplete="new-password"
            InputProps={
              passwordFocused
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button onClick={() => setShowNewPassword(!showNewPassword)}>
                          <P mui muiType="caption-body-bold">
                            {showNewPassword ? 'HIDE' : 'SHOW'}
                          </P>
                        </Button>
                      </InputAdornment>
                    )
                  }
                : {}
            }
          />
        </CssTextField>
        {!isPasswordValid && passwordFocused && <PasswordCriteria />}
      </Grid>
      <Grid
        item
        style={{
          paddingBottom: 40
        }}
      >
        <CssTextField variant="outlined" fullWidth>
          <InputText
            id="confirm-password"
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            fullWidth
            error={!doesPasswordMatch && passwordFocused}
            helperText={!doesPasswordMatch && passwordFocused && 'Your passwords do not match.'}
            FormHelperTextProps={{ id: 'error-confirm-password' }}
            defaultValue={confirmPassword}
            onBlur={() => setPasswordFocused(true)}
            onChange={onChangeConfirmPassword}
            autoComplete="new-password"
            InputProps={
              passwordFocused
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                          <P mui muiType="caption-body-bold">
                            {showConfirmPassword ? 'HIDE' : 'SHOW'}
                          </P>
                        </Button>
                      </InputAdornment>
                    )
                  }
                : {}
            }
          ></InputText>
        </CssTextField>
      </Grid>
    </Grid>
  );
};

const CssTextField = withStyles({
  //Default
  root: {
    fontFamily: 'Source Sans Pro',
    fontWeight: fontWeights.regular,
    fontSize: 16,
    lineHeight: 22,
    letterSpacing: 0,
    //Shrink label
    '& .MuiInputLabel-shrink': {
      //Default
      backgroundColor: palette.pvcWhite,
      fontWeight: 700,
      color: 'red',
      //focused and error
      '& .Mui-focused .Mui-error': {
        color: palette.electricalWire
      },
      //filled
      '& .MuiFormLabel-filled': {
        color: palette.marine,
        fontWeight: 700
      },
      //error
      '&.Mui-error p': {
        color: palette.electricalWire
      },
      //focused
      '& .Mui-focused': {
        color: palette.tarnishedGold
      },
      //filled and error
      '& .MuiFormLabel-filled .Mui-error': {
        color: palette.electricalWire,
        fontWeight: 700
      }
    },
    //Regular Label
    '& .MuiInputLabel-outlined': {
      //Default
      color: palette.concrete,
      //Error
      '&.Mui-error': {
        color: palette.electricalWire
      },
      //Outline on filled
      '&.MuiFormLabel-filled + .MuiInputBase-formControl fieldset': {
        borderColor: palette.marine
      },
      //outline if filled and error
      '&.MuiFormLabel-filled.Mui-error + .MuiInputBase-formControl fieldset': {
        borderColor: palette.electricalWire
      }
    },

    //Main Input Box
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.concrete
      },
      //Focused
      '&.Mui-focused': {
        Color: palette.tarnishedGold
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.tarnishedGold,
        borderWidth: 1
      },
      //Typed in number
      '& .MuiOutlinedInput-input': {
        paddingLeft: 16
      },
      '&.Mui-error': {
        color: palette.electricalWire,
        '& .MuiInputAdornment-root': {
          color: palette.electricalWire
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.electricalWire
        }
      }
    }
  }
})(FormControl);
