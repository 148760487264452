import React, { CSSProperties, ReactNode } from 'react';
import { palette, fontWeights } from 'styles/global';

interface IAProps {
  href?: string;
  onClick?: Function;
  children?: ReactNode;
  styleOverride?: CSSProperties;
  type?: '1' | '2' | '3';
  id?: string;
  mui?: boolean;
  muiType?: 'semi-bold';
  target?: string;
}

export const A = ({
  href,
  onClick,
  children,
  type = '1',
  styleOverride,
  mui = false,
  muiType = 'semi-bold',
  target
}: IAProps) => {
  const style = mui ? styles.mui[muiType] : styles.grommet[type];
  return (
    <a
      href={href}
      style={{ ...style, ...styleOverride }}
      onClick={
        onClick
          ? () => {
              onClick();
            }
          : () => true
      }
      target={target}
    >
      {children}
    </a>
  );
};

const styles = {
  grommet: {
    1: {
      fontFamily: 'Assistant',
      fontWeight: 700,
      fontSize: 16,
      color: palette.coal,
      lineHeight: '21px',
      margin: 0
    },
    2: {
      fontFamily: 'Assistant',
      fontWeight: 600,
      fontSize: 12,
      color: palette.coal,
      lineHeight: '16px',
      margin: 0
    },
    3: {
      fontFamily: 'Assistant',
      fontWeight: 400,
      fontSize: 12,
      color: palette.coal,
      textDecoration: 'underline',
      margin: 0
    }
  },
  mui: {
    'semi-bold': {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.semiBold,
      fontSize: 14,
      color: palette.freon,
      textDecoration: 'underline',
      margin: 0
    }
  }
};
