import React, { FC, useContext, useRef, useState } from 'react';
import { navigate } from '@reach/router';
import { Button, Grid } from '@material-ui/core';
import {
  ActionButton,
  P,
  MUIButton,
  EstimateDivider,
  CastleCheckBox,
  BraintreeClient,
  BraintreePaypal,
  BraintreeHostedFields,
  A,
  CastleDialog
} from 'components';
import { palette } from 'styles/global';
import { BASE_URL } from 'data/env-vars';
import { SnackbarContext } from 'context';
import { useQuery } from '@apollo/client';
import { Query } from 'generated/graphql';
import { SNACKBAR_OPTIONS_FUNCTION } from 'data/snackbar-options';
import { pushGAEvent } from 'utils';
import PayPal from 'assets/images/paypal.png';
import Visa from 'assets/images/visa.png';
import Jcb from 'assets/images/jcb.png';
import MasterCard from 'assets/images/mastercard.png';
import Discover from 'assets/images/discover.png';
import AmericanExpress from 'assets/images/american-express.png';
import { GET_PAYMENT_TOKEN_FOR_PAYMENT_METHOD } from 'graphql/queries';
import { PAYPAL_MERCHANT_TERMS } from 'data/constants';

interface IPaymentMethodSelector {
  paypalType: 'vault' | 'checkout';
  renderCancelDialog?: boolean;
  renderDiscardButton?: boolean;
  renderCreditCardSave?: boolean;
  amountDue?: number;
  paypalActionText?: string;
  creditCardActionText?: string;
  submitButtonRef?: React.RefObject<HTMLButtonElement>;
  payPalSubmitButtonRefOverride?: React.RefObject<HTMLButtonElement>;
  isAdditionalRadioSelected?: boolean;
  renderTerms?: boolean;
  onRadioSelect?: (value: 'paypal' | 'creditCard') => void;
  onPaypalVault?: () => void;
  savePaymentMutation: (nonce: string) => void;
}

export const PaymentMethodSelector: FC<IPaymentMethodSelector> = ({
  paypalType,
  renderCancelDialog,
  renderDiscardButton,
  renderCreditCardSave,
  paypalActionText,
  creditCardActionText,
  amountDue,
  submitButtonRef,
  payPalSubmitButtonRefOverride,
  isAdditionalRadioSelected,
  renderTerms = true,
  onRadioSelect,
  onPaypalVault,
  savePaymentMutation
}) => {
  const { data } = useQuery<Query>(GET_PAYMENT_TOKEN_FOR_PAYMENT_METHOD);
  const { updateSnackbar, closeSnackbar } = useContext(SnackbarContext);

  const [radioButton, setRadioButton] = useState<'paypal' | 'creditCard' | undefined>(undefined);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const creditCardSubmitButton = useRef<HTMLButtonElement>(null);
  const payPalSubmitButton = useRef<HTMLButtonElement>(null);
  const [terms, setTerms] = useState(false);

  return (
    <>
      <Grid direction="row" container alignItems="baseline">
        <ActionButton
          onClick={() => {
            setRadioButton('paypal');
            if (onRadioSelect) {
              onRadioSelect('paypal');
            }
          }}
          checked={radioButton === 'paypal' && !isAdditionalRadioSelected}
          id="paypal-radio-btn"
          styleOverride={{ padding: '12px 12px 12px 0px' }}
        />
        <Grid item container xs direction="column">
          <Grid item xs container direction="row">
            <P mui muiType="body-2-large">
              {paypalActionText ? paypalActionText : 'Link your PayPal Account'}
            </P>
            <Grid item xs>
              <img
                src={PayPal}
                alt={`${PayPal}`}
                style={{
                  width: 35,
                  height: 22,
                  padding: `0px 0px 0px 8px`
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid direction="row" container alignItems="baseline">
        <ActionButton
          onClick={() => {
            setRadioButton('creditCard');
            if (onRadioSelect) {
              onRadioSelect('creditCard');
            }
          }}
          checked={radioButton === 'creditCard' && !isAdditionalRadioSelected}
          id="credit-card-radio-btn"
          styleOverride={{ padding: '12px 12px 12px 0px' }}
        />
        <Grid item container xs direction="column">
          <Grid item xs container direction="row">
            <P mui muiType="body-2-large">
              {creditCardActionText ? creditCardActionText : 'Add New Card'}
            </P>
            <Grid item xs>
              {[Visa, MasterCard, Jcb, Discover, AmericanExpress].map((name, index) => (
                <img
                  src={name}
                  key={`${name}-${index}`}
                  alt={`${name}`}
                  style={{
                    width: 35,
                    height: 22,
                    padding: `0px 0px 0px ${index === 0 ? '8' : '3'}px`
                  }}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        {!payPalSubmitButtonRefOverride && (
          <Grid item xs>
            <EstimateDivider styleOverride={{ marginTop: 17, marginBottom: 12 }} />
          </Grid>
        )}
        {!!data && (
          <>
            <BraintreeClient token={data.getPaymentTokenForPaymentMethod}>
              {radioButton === 'paypal' && !isAdditionalRadioSelected && (
                <BraintreePaypal
                  redeemNonce={async (nonce) => {
                    pushGAEvent({ id: 'continue-paypal-btn' });
                    savePaymentMutation(nonce);
                  }}
                  onError={(_message) => {
                    updateSnackbar(
                      SNACKBAR_OPTIONS_FUNCTION.braintreeError(
                        'Unable to add payment method. Please try again or call for assistance.'
                      )
                    );
                  }}
                  flow={paypalType}
                  amount={amountDue}
                  submitButton={payPalSubmitButtonRefOverride || payPalSubmitButton}
                  billingAgreementDescription={PAYPAL_MERCHANT_TERMS}
                />
              )}
              {radioButton === 'creditCard' && !isAdditionalRadioSelected && (
                <BraintreeHostedFields
                  redeemNonce={async (nonce) => {
                    pushGAEvent({ id: 'add-payment-save-success', type: 'formSubmit' });
                    savePaymentMutation(nonce);
                  }}
                  onError={(_message) => {
                    updateSnackbar(
                      SNACKBAR_OPTIONS_FUNCTION.braintreeError(
                        'Unable to add payment method. Please try again or call for assistance.'
                      )
                    );
                  }}
                  submitButton={submitButtonRef}
                  onFocus={() => closeSnackbar(SNACKBAR_OPTIONS_FUNCTION.braintreeError('').key)}
                />
              )}
            </BraintreeClient>
            {!!radioButton && !isAdditionalRadioSelected && (
              <>
                {renderTerms && (
                  <Grid container direction="row" style={{ paddingTop: 44 }}>
                    <Grid item xs={1}>
                      <CastleCheckBox
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          pushGAEvent({ id: 'add-payment-accept-terms-btn' });
                          setTerms(e.target.checked);
                        }}
                        checked={terms}
                      />
                    </Grid>
                    <Grid item xs style={{ paddingBottom: 60 }}>
                      <P mui muiType="body">
                        By checking this box, I authorize Castle to charge my payment method above
                        for agreed upon purchases. I understand that I will be charged the
                        assessment fee if a Castle representative provides an estimate for work, but
                        I decide not to accept. I also agree to the terms and conditions of this
                        agreement.{' '}
                        <A
                          mui
                          muiType="semi-bold"
                          href={`${BASE_URL}/payment-terms`}
                          target="_blank"
                          onClick={() => {
                            pushGAEvent({ id: 'add-payment-terms-lbl' });
                          }}
                          styleOverride={{ color: palette.marine }}
                        >
                          View Payment Terms and Conditions.
                        </A>
                      </P>
                    </Grid>
                  </Grid>
                )}
                {(!payPalSubmitButtonRefOverride || radioButton === 'creditCard') && (
                  <Grid container direction="column" spacing={2}>
                    {radioButton === 'creditCard' &&
                      renderCreditCardSave &&
                      !isAdditionalRadioSelected && (
                        <Grid item xs>
                          <MUIButton
                            label="Save"
                            fullWidth
                            disabled={renderTerms && !terms}
                            ref={creditCardSubmitButton}
                            onClick={() => {
                              pushGAEvent({ id: 'add-payment-save-btn', type: 'button' });
                            }}
                          />
                        </Grid>
                      )}
                    {radioButton === 'paypal' &&
                      !isAdditionalRadioSelected &&
                      !payPalSubmitButtonRefOverride && (
                        <Grid item xs>
                          <MUIButton
                            label="Continue to PayPal"
                            fullWidth
                            disabled={renderTerms && !terms}
                            ref={payPalSubmitButton}
                            onClick={onPaypalVault}
                          />
                        </Grid>
                      )}
                    {renderDiscardButton && (
                      <Grid item xs>
                        <MUIButton
                          label="Discard"
                          fullWidth
                          onClick={() => {
                            pushGAEvent({ id: 'add-payment-discard-btn' });
                            setOpenCancelDialog(true);
                          }}
                          type="outline"
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      {renderCancelDialog && (
        <CastleDialog
          open={openCancelDialog}
          title={'Discard Edits'}
          actions={
            <Grid item container style={{ paddingTop: 21 }} justify="flex-end">
              <Button
                style={{ textTransform: 'none' }}
                onClick={() => {
                  pushGAEvent({ id: 'add-payment-cancel-lnk' });
                  setOpenCancelDialog(false);
                }}
              >
                <P mui muiType="sub-header-bold" styleOverride={{ color: palette.granite }}>
                  Cancel
                </P>
              </Button>
              <Button
                style={{ textTransform: 'none', marginLeft: 10 }}
                onClick={() => {
                  pushGAEvent({ id: 'add-payment-discard-lnk' });
                  navigate(`${BASE_URL}/account/payment-methods`);
                }}
              >
                <P mui muiType="sub-header-bold" styleOverride={{ color: palette.caribbean }}>
                  Discard
                </P>
              </Button>
            </Grid>
          }
        >
          <P mui muiType="body-2-large">
            Continue without adding new card?
          </P>
        </CastleDialog>
      )}
    </>
  );
};
