import React, { FC } from 'react';

import { CardDashboard } from 'components';
import { DashboardService } from 'core/interfaces';
import { palette } from 'styles/global';
import { pushGAEvent } from 'utils';
import { navigate } from '@reach/router';
import { BASE_URL } from 'data/env-vars';

interface CardServiceInProgressProps {
  id: string;
  service: DashboardService;
}

export const CardServiceInProgress: FC<CardServiceInProgressProps> = ({ id, service }) => {
  const { provider, technician, title, subtotal, serviceId, grossCost } = service;

  return (
    <CardDashboard
      id={id}
      backgroundColor={palette.sawdust}
      textColor={palette.marine}
      action={{
        status: 'In Progress',
        cta: {
          id: `view-service-${serviceId}-lnk`,
          label: 'View Service',
          onClick: (e) => {
            pushGAEvent({ id: e.currentTarget.id });
            navigate(`${BASE_URL}/service-details/${serviceId}`);
          }
        }
      }}
      grossCost={grossCost}
      provider={provider}
      technician={technician}
      description={title}
      subtotal={subtotal}
    />
  );
};
