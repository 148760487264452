import { Service, User, EstimateStatus } from 'generated/graphql';

interface GAEvent {
  event?: 'click';
  type?: 'link' | 'button' | 'radio' | 'formSubmit';
  id: string;
}

export const gtmDataLayer: any[] = (window as any).dataLayer;

export const pushSnackbarInformation = (snackbarTitle: string, snackbarSubtitle?: string) => {
  gtmDataLayer.push({
    snackbarTitle,
    snackbarSubtitle
  });
};

export const pushBannerInformation = (bannerTitle: string, bannerCta: string) => {
  gtmDataLayer.push({
    bannerTitle,
    bannerCta
  });
};

export const pushDashboardAppointmentCard = (dashboardAppointmentCard: any) => {
  gtmDataLayer.push({ dashboardAppointmentCard });
};

export const pushDashboardEstimateCard = (dashboardEstimateCard: any) => {
  gtmDataLayer.push({ dashboardEstimateCard });
};

export const pushDashboardServiceCard = (dashboardServiceCard: any) => {
  gtmDataLayer.push({ dashboardServiceCard });
};

export const pushHistoryServiceCard = (historyServiceCard: any) => {
  gtmDataLayer.push({ historyServiceCard });
};

export const pushPageTitle = (pageTitle: string) => {
  gtmDataLayer.push({
    pageTitle
  });
};

export const pushGAEvent = ({ id, type = 'button', event = 'click' }: GAEvent) => {
  gtmDataLayer.push({ id, type, event });
};

export const pushEstimatePage = (service: Service) => {
  const { estimates, id, type, typeDescription, serviceProvider, technicianName, fee } = service;
  const assessmentFee = fee?.fee;
  if (!!estimates[0] && estimates[0] !== null && !!serviceProvider && serviceProvider !== null) {
    const { laborSubtotal, partsSubtotal, discount, totalCost } = estimates[0];
    const serviceProviderName = serviceProvider.name;
    gtmDataLayer.push({
      estimate: {
        laborSubtotal,
        partsSubtotal,
        discount,
        totalCost
      },
      service: {
        id,
        assessmentFee,
        type,
        typeDescription,
        technicianName,
        serviceProviderName
      }
    });
  }
};

export const pushReceiptPage = (service: Service) => {
  const { estimates, id, status, type, typeDescription, fee } = service;
  const assessmentFee = fee?.fee;
  if (!!estimates && estimates.length > 0) {
    const { laborSubtotal, partsSubtotal, discount, totalCost, salesTax } =
      estimates.find((estimate) => estimate.status === EstimateStatus.Accepted) || estimates[0];
    gtmDataLayer.push({
      estimate: {
        laborSubtotal,
        partsSubtotal,
        discount,
        totalCost,
        salesTax
      },
      service: {
        id,
        status,
        type,
        typeDescription,
        assessmentFee
      }
    });
  }
};

export const pushPaymentPage = (service: Service) => {
  const {
    estimates,
    id,
    status,
    type,
    typeDescription,
    serviceProvider,
    technicianName,
    fee
  } = service;
  const assessmentFee = fee?.fee;
  const estimate =
    estimates.find((estimate) => estimate.status === EstimateStatus.Accepted) || estimates[0];
  if (!!serviceProvider && serviceProvider !== null) {
    const serviceProviderName = serviceProvider.name;
    gtmDataLayer.push({
      estimate: {
        laborSubtotal: estimate?.laborSubtotal,
        partsSubtotal: estimate?.partsSubtotal,
        discount: estimate?.discount,
        totalCost: estimate?.totalCost
      },
      service: {
        id,
        status,
        type,
        typeDescription,
        assessmentFee,
        technicianName,
        serviceProviderName
      }
    });
  }
};

export const pushServiceDetailsPage = (user: User, service: Service) => {
  const { addresses, createdAt } = user;
  const primaryAddress = addresses.find((address) => address.primary);
  const market = primaryAddress?.market;
  const {
    estimates,
    id,
    status,
    type,
    typeDescription,
    serviceProvider,
    financialTransactions,
    fee
  } = service;
  const assessmentFee = fee?.fee;

  const { instrumentType } =
    financialTransactions && financialTransactions.length > 0
      ? financialTransactions[0]
      : { instrumentType: '' };

  if (!!estimates && estimates.length > 0 && !!serviceProvider) {
    const { laborSubtotal, partsSubtotal, discount, totalCost, salesTax } = estimates[0];
    const serviceProviderName = serviceProvider.name;

    gtmDataLayer.push({
      estimate: {
        laborSubtotal,
        partsSubtotal,
        discount,
        totalCost,
        salesTax
      },
      service: {
        id,
        status,
        type,
        typeDescription,
        assessmentFee,
        serviceProviderName
      },
      financialTransaction: {
        instrumentType
      },
      user: {
        market,
        createdAt,
        instrumentType
      }
    });
  }
};
