import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  fragment InvFrag on Invoice {
    status
    description
    totalCost
    invoiceNumber
    totalPromoValue
    grossCost
    subtotal
    salesTax
    customerCost
    partsSubtotal
    laborSubtotal
    discount
  }

  fragment PromoReferesFrag on PromotionReference {
    id
    createdAt
    updatedAt
    redeemed
    totalDollarAmount
    projectId
    userId
    feeId
    estimateId
    serviceId
    promotion {
      id
      description
      shortTerms
      title
      market
      promoCode
      amount
      amountType
      startDate
      endDate
      terms
      redemptions
      combinable
      createdAt
      updatedAt
    }
  }

  fragment Appt on ServiceWindow {
    type
    id
    startAt
    endAt
  }

  fragment Address on Address {
    id
    primary
    street1
    street2
    city
    state
    zip
    market
    createdAt
    updatedAt
    timezone
  }

  fragment F on Fee {
    id
    fee
    tax
    type
    createdAt
    customerCost
    grossCost
    subtotal
    totalPromoValue
    updatedAt
    promotionReference {
      ...PromoReferesFrag
    }
  }

  fragment FT on FinancialTransaction {
    id
    instrumentDescription
    processorId
    createdAt
    amount
    processor
    processorId
    instrumentType
    instrumentDescription
  }

  fragment COGS on ServiceCostOfGoodsSold {
    id
    materials
    equipment
    labor
    melt
    marketBurden
    total
    createdAt
    updatedAt
  }

  fragment SE on ServiceEstimate {
    id
    createdAt
    updatedAt
    title
    status
    description
    parts
    partsSubtotal
    laborSubtotal
    discount
    totalCost
    grossCost
    subtotal
    salesTax
    customerCost
    totalPromoValue
    costOfGoodsSold {
      ...COGS
    }
    promotionReference {
      ...PromoReferesFrag
    }
  }

  query getCurrentUser {
    getCurrentUser {
      id
      firstName
      lastName
      phone
      email
      referral
      referralOther
      xactId
      addresses {
        id
        primary
        street1
        street2
        city
        county
        state
        zip
        market
        timezone
        createdAt
        updatedAt
      }
      paymentMethods {
        creditCards {
          cardType
          cardholderName
          createdAt
          default
          expirationDate
          expirationMonth
          expirationYear
          expired
          imageUrl
          last4
          token
          updatedAt
        }
        paypalAccounts {
          imageUrl
          payerId
          token
          billingAgreementId
          createdAt
          customerId
          default
          email
          revokedAt
          updatedAt
        }
      }
      notes {
        author
        reason
        description
        createdAt
      }
      promotionReference {
        ...PromoReferesFrag
      }
      issues {
        id
        title
        description
        status
        createdAt
        updatedAt
        projects {
          id
          xactId
          title
          invoices {
            ...InvFrag
          }
          fees {
            ...F
          }
          estimates {
            ...SE
          }
          appointments {
            ...Appt
          }
          financialTransactions {
            ...FT
          }
          services {
            id
            category
            type
            typeDescription
            amountRequested
            amountDue
            status
            statusReason
            statusNotes
            estimateDeclinedReason
            estimateDeclinedNotes
            serviceCompletionDate
            workApprovedDate
            createdAt
            updatedAt
            invoices {
              ...InvFrag
            }
            fee {
              ...F
            }
            promotionReference {
              ...PromoReferesFrag
            }
            financialTransactions {
              ...FT
            }
            serviceProvider {
              id
              name
              phone
            }
            appointments {
              ...Appt
            }
            technicianName
            technicianPhone
            estimates {
              ...SE
            }
            arrivalTime
            estimateDeclinedReason
            costOfGoodsSold {
              ...COGS
            }
          }
        }
        notes {
          author
          description
          createdAt
        }
        twilioTasks {
          id
          createdAt
        }
        address {
          ...Address
        }
      }
    }
  }
`;

export const GET_USER_FROM_TOKEN = gql`
  query getUserFromToken($token: String!) {
    getUserFromToken(token: $token) {
      user {
        id
        firstName
        lastName
        fullName
        phone
        email
        addresses {
          id
          primary
          street1
          street2
          city
          market
          state
          zip
          market
        }
      }
      paymentToken
    }
  }
`;

// TODO: UPDATE THE FOLLOWING QUERIES

export const GET_ISSUE = gql`
  query getIssue($id: ID!) {
    getIssue(id: $id) {
      id
      title
      description
      services {
        id
      }
    }
  }
`;

export const GET_ISSUES = gql`
  query getIssues {
    getIssues {
      id
      title
      description
      services {
        id
      }
    }
  }
`;

export const GET_PAYMENT_METHOD = gql`
  query getPaymentMethodInfo($id: String!) {
    getPaymentMethodInfo(customerId: $id) {
      id
      last4
      exp_year
      exp_month
    }
  }
`;

export const GET_PAYMENT_TOKEN = gql`
  query getPaymentToken {
    getPaymentToken
  }
`;

export const GET_PAYMENT_TOKEN_FOR_PAYMENT_METHOD = gql`
  query getPaymentTokenForPaymentMethod {
    getPaymentTokenForPaymentMethod
  }
`;

export const GET_FEATURES = gql`
  query getFeatures {
    getFeatures {
      name
      enabled
    }
  }
`;

export const CALCULATE_SERVICE_ACTION = gql`
  query calculateServiceAction($projectId: String!, $data: [CalculatePaymentInput!]!) {
    calculateServiceAction(projectId: $projectId, data: $data) {
      serviceId
      status
      estimateId
      customerCost
    }
  }
`;
