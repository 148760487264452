import React, { FC, useEffect } from 'react';
import { navigate } from '@reach/router';

import { CardDashboard } from 'components';
import { DashboardService } from 'core/interfaces';
import { ServiceHistoryStatus } from 'data/constants';
import { BASE_URL } from 'data/env-vars';
import { palette } from 'styles/global';
import { pushGAEvent, pushHistoryServiceCard } from 'utils';

interface CardServiceHistoryProps {
  id: string;
  service: DashboardService;
}

export const CardServiceHistory: FC<CardServiceHistoryProps> = ({ id, service }) => {
  const { serviceId, status, provider, technician, title, subtotal, grossCost } = service;

  useEffect(() => pushHistoryServiceCard({ ...service }));

  return (
    <CardDashboard
      id={id}
      backgroundColor={palette.pvcWhite}
      textColor={palette.marine}
      action={{
        status: ServiceHistoryStatus[status],
        cta: {
          id: `view-receipt-${serviceId}-lnk`,
          label: 'View Receipt',
          onClick: (e) => {
            pushGAEvent({ id: e.currentTarget.id });
            navigate(`${BASE_URL}/service-details/${serviceId}/receipt`);
          }
        }
      }}
      provider={provider}
      technician={technician}
      description={title}
      subtotal={subtotal}
      grossCost={grossCost}
    />
  );
};
