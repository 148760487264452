import React, { useContext, useRef, useState, useEffect, useCallback, FC } from 'react';
import { createStyles, Grid, InputBase, MenuItem, Select, withStyles } from '@material-ui/core';
import { navigate } from '@reach/router';
import { useMutation } from '@apollo/client';

import { UserContext, IssueContext, SnackbarContext } from 'context';
import {
  P,
  H3,
  EstimateList,
  EstimateDivider,
  Subtitle,
  EstimateCardContainer,
  ActionButton,
  TotalPaymentContainer,
  CastleBackdrop,
  PaymentMethodSelector,
  Text
} from 'components';
import { palette } from 'styles/global';
import { pushPaymentPage, pushGAEvent } from 'utils';
import { ReactComponent as PaypalLogo } from 'assets/svg/Paypal_Logo_Blue.svg';
import { PROCESS_TRANSACTION } from 'graphql/mutations';

import {
  CreditCard,
  EstimateStatus,
  InvoiceStatus,
  Maybe,
  Mutation,
  MutationProcessTransactionArgs,
  PayPalAccount
} from 'generated/graphql';
import { SNACKBAR_OPTIONS, SNACKBAR_OPTIONS_FUNCTION } from 'data/snackbar-options';
import { BASE_URL } from 'data/env-vars';

interface IPayProps {
  serviceId: string;
  token: string;
}

export const Pay = ({ serviceId, token }: IPayProps) => {
  const { updateSnackbar, closeAllErrorSnackbars } = useContext(SnackbarContext);
  const { updateCurrentUser, user } = useContext(UserContext);
  const { getServiceFromId, getIssueFromService } = useContext(IssueContext);
  const submitButton = useRef<HTMLButtonElement>(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paypalNonce, setPaypalNonce] = useState('');
  const service = getServiceFromId(serviceId);
  const issue = getIssueFromService(serviceId);
  const estimates = service.estimates;
  const receipt = service.invoices?.find((x) => x?.status === InvoiceStatus.Active);

  const existingCreditCards = user.paymentMethods.creditCards || [];
  const existingPaypal = user.paymentMethods.paypalAccounts || [];
  const combinedPaymentMethods = [...existingCreditCards, ...existingPaypal];
  const defaultPaymentMethod =
    combinedPaymentMethods.find((payment) => payment?.default) || combinedPaymentMethods[0];
  const [existingSelectedPaymentMethod, setExistingSelectedPaymentMethod] = useState(
    defaultPaymentMethod?.token || undefined
  );

  useEffect(() => {
    pushPaymentPage(service);
  }, [service]);

  const [processTransactionMutation] = useMutation<Mutation, MutationProcessTransactionArgs>(
    PROCESS_TRANSACTION
  );

  const onTransactionCompleted = useCallback(() => {
    closeAllErrorSnackbars();
    updateSnackbar(SNACKBAR_OPTIONS.successfulPayment);
    updateCurrentUser().then(() => {
      navigate(`${BASE_URL}/service-details/${serviceId}/receipt`);
    });
  }, [updateCurrentUser, serviceId, updateSnackbar, closeAllErrorSnackbars]);

  const onTransactionError = useCallback(
    (_error: any) => {
      setOpenBackdrop(false);
      updateSnackbar(SNACKBAR_OPTIONS_FUNCTION.processPaymentFail());
    },
    [updateSnackbar, setOpenBackdrop]
  );

  const processTransaction = useCallback(
    async ({ nonce, token }: { nonce?: string; token?: string | null }): Promise<void> => {
      if (!nonce && !token) {
        updateSnackbar(SNACKBAR_OPTIONS.invalidPaymentMethod);
        return;
      }
      closeAllErrorSnackbars();
      setOpenBackdrop(true);
      return processTransactionMutation({
        variables: { nonce, token, serviceId: service.id }
      }).then(onTransactionCompleted, onTransactionError);
    },
    [
      service,
      processTransactionMutation,
      updateSnackbar,
      onTransactionCompleted,
      onTransactionError,
      closeAllErrorSnackbars,
      setOpenBackdrop
    ]
  );

  const selectPaymentMethod = useCallback(
    (method: string) => {
      setPaymentMethod(method);
    },
    [setPaymentMethod]
  );

  const estimate = estimates.find((x) => x.status === EstimateStatus.Accepted);

  if (!service) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const creditCard = user.paymentMethods.creditCards?.find(
      (p) => p?.token === event.target.value
    );

    if (creditCard && creditCard.expired) {
      updateSnackbar(SNACKBAR_OPTIONS.expiredCard);
    }
    setExistingSelectedPaymentMethod(event.target.value as string);
  };

  const subtotal = receipt?.subtotal;
  const customerCost = receipt?.customerCost;
  const grossCost = receipt?.grossCost;

  return (
    <>
      <Grid style={{ padding: '0px 18px' }}>
        <TopTitleContainer />
        <EstimateDivider />
        <Subtitle
          mui
          muiStyle="bold"
          styleOverride={{ textTransform: 'uppercase', letterSpacing: '0.1em' }}
        >
          Review
        </Subtitle>

        <EstimateCardContainer
          description={estimate?.description || ''}
          subtitle={estimate?.title || ''}
          title={service.serviceProvider?.name || 'No Service Provider'}
          grossCost={grossCost!}
          subtotal={subtotal}
          showActionButton={false}
          value
          onClick={() => {}}
          expandedButtonClick={(expanded) => {
            pushGAEvent({
              id: expanded ? 'view-details-pay-close-lbl' : 'view-details-pay-open-lbl'
            });
          }}
        >
          <EstimateList issue={issue} service={service} estimate={estimate} receipt={receipt!} />
        </EstimateCardContainer>

        <Subtitle
          mui
          muiStyle="bold"
          styleOverride={{ textTransform: 'uppercase', letterSpacing: '0.1em' }}
        >
          Pay
        </Subtitle>
        {!paypalNonce ? (
          <PaymentMethodsContainer
            creditCards={existingCreditCards || []}
            paypalAccounts={existingPaypal || []}
            amountDue={customerCost !== undefined ? customerCost : 0}
            submitButton={submitButton}
            handleChange={handleChange}
            processNonceTransaction={(nonce) => {
              pushGAEvent({ id: 'pay-btn' });
              processTransaction({ nonce });
            }}
            existingSelectedPaymentMethod={existingSelectedPaymentMethod}
            selectPaymentMethod={selectPaymentMethod}
            paymentMethod={paymentMethod}
            onTriggerPaypal={(nonce) => {
              setPaypalNonce(nonce);
            }}
          />
        ) : (
          <Grid>
            <Text customClass="overline-b">Payment Method Authorized</Text>
            <Text customClass="body">Click Pay to complete your payment using PayPal</Text>
            <Grid style={{ display: 'flex', marginTop: 16, alignItems: 'center' }}>
              <Text customClass="body-i" style={{ marginRight: 4 }}>
                Secured by
              </Text>
              <PaypalLogo />
            </Grid>
          </Grid>
        )}

        <Grid item xs>
          <EstimateDivider styleOverride={{ marginTop: 17 }} />
        </Grid>
        <Grid item xs style={{ paddingBottom: 50 }}>
          <TotalPaymentContainer
            onClick={() => {
              pushGAEvent({ id: 'pay-btn' });
              const params = paypalNonce
                ? { nonce: paypalNonce }
                : { token: existingSelectedPaymentMethod };
              processTransaction(params);
            }}
            total={customerCost!}
            titleOverride={
              paymentMethod === 'paypal' && !paypalNonce ? 'Check out with PayPal' : 'Pay'
            }
            buttonRef={submitButton}
            useOnClickButton={
              paymentMethod === 'existing' || (paymentMethod === 'paypal' && !!paypalNonce)
            }
            disabled={!paymentMethod}
          />
        </Grid>
      </Grid>
      <CastleBackdrop open={openBackdrop} />
    </>
  );
};

const TopTitleContainer: FC = () => (
  <Grid style={styles.topContainer}>
    <Grid>
      <H3 mui id="pay-to-complete-txt" styleOverride={{ margin: 0 }}>
        Pay For Service(s)
      </H3>
    </Grid>
  </Grid>
);

const styles = {
  acceptButton: {
    height: '36px',
    padding: '0px 24px',
    border: `1px solid ${palette.ocean}`
  },
  topContainer: {
    padding: '33px 0px 8px'
  },
  totalDueContainer: {
    paddingTop: '24px'
  },
  totalDueText: {
    color: palette.firefly
  }
};

interface IPaymentMethodsContainer {
  creditCards: Maybe<CreditCard>[];
  paypalAccounts: Maybe<PayPalAccount>[];
  paymentMethod: string;
  amountDue: number;
  submitButton: React.RefObject<HTMLButtonElement>;
  existingSelectedPaymentMethod: string | undefined | null;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  processNonceTransaction: (nonce: string) => void;
  onTriggerPaypal: (nonce: string) => void;
  selectPaymentMethod: (method: string) => void;
}

const PaymentMethodsContainer: FC<IPaymentMethodsContainer> = ({
  creditCards,
  paypalAccounts,
  submitButton,
  amountDue,
  existingSelectedPaymentMethod,
  paymentMethod,
  handleChange,
  processNonceTransaction,
  onTriggerPaypal,
  selectPaymentMethod
}) => {
  const { closeAllErrorSnackbars } = useContext(SnackbarContext);

  return (
    <Grid>
      <ExistingPaymentMethods
        checked={paymentMethod === 'existing'}
        onClick={() => {
          selectPaymentMethod('existing');
          closeAllErrorSnackbars();
        }}
        creditCards={creditCards}
        paypalAccounts={paypalAccounts}
        handleChange={handleChange}
        existingSelectedPaymentMethod={existingSelectedPaymentMethod}
      />
      <PaymentMethodSelector
        paypalType="checkout"
        onRadioSelect={(value: 'paypal' | 'creditCard') => {
          selectPaymentMethod(value);
        }}
        isAdditionalRadioSelected={paymentMethod === 'existing'}
        creditCardActionText={'Pay with another card'}
        paypalActionText={'Pay with new PayPal method'}
        savePaymentMutation={(nonce) => {
          if (paymentMethod === 'paypal') {
            onTriggerPaypal(nonce);
          } else {
            pushGAEvent({ id: 'pay-service-btn' });
            processNonceTransaction(nonce);
          }
        }}
        amountDue={amountDue}
        submitButtonRef={submitButton}
        payPalSubmitButtonRefOverride={submitButton}
        renderTerms={false}
      />
    </Grid>
  );
};

interface IExistingPaymentMethods {
  checked: boolean;
  onClick: () => void;
  creditCards: Maybe<CreditCard>[];
  paypalAccounts: Maybe<PayPalAccount>[];
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  existingSelectedPaymentMethod: string | undefined | null;
}

const ExistingPaymentMethods: FC<IExistingPaymentMethods> = ({
  checked,
  creditCards,
  paypalAccounts,
  onClick,
  handleChange,
  existingSelectedPaymentMethod
}) => {
  const creditCardCount = creditCards.length || 0;
  const paypalCount = paypalAccounts.length || 0;

  const getCreditCard = (value: Maybe<CreditCard>, index: number) =>
    value ? getExistingPaymentComponent(value, index) : <></>;

  const getPaypalAccount = (value: Maybe<PayPalAccount>, index: number) =>
    value ? getExistingPaymentComponent(value, index) : <></>;

  const getExistingPaymentComponent = (payment: PayPalAccount | CreditCard, index: number) => {
    if (payment) {
      const isCreditCard = payment?.hasOwnProperty('cardType');
      return (
        <MenuItem
          value={payment.token || ''}
          key={isCreditCard ? `cc-${index}` : `paypal-${index}`}
          style={{
            backgroundColor:
              existingSelectedPaymentMethod === payment.token ? palette.cement : palette.pvcWhite
          }}
        >
          <Grid item container direction="row" alignItems="center">
            <img
              src={payment.imageUrl!}
              style={{ width: 30, height: 19 }}
              alt={(payment as CreditCard).cardType || ''}
            />
            <P
              mui
              muiType={
                existingSelectedPaymentMethod !== payment.token ? 'body-bold' : 'body-2-large-bold'
              }
              styleOverride={{ paddingLeft: 20 }}
            >
              {isCreditCard ? (
                <>Ending in {(payment as CreditCard)?.last4}</>
              ) : (
                <>{(payment as PayPalAccount).email}</>
              )}
            </P>
          </Grid>
        </MenuItem>
      );
    }
  };

  return (
    <>
      <Grid>
        <P mui muiType="body-italic">
          Select how you’d like to pay
        </P>
      </Grid>
      <Grid direction="row" container alignItems="center">
        <ActionButton
          onClick={onClick}
          checked={checked}
          disabled={paypalCount + creditCardCount === 0}
          id=""
          styleOverride={{ padding: '12px 12px 12px 0px' }}
        />
        <Grid item xs>
          <Select
            id="payment-method-dropdown"
            value={existingSelectedPaymentMethod || 'No stored cards'}
            fullWidth
            disabled={paypalCount + creditCardCount === 0}
            style={{ padding: 0 }}
            onChange={handleChange}
            error={
              (creditCards.find((p) => p?.token === existingSelectedPaymentMethod) as CreditCard)
                ?.expired
                ? true
                : false
            }
            input={<CastleInput fullWidth />}
            MenuProps={{
              MenuListProps: {
                style: {
                  padding: 0
                }
              }
            }}
          >
            {creditCards.sort((a, _b) => (a && a?.default ? -1 : 1)).map(getCreditCard)}
            {paypalAccounts.sort((a, _b) => (a && a?.default ? -1 : 1)).map(getPaypalAccount)}
            {creditCardCount + paypalCount === 0 && (
              <MenuItem value="No stored cards" disabled>
                <P
                  mui
                  muiType="body-italic"
                  styleOverride={{ paddingLeft: 5, color: palette.concrete }}
                >
                  No stored cards
                </P>
              </MenuItem>
            )}
          </Select>
        </Grid>
      </Grid>
    </>
  );
};

const CastleInput = withStyles(() =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      border: `1px solid ${palette.marine}`,
      padding: '6px 26px 6px 12px',
      backgroundColor: palette.pvcWhite,
      '&:focus': {
        borderColor: palette.tarnishedGold,
        backgroundColor: palette.pvcWhite
      },
      '&:error': {
        borderColor: palette.electricalWire,
        backgroundColor: palette.pvcWhite
      }
    },
    disabled: {
      borderColor: palette.concrete,
      backgroundColor: palette.cement
    }
  })
)(InputBase);
