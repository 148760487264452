import React, { FC } from 'react';
import { DoubleSlash } from 'components';
import { Grid } from '@material-ui/core';
import { EstimatesBreadcrumbStyles } from './EstimatesBreadcrumbStyles';

interface IEstimatesBreadcrumb {
  variant: 'estimate' | 'confirm';
  plural?: boolean;
}

export const EstimatesBreadcrumb: FC<IEstimatesBreadcrumb> = ({ variant, plural }) => {
  const classes = EstimatesBreadcrumbStyles();

  return (
    <div
      className={`${classes.EstimatesBreadcrumbWrapper} ${
        variant === 'confirm' && classes.confirm
      }`}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-around"
        className={classes.EstimatesBreadcrumb}
      >
        <Grid item className={`${classes.navText} ${classes.navReview}`}>
          {`Review Estimate${plural ? 's' : ''}`}
        </Grid>
        <Grid item className={classes.navSlashes}>
          <DoubleSlash height={'18'} fill="#B6B2AB" />
        </Grid>
        <Grid item className={`${classes.navText} ${classes.navConfirm}`}>
          Confirmation
        </Grid>
      </Grid>
    </div>
  );
};
