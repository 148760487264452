import React, { ReactNode } from 'react';
import { palette } from 'styles/global';

interface INProps {
  children?: ReactNode;
  styleOverride?: Object;
}

export const N = ({ children, styleOverride }: INProps) => {
  return <p style={{ ...styles, ...styleOverride }}>{children}</p>;
};

const styles = {
  fontFamily: 'Assistant',
  fontWeight: 600,
  fontSize: 12,
  color: palette.coal,
  lineHeight: '16px',
  letterSpacing: '0.5px',
  margin: 0
};
