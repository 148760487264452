import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import { palette } from 'styles/global';

interface ILabelProps {
  children?: ReactNode;
  divider?: boolean;
  styleOverride?: Object;
  id?: string;
}

export const Label = ({ children, divider, styleOverride, id }: ILabelProps) => (
  <>
    <Typography id={id} style={{ ...styles.label, ...styleOverride }}>
      {children}
    </Typography>
    {divider && <div style={styles.divider} />}
  </>
);

const styles = {
  label: {
    color: palette.black,
    fontFamily: 'Assistant',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px'
  },
  divider: {
    border: '0.5px solid rgba(51, 51, 51, 0.4)',
    marginBottom: 8
  }
};
