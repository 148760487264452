import { Grid } from '@material-ui/core';

import { BraintreeHostedFields, CastleCheckBox, P, A, BraintreeClient } from 'components';
import { UserContext, SnackbarContext } from 'context';
import { BASE_URL } from 'data/env-vars';
import { SNACKBAR_OPTIONS_FUNCTION } from 'data/snackbar-options';
import React, { FC, useContext } from 'react';
import { palette } from 'styles/global';
import { pushGAEvent } from 'utils';

interface StepAddCreditCardProps {
  refSubmit: React.RefObject<HTMLButtonElement>;
  onRedeemNonce: (nonce: string) => Promise<void>;
  paymentTermsAccepted: boolean;
  setPaymentTermsAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StepAddCreditCard: FC<StepAddCreditCardProps> = (props) => {
  const { tokenUser } = useContext(UserContext);
  const { updateSnackbar, closeSnackbar } = useContext(SnackbarContext);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <BraintreeClient token={tokenUser.paymentToken}>
          <BraintreeHostedFields
            onError={(_message) =>
              updateSnackbar(
                SNACKBAR_OPTIONS_FUNCTION.braintreeError(
                  'Unable to add payment method. Please try again or call for assistance.'
                )
              )
            }
            redeemNonce={async (nonce) => await props.onRedeemNonce(nonce)}
            submitButton={props.refSubmit}
            onFocus={() => closeSnackbar(SNACKBAR_OPTIONS_FUNCTION.braintreeError('').key)}
          />
        </BraintreeClient>
      </Grid>
      <Grid item container spacing={1} wrap="nowrap">
        <Grid item>
          <CastleCheckBox
            id="accept-payment-terms-btn"
            checked={props.paymentTermsAccepted}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setPaymentTermsAccepted(e.target.checked);
              pushGAEvent({ id: 'accept-payment-terms-btn' });
            }}
          />
        </Grid>
        <Grid item>
          <P mui muiType="body">
            By checking this box, I authorize Castle to charge my payment method above for agreed
            upon purchases. I understand that I will be charged the assessment fee if a Castle
            representative provides an estimate for work, but I decide not to accept. I also agree
            to the terms and conditions of this agreement. View Payment Terms and Conditions.{' '}
            <A
              id="payment-terms-lbl"
              mui
              muiType="semi-bold"
              href={`${BASE_URL}/payment-terms`}
              target="_blank"
              styleOverride={{ color: palette.marine }}
              onClick={() => pushGAEvent({ id: 'payment-terms-lbl' })}
            >
              View Payment Terms and Conditions.
            </A>
          </P>
        </Grid>
      </Grid>
    </Grid>
  );
};
