import React, { FC, useEffect } from 'react';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import { navigate } from '@reach/router';

import { CardDashboard, withPaymentToken } from 'components';
import { DashboardEstimate } from 'core/interfaces';
import { BASE_URL } from 'data/env-vars';
import { palette } from 'styles/global';
import { pushDashboardEstimateCard, pushGAEvent } from 'utils';

interface CardEstimateDeclinedProps {
  id: string;
  estimate: DashboardEstimate;
}

export const CardEstimateDeclined: FC<CardEstimateDeclinedProps> = withPaymentToken(
  ({ id, estimate, paymentToken, getPaymentToken }) => {
    const cta = 'Pay Assessment Fee';
    const { serviceId, provider, description, subtotal, grossCost } = estimate;

    useEffect(() => pushDashboardEstimateCard({ ...estimate, cta }));

    useEffect(() => {
      if (paymentToken) {
        navigate(`${BASE_URL}/service-details/${serviceId}/pay`, { state: { token: paymentToken } });
      }
    }, [paymentToken, serviceId]);

    return (
      <CardDashboard
        id={id}
        backgroundColor={palette.white}
        textColor={palette.marine}
        provider={<i>{provider}</i>}
        description={description}
        subtotal={subtotal}
        grossCost={grossCost}
        action={{ status: 'Declined' }}
        button={{
          id: `pay-assessment-${serviceId}-btn`,
          Icon: AttachMoneySharpIcon,
          label: cta,
          onClick: (e) => {
            pushGAEvent({ id: e.currentTarget.id });
            getPaymentToken();
          }
        }}
      />
    );
  }
);
