import { DashboardService } from 'core/interfaces';
import { SERVICE_CATEGORIES, SERVICE_TYPES } from 'data/constants';
import { Page } from 'enums';
import {
  EstimateStatus,
  InvoiceStatus,
  Issue,
  ServiceCategory,
  ServiceStatus,
  ServiceType,
  User
} from 'generated/graphql';

type mapFn = (issue: Issue) => DashboardService[];

const validDashboardIssueServiceStatus = [
  ServiceStatus.EstimateAccepted,
  ServiceStatus.ServiceCompletedPendingPayment,
  ServiceStatus.CanceledPendingPayment
];

const mapDashboardIssueServices: mapFn = (issue: Issue): DashboardService[] => {
  if (!issue.projects || issue.projects.length === 0) return [];

  const projects = issue.projects;

  const result = projects!.flatMap((p) =>
    p
      ?.services!.filter((x) => !!x && validDashboardIssueServiceStatus.includes(x?.status))
      .map(
        (service): DashboardService => {
          const estimate = service!.estimates.find((x) => x.status === EstimateStatus.Accepted);
          const projectInvoice = p.invoices?.find(
            (invoice) => invoice?.status === InvoiceStatus.Active
          );
          const serviceInvoice = service?.invoices?.find(
            (invoice) => invoice?.status === InvoiceStatus.Active
          );

          return {
            serviceId: service?.id!,
            status: service?.status!,
            lastUpdated: service?.updatedAt,
            provider: service?.serviceProvider?.name || 'No Service Provider',
            technician: service?.technicianName || '',
            description: estimate?.description || issue.description,
            title: estimate?.title || issue.title,
            subtotal: projectInvoice?.subtotal || serviceInvoice?.subtotal,
            grossCost: projectInvoice?.grossCost || serviceInvoice?.grossCost
          };
        }
      )
  );

  return result as DashboardService[];
};

const validHistoryIssueServiceStatus = [
  ServiceStatus.WorkApprovedPaid,
  ServiceStatus.CanceledPaid,
  ServiceStatus.EstimateDeclinedPaid
];

const mapHistoryIssueServices: mapFn = (issue: Issue): DashboardService[] => {
  if (!issue.projects || issue.projects.length === 0) return [];

  if (issue.projects && issue.projects.length > 0) {
    const projects = issue.projects;

    const validServices = projects?.flatMap((p) =>
      p?.services?.filter(
        (x) =>
          !!x &&
          p.invoices?.length === 0 &&
          validHistoryIssueServiceStatus.includes(x.status) &&
          x.invoices &&
          x.invoices.length > 0 &&
          x.invoices.find((i) => i?.status === InvoiceStatus.Active) &&
          x.financialTransactions &&
          x.financialTransactions.length > 0
      )
    );

    if (!validServices || validServices.length === 0) return [];

    return validServices.map((service) => {
      const estimate = service!.estimates.find(
        (estimate) => estimate.status === EstimateStatus.Accepted
      );
      return {
        serviceId: service!.id,
        status: service!.status,
        lastUpdated: service!.updatedAt,
        provider: service!.serviceProvider?.name || 'No Service Provider',
        technician: service!.technicianName || '',
        description: `${
          service!.type === ServiceType.NewServiceOffering ||
          service!.type === ServiceType.SmallWork
            ? `${SERVICE_TYPES[service!.type]} / ${SERVICE_CATEGORIES[service!.category]} service`
            : `${SERVICE_TYPES[service!.type]} service`
        }`,
        title: estimate?.title || issue.title,
        subtotal: service?.invoices?.find((x) => x?.status === InvoiceStatus.Active)?.subtotal,
        grossCost: service?.invoices?.find((x) => x?.status === InvoiceStatus.Active)?.grossCost
      };
    });
  }
  return [];
};

export interface DashboardServiceProviderHistory {
  services: {
    type: ServiceType;
    category: ServiceCategory;
    lastUpdated: string;
    status: ServiceStatus;
  }[];
  projectId: string;
  serviceProviderName: string;
  serviceProviderId: string;
  subtotal?: number;
  grossCost?: number;
}

const mapHistoryIssueProjects = (issue: Issue): DashboardServiceProviderHistory[] => {
  if (!issue.projects || issue.projects.length === 0) return [];

  if (issue.projects && issue.projects.length > 0) {
    const validProjects = issue.projects?.filter(
      (x) =>
        !!x &&
        x.invoices &&
        x.invoices.length > 0 &&
        x.invoices.find((i) => i?.status === InvoiceStatus.Active) &&
        x.services?.every(
          (s) =>
            s?.status === ServiceStatus.CanceledPaid ||
            s?.status === ServiceStatus.EstimateDeclinedPaid
        )
    );

    if (!validProjects || validProjects.length === 0) return [];
    return validProjects.map((project) => {
      const services =
        project?.services?.map((s) => ({
          type: s!.type,
          category: s!.category,
          lastUpdated: s!.updatedAt,
          status: s!.status
        })) || [];
      const projectId = project?.id || '';
      const serviceProviderName =
        project?.services?.find((f) => f?.serviceProvider?.name)?.serviceProvider?.name || '';
      const serviceProviderId =
        project?.services?.find((f) => f?.serviceProvider?.id)?.serviceProvider?.id || '';
      const projectInvoice = project?.invoices?.find((f) => f?.status === InvoiceStatus.Active);
      const subtotal = projectInvoice?.subtotal || 0;
      const grossCost = projectInvoice?.grossCost || 0;

      return { services, projectId, serviceProviderName, serviceProviderId, subtotal, grossCost };
    });
  }
  return [];
};
const mapPageFn: Record<Page, mapFn> = {
  [Page.Dashboard]: mapDashboardIssueServices,
  [Page.History]: mapHistoryIssueServices
};

export const mapUserServices = (user: User, page: Page): DashboardService[] => {
  return user.issues
    ? user.issues
        .map((issue) => (issue ? mapPageFn[page](issue) : []))
        .reduce((p, c) => [...p, ...c])
    : [];
};

export const mapUserProjects = (user: User): DashboardServiceProviderHistory[] => {
  return user.issues
    ? user.issues
        .map((issue) => (issue ? mapHistoryIssueProjects(issue) : []))
        .reduce((p, c) => [...p, ...c])
    : [];
};
