import React, { FC, CSSProperties } from 'react';
import { palette } from 'styles/global';
import { Subtitle } from 'components';

interface RibbonProps {
  text: string;
  margin?: string;
}

export const LeftRibbon: FC<RibbonProps> = ({ text, margin }) => {
  return (
    <div style={{ ...styles.ribbon, margin }}>
      <Subtitle type="3" styleOverride={styles.ribbonText}>
        {text}
      </Subtitle>
      <div style={styles.ribbonAfter} />
    </div>
  );
};

const styles: Record<string, CSSProperties> = {
  ribbon: {
    position: 'relative',
    maxWidth: '165px',
    background: palette.oceanRibbon,
    borderRadius: '2px'
  },
  ribbonText: {
    margin: '8px 16px',
    color: palette.elm
  },
  ribbonAfter: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    border: 'calc(37px / 2) solid transparent',
    borderRight: `12px solid ${palette.white}`
  }
};
