import React, { FC, useEffect } from 'react';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import { navigate } from '@reach/router';

import { CardDashboard, withPaymentToken } from 'components';
import { DashboardService } from 'core/interfaces';
import { BASE_URL } from 'data/env-vars';
import { palette } from 'styles/global';
import { pushGAEvent } from 'utils';

interface CardServiceCompletedProps {
  id: string;
  service: DashboardService;
}

export const CardServiceCompleted: FC<CardServiceCompletedProps> = withPaymentToken(
  ({ id, service, paymentToken, getPaymentToken }) => {
    const { provider, serviceId,  subtotal, grossCost, title } = service;

    useEffect(() => {
      if (paymentToken) {
        navigate(`${BASE_URL}/service-details/${serviceId}/pay`, { state: { token: paymentToken } });
      }
    }, [paymentToken, serviceId]);

    return (
      <CardDashboard
        id={id}
        backgroundColor={palette.ultraMarine}
        textColor={palette.sawdust}
        provider={provider}
        description={title}
        subtotal={subtotal}
        grossCost={grossCost}
        button={{
          id: `pay-service-${serviceId}-btn`,
          Icon: AttachMoneySharpIcon,
          label: 'Pay for Service',
          onClick: (e) => {
            pushGAEvent({ id: e.currentTarget.id });
            getPaymentToken();
          }
        }}
      />
    );
  }
);
