import React, { FC, useContext } from 'react';
import { Grid } from '@material-ui/core';

import { UserContext } from 'context';
import { P, TermsCheckbox } from 'components';
import { addPhoneFormatting, pushGAEvent } from 'utils';

interface StepVerifyInfoTerms {
  termsAccepted: boolean;
  setTermsAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StepVerifyInfoTerms: FC<StepVerifyInfoTerms> = props => {
  const { tokenUser } = useContext(UserContext);

  const { firstName, lastName, phone, email, addresses } = tokenUser;
  const primaryAddress = addresses.find(address => address.primary);
  const { street1, street2, city, state, zip } = primaryAddress || {};

  const formattedPhone = addPhoneFormatting(phone);

  return (
    <Grid item container direction="column" spacing={3}>
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <P mui muiType="body-bold">
            Your Name and Address
          </P>
          <P id="full-name" mui muiType="body-2-large">
            {firstName + ' ' + lastName}
          </P>
          {!!primaryAddress && (
            <>
              <P id="street-address-1" mui muiType="body-2-large">
                {street1}
              </P>
              {street2 && (
                <P id="street-address-2" mui muiType="body-2-large">
                  {street2}
                </P>
              )}
              <P id="city-state-zip" mui muiType="body-2-large">
                {city + ', ' + state + ' ' + zip}
              </P>
            </>
          )}
        </Grid>
        <Grid item>
          <P mui muiType="body-bold">
            Phone
          </P>
          <P id="phone" mui muiType="body-2-large">
            {formattedPhone}
          </P>
        </Grid>
        <Grid item>
          <P mui muiType="body-bold">
            Email
          </P>
          <P id="email" mui muiType="body-2-large">
            {email}
          </P>
        </Grid>
      </Grid>
      <Grid item>
        <TermsCheckbox
          checked={props.termsAccepted}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.setTermsAccepted(e.target.checked);
            pushGAEvent({ id: 'accept-terms-btn' });
          }}
        />
      </Grid>
    </Grid>
  );
};
