import { Button, Drawer, IconButton } from '@material-ui/core';
import React, { FC, useLayoutEffect, useState } from 'react';
import { CloseSharp, InfoOutlined } from '@material-ui/icons';

import { palette } from 'styles/global';
import { SlashDivider } from 'components/Shared';
import {
  WhatToExpectModalOverrideStyles,
  WhatToExpectModalStyles
} from './WhatToExpectModalStyles';
import { pushGAEvent } from 'utils';

interface IWhatToExpectModal {
  variant: string;
  location: string;
}
export const WhatToExpectModal: FC<IWhatToExpectModal> = ({ variant, location, children }) => {
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(0);
  const classes = WhatToExpectModalStyles();
  const classesOverride = WhatToExpectModalOverrideStyles({ width });

  // This will re-render the app when the window width changes
  useLayoutEffect(() => {
    const updateSize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
          pushGAEvent({
            id: `${location}-view-expect-from-castle-lnk`,
            type: 'link'
          });
        }}
        startIcon={<InfoOutlined />}
        className={variant === 'clear' ? classes.openLink : classes.openLinkFilled}
      >
        What to expect using castle
      </Button>
      {variant === 'clear' && (
        <SlashDivider style={{ width: 'calc(100% - 30px)', margin: '0 auto' }} />
      )}
      <Drawer
        classes={classesOverride}
        anchor="right"
        open={open}
        elevation={10}
        PaperProps={{
          elevation: 6,
          style: {
            height: '100%',
            width: 'calc(100% - 20px)',
            maxWidth: '475px',
            zIndex: 12,
            backgroundColor: palette.pvcWhite
          }
        }}
        SlideProps={{
          appear: open
        }}
      >
        <IconButton
          size="small"
          aria-label="close menu"
          id="close-expect-from-castle-lnk"
          onClick={() => {
            pushGAEvent({
              id: `${location}-close-expect-from-castle-lnk`,
              type: 'link'
            });
            setOpen(false);
          }}
          style={{
            color: palette.caribbean,
            position: 'absolute',
            right: 18,
            top: 17,
            fontSize: 26
          }}
        >
          <CloseSharp />
        </IconButton>
        {children}
      </Drawer>
    </>
  );
};
