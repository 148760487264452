import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box } from '@material-ui/core';

import { StickyHeader, SuspenseLoader, Banner, StickyFooter } from 'components';

import { HeaderAction } from 'data/constants';

interface IPublicRouteProps extends RouteComponentProps {
  default?: boolean;
  path?: string;
  headerAction?: HeaderAction;
  showHeader?: boolean;
  showStickyFooter?: boolean;
  component: FC<any>;
  backRoute?: string;
  backButtonId?: string;
  headerJustify?: headerJustifyType | 'center';
}
type headerJustifyType = 'center' | 'flex-end' | 'flex-start' | 'space-between';

export const PublicRoute: FC<IPublicRouteProps> = ({
  component: Component,
  headerAction = HeaderAction.NONE,
  headerJustify,
  showHeader = false,
  showStickyFooter = true,
  backRoute,
  backButtonId,
  ...rest
}) => {
  return (
    <Box>
      {showHeader && (
        <StickyHeader
          justify={headerJustify}
          action={headerAction}
          backRoute={backRoute}
          backButtonId={backButtonId}
        />
      )}
      <Banner />
      <SuspenseLoader>
        <Component {...rest} />
      </SuspenseLoader>
      {showStickyFooter && <StickyFooter />}
    </Box>
  );
};
