import React from 'react';
import { Grid } from '@material-ui/core';
import { H3, P } from 'components';
import { formatDate } from 'core/utils';
import { palette } from 'styles/global';

interface ILegalProps {
  content: {
    title: string;
    date: string;
    copy: ISectionProps[];
    alternateDescription?: JSX.Element;
  };
}

export const Legal = ({ content }: ILegalProps) => {
  const { title, date, copy } = content;
  const description = content.alternateDescription
    ? content.alternateDescription
    : `Last updated: ${formatDate(date, 'MMM D, YYYY')}`;

  const mappedSections = copy.map((section, idx) => (
    <Section title={section.title} content={section.content} key={idx} />
  ));

  return (
    <Grid container style={{ padding: '32px 18px 12px' }}>
      <Grid item xs={12}>
        <H3
          mui
          styleOverride={{
            color: palette.marine,
            paddingBottom: '16px',
            fontSize: 30,
            margin: 0
          }}
        >
          {title}
        </H3>
      </Grid>
      <Grid item container xs={12}>
        <P
          mui
          muiType="sub-header"
          styleOverride={{ color: palette.caribbean, paddingBottom: '32px' }}
        >
          {description}
        </P>
      </Grid>
      <Grid item xs={12} style={{ fontSize: 14, color: palette.marine }}>
        {mappedSections}
      </Grid>
    </Grid>
  );
};

interface ISectionProps {
  title?: string | JSX.Element;
  content: JSX.Element;
}

const Section = ({ title, content }: ISectionProps) => {
  return (
    <Grid container>
      <Grid item>
        {title && (
          <H3
            styleOverride={{
              color: palette.caribbean,
              marginBottom: '20px',
              fontSize: 14,
              fontWeight: 'bold'
            }}
          >
            {title}
          </H3>
        )}
      </Grid>
      <Grid item container style={{ marginBottom: '20px' }}>
        {content}
      </Grid>
    </Grid>
  );
};
