import React, { FC } from 'react';
import { Card, CardContent } from '@material-ui/core';

import { MUIButton, EstimateCardLayoutContainer, Title, Total } from 'components';
import { palette } from 'styles/global';
interface ITotalPaymentContainer {
  disabled?: boolean;
  total: number;
  onClick: () => void;
  id?: string;
  titleOverride?: string;
  buttonRef?: React.Ref<HTMLButtonElement>;
  useOnClickButton: boolean;
}

export const TotalPaymentContainer: FC<ITotalPaymentContainer> = ({
  disabled,
  total,
  onClick,
  id,
  titleOverride,
  buttonRef,
  useOnClickButton = false
}) => (
  <Card elevation={0}>
    <CardContent style={{ padding: '30px 0px 0px 0px', backgroundColor: palette.pvcWhite }}>
      <CardContent style={{ padding: '5px 0px', marginLeft: 'auto', marginRight: 'auto' }}>
        <EstimateCardLayoutContainer
          title={<Title styleOverride={{ padding: 0 }} title="Total" id="total-estimate-title" />}
          total={<Total styleOverride={{ padding: 0 }} total={total} id="amount-requested" />}
        />
        <EstimateCardLayoutContainer>
          {useOnClickButton ? (
            <MUIButton
              label={titleOverride || 'Pay'}
              disabled={disabled}
              onClick={onClick}
              id={id}
              fullWidth
            />
          ) : (
            <MUIButton
              label={titleOverride || 'Pay'}
              disabled={disabled}
              id={id}
              fullWidth
              ref={buttonRef}
            />
          )}
        </EstimateCardLayoutContainer>
      </CardContent>
    </CardContent>
  </Card>
);
