import React, { FC, useContext } from 'react';
import { useLazyQuery, ApolloError } from '@apollo/client';

import { CastleBackdrop } from 'components';
import { SnackbarContext } from 'context';
import { SNACKBAR_OPTIONS_FUNCTION } from 'data/snackbar-options';
import { Query } from 'generated/graphql';
import { GET_PAYMENT_TOKEN } from 'graphql/queries';

export interface WithPaymentTokenProps {
  getPaymentToken: () => void;
  paymentToken: string | undefined;
  paymentTokenError: ApolloError | undefined;
}

export const withPaymentToken = <T extends object>(
  Component: FC<T & WithPaymentTokenProps>
): FC<T> => props => {
  const { updateSnackbar } = useContext(SnackbarContext);

  const [getPaymentToken, { data, loading, error }] = useLazyQuery<Query>(GET_PAYMENT_TOKEN, {
    fetchPolicy: 'no-cache',
    onError: () => {
      updateSnackbar(SNACKBAR_OPTIONS_FUNCTION.processPaymentFail());
    }
  });

  return (
    <>
      <Component
        {...props}
        getPaymentToken={getPaymentToken}
        paymentToken={data?.getPaymentToken}
        paymentTokenError={error}
      />
      <CastleBackdrop open={loading} />
    </>
  );
};
