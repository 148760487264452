import { Snackbar } from 'context';

type SnackbarFN = (...x: any) => Snackbar;

type SnackbarTypeFN =
  | 'acceptEstimateFail'
  | 'processPaymentFail'
  | 'passwordResetFail'
  | 'braintreeError'
  | 'savePaymentMethodFail'
  | 'deleteSetPrimaryPaymentMethodFail'
  | 'estimateActions';

export const SNACKBAR_OPTIONS_FUNCTION: Record<SnackbarTypeFN, SnackbarFN> = {
  acceptEstimateFail: (error: string) => ({
    type: 'error',
    message: 'Unable to accept estimate',
    description: error || 'System Error',
    key: 'acceptEstimateFail'
  }),
  processPaymentFail: () => ({
    type: 'error',
    message: 'Unable to process payment.',
    description: 'Please try again or call for assistance.',
    key: 'processPaymentFail'
  }),
  savePaymentMethodFail: (error: string) => ({
    type: 'error',
    message: 'Unable to save payment method',
    description: error || 'System Error',
    key: 'savePaymentMethodFail'
  }),
  deleteSetPrimaryPaymentMethodFail: (error: string) => ({
    type: 'error',
    message: error,
    key: 'deleteSetPrimaryPaymentMethodFail'
  }),
  passwordResetFail: (error: string) => ({
    type: 'error',
    message: 'There was an issue resetting your password',
    description: error,
    key: 'passwordResetFail'
  }),
  estimateActions: (
    estimateActions: 'allAccepted' | 'allDeclined' | 'mix' | 'someDeclined',
    plural: boolean,
    isZeroDollar: boolean,
    promotionTitle?: string
  ) => {
    let data = {
      message: 'Confirmed',
      description: `You have accepted and declined estimates. Please contact Castle if you have any questions.`,
      key: 'mixEstimatesConfirmed'
    };
    switch (estimateActions) {
      case 'allAccepted':
        data = {
          message: `Service${plural ? 's' : ''} Confirmed`,
          description: `You have accepted your estimate${
            plural ? 's' : ''
          }. Please contact Castle if you have any questions.`,
          key: 'allEstimatesConfirmed'
        };
        break;
      case 'allDeclined':
        if (isZeroDollar) {
          data = {
            message: `Service${plural ? 's' : ''} Declined`,
            description: `You've got a ${
              promotionTitle || 'promotion'
            } discount, so your assessment fee is waived.`,
            key: 'allEstimatesDeclinedZeroDollar'
          };
        } else {
          data = {
            message: `Service${plural ? 's' : ''} Declined`,
            description: 'Please pay your in person assessment fee.',
            key: 'allEstimatesDeclined'
          };
        }
        break;
      case 'someDeclined':
        data = {
          message: `Confirmed`,
          description:
            'You have declined your estimates. Please contact Castle if you have any questions.',
          key: 'someEstimatesDeclined'
        };
        break;
    }
    return {
      type: 'success',
      ...data
    };
  },
  braintreeError: (error: string) => ({ type: 'error', message: error, key: 'braintreeError' })
};

type SnackbarType =
  | 'accountCreated'
  | 'passwordCreated'
  | 'confirmService'
  | 'declineEstimate'
  | 'receiptSent'
  | 'invalidEmail'
  | 'loginError'
  | 'invalidToken'
  | 'invalidPassword'
  | 'invalidVerificationCode'
  | 'tcNotChecked'
  | 'apiError'
  | 'declineEstimateReasonRequired'
  | 'successfulPayment'
  | 'invalidPaymentMethod'
  | 'expiredCard'
  | 'invalidCreditCard'
  | 'paymentMethodAdded'
  | 'primaryPaymentMethod'
  | 'resendVerificationError'
  | 'deletePaymentMethod';

export const SNACKBAR_OPTIONS: Record<SnackbarType, Snackbar> = {
  accountCreated: {
    type: 'success',
    message: 'Account Successfully Created',
    description: 'Log in to access your service details.',
    key: 'accountCreated'
  },
  passwordCreated: {
    type: 'success',
    message: 'Password Successfully Created',
    description: 'Log in to access your service details.',
    key: 'passwordCreated'
  },
  confirmService: {
    type: 'success',
    message: 'Service Confirmed',
    description:
      'Your estimate has been accepted. Please contact Castle if you have any questions.',
    key: 'confirmService'
  },
  declineEstimate: {
    type: 'success',
    message: 'Service Declined',
    description: 'Please pay your in-person assessment fee',
    key: 'declineEstimate'
  },
  receiptSent: {
    type: 'success',
    message: 'Receipt Sent',
    description: 'Please check your email.',
    key: 'receiptSent'
  },
  invalidEmail: {
    type: 'error',
    message: 'Receipt cannot be sent',
    description: 'Please check your email address below.',
    key: 'invalidEmail'
  },
  loginError: {
    type: 'error',
    message: 'Login Error',
    description: 'Incorrect phone number or password',
    key: 'loginError'
  },
  invalidToken: {
    type: 'error',
    message: 'There was an issue setting your password',
    description: 'Please try again or contact Castle.',
    key: 'invalidToken'
  },
  invalidCreditCard: {
    type: 'error',
    message: 'Unable to process payment.',
    description: 'Please try again or call for assistance.',
    key: 'invalidCreditCard'
  },
  resendVerificationError: {
    type: 'error',
    message: 'Unable to resend verification code.',
    description: 'Please try again.',
    key: 'resendVerificationError'
  },
  invalidPassword: {
    type: 'error',
    message: 'Password Invalid',
    description: `Please verify you've fulfilled all requirements.`,
    key: 'invalidPassword'
  },
  invalidVerificationCode: {
    type: 'error',
    message: 'Verification Code Invalid',
    description: `Please verify you've fulfilled all requirements.`,
    key: 'invalidVerificationCode'
  },
  tcNotChecked: {
    type: 'error',
    message: 'Form is incomplete.',
    description: `Please agree to Terms & Conditions to continue.`,
    key: 'tcNotChecked'
  },
  apiError: {
    type: 'error',
    message: 'There was a server error.',
    description: `Please try again or contact Castle if you are still having issues.`,
    key: 'apiError'
  },
  declineEstimateReasonRequired: {
    type: 'error',
    message: 'Missing Information',
    description: 'Please select a reason for declining.',
    key: 'declineEstimateReasonRequired'
  },
  successfulPayment: {
    type: 'success',
    message: 'Payment confirmed. Thank you.',
    key: 'successfulPayment'
  },
  invalidPaymentMethod: {
    type: 'error',
    message: 'Unable to process payment.',
    description: 'Please try again or call for assistance.',
    key: 'invalidPaymentMethod'
  },
  expiredCard: {
    message: 'This card is expired. Please select a different payment method.',
    type: 'error',
    key: 'expiredCard'
  },
  paymentMethodAdded: {
    type: 'success',
    message: 'Your payment method was added.',
    key: 'paymentMethodAdded'
  },
  primaryPaymentMethod: {
    type: 'success',
    message: 'Your payment method was set to primary.',
    key: 'primaryPaymentMethod'
  },
  deletePaymentMethod: {
    type: 'success',
    message: 'Your payment method was deleted.',
    key: 'deletePaymentMethod'
  }
};
