import React, { FC } from 'react';
import { Button, MobileStepper } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { pushGAEvent } from 'utils';

interface IEstimatesNav {
  maxSteps: number;
  activeStep: number;
  classes: any;
  handleNext: () => void;
  handlePrev: () => void;
}

export const EstimatesNav: FC<IEstimatesNav> = ({
  maxSteps,
  activeStep,
  classes,
  handleNext,
  handlePrev
}) => (
  <MobileStepper
    steps={maxSteps}
    position="static"
    variant="text"
    activeStep={activeStep}
    classes={{
      root: classes.mobileStepperRoot
    }}
    nextButton={
      <Button
        size="small"
        onClick={() => {
          pushGAEvent({ id: 'estimate-next-btn', type: 'button' });
          handleNext();
        }}
        disabled={activeStep === maxSteps - 1}
        classes={{
          root: classes.buttonRoot
        }}
      >
        Next
        <KeyboardArrowRight />
      </Button>
    }
    backButton={
      <Button
        size="small"
        onClick={() => {
          pushGAEvent({ id: 'estimate-previous-btn', type: 'button' });
          handlePrev();
        }}
        disabled={activeStep === 0}
        classes={{
          root: classes.buttonRoot
        }}
      >
        <KeyboardArrowLeft />
        Previous
      </Button>
    }
  />
);
