import React, { FC } from 'react';
import { Checkbox, withStyles, CheckboxProps } from '@material-ui/core';
import { palette } from 'styles/global';

interface ICastleCheckBox {
  id?: string;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ariaLabel?: string;
}

const CustomCheckbox = withStyles({
  root: {
    color: palette.marine,
    padding: 0,
    '&$checked': {
      color: palette.tarnishedGold
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox {...props} />);

export const CastleCheckBox: FC<ICastleCheckBox> = ({
  id,
  checked,
  onChange,
  ariaLabel = 'Terms Checkbox'
}) => (
  <CustomCheckbox
    id={id}
    checked={checked}
    onChange={onChange}
    inputProps={{ 'aria-label': ariaLabel }}
  />
);
