import React, { FC } from 'react';
import { Text, SlashDivider, DoubleSlash } from 'components/Shared';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Link } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { WhatToExpectModalContentStyles } from './WhatToExpectModalContentStyles';
import { palette } from 'styles/global';
import { SupportNumber } from 'enums';
import { addPhoneFormatting, pushGAEvent } from 'utils';
import SecuredByPayPal from 'assets/svg/Paypal_Logo_Blue.svg';

interface IWhatToExpectModalContent {
  location: string;
}
export const WhatToExpectModalContent: FC<IWhatToExpectModalContent> = ({ location }) => {
  const classes = WhatToExpectModalContentStyles();
  const [expanded, setExpanded] = React.useState<string | false>(
    location === 'dashboard' ? 'panel1' : 'panel2'
  );
  const handleChange = (panel: string) => (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const phoneNumber = SupportNumber.DEFAULT;

  return (
    <>
      <Box className={classes.title}>
        <div className={classes.titleSlashes}>
          <DoubleSlash height={'20'} />
        </div>
        <Text element="h1" customClass="headline-4-b">
          What to expect for your <span style={{ whiteSpace: 'nowrap' }}>Castle Experience</span>
        </Text>
      </Box>
      <Box className={classes.accordions}>
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          classes={{
            root: classes.MuiAccordionroot
          }}
        >
          <AccordionSummary
            className={classes.accordionSum}
            expandIcon={<ExpandMore className={classes.expandIcon} />}
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.MuiAccordionroot
            }}
          >
            <Text element="h2" customClass="body">
              Appointments
            </Text>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDet}>
            <Text customClass="body-lg">
              You will receive a text from Castle when your appointment is scheduled. Prior to the
              scheduled appointment, clear the repair area of any clutter.
            </Text>
          </AccordionDetails>
        </Accordion>
        <SlashDivider />
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          classes={{
            root: classes.MuiAccordionroot
          }}
        >
          <AccordionSummary
            className={classes.accordionSum}
            expandIcon={<ExpandMore className={classes.expandIcon} />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Text element="h2" customClass="body">
              Estimates
            </Text>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDet}>
            <Text customClass="body-lg">
              You will receive a text from Castle when your detailed service estimate is ready to
              view. Your estimate provides a detailed breakdown of labor, parts, taxes, and
              discounts. The estimate is sent to Castle, and we double check that it aligns with
              fair market rates.
            </Text>

            <Text customClass="body-lg">
              Available options are separated into individual estimates. You will be asked to
              confirm that you are accepting or declining each estimate. The $75 assessment fee is
              waived when you accept an estimate from any single service provider.
            </Text>
            <Text customClass="body-lg">
              <strong>Accept</strong>—Accepting an estimate means you approve of the cost and want
              to move forward with scheduling the service.
            </Text>
            <Text customClass="body-lg">
              <strong>Decline</strong>—Declining an estimate means you do not wish to move forward
              with the service.
            </Text>
            <Text customClass="body-lg">
              <strong>Skip this estimate</strong>—Skipping an estimate means you wish to take no
              action at this time. You can return to available estimates later.
            </Text>
          </AccordionDetails>
        </Accordion>
        <SlashDivider />
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          classes={{
            root: classes.MuiAccordionroot
          }}
        >
          <AccordionSummary
            className={classes.accordionSum}
            expandIcon={<ExpandMore className={classes.expandIcon} />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Text element="h2" customClass="body">
              Services
            </Text>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDet}>
            <Text customClass="body-lg">
              <strong>Immediate repairs</strong>—Some work, like small repairs or emergency service,
              can start right away while the service provider is at your home and if materials are
              available.
            </Text>
            <Text customClass="body-lg">
              <strong>Scheduled repairs</strong>—For more extensive repairs or services a Castle
              Expert will call you within 48 hours to coordinate a time for the service provider to
              start work.
            </Text>
            <Text customClass="body-lg">
              <strong>Changes to scheduling</strong>—You can always contact your Castle Expert to
              change dates and times if something comes up.
            </Text>
          </AccordionDetails>
        </Accordion>
        <SlashDivider />
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          classes={{
            root: classes.MuiAccordionroot
          }}
        >
          <AccordionSummary
            className={classes.accordionSum}
            expandIcon={<ExpandMore className={classes.expandIcon} />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Text element="h2" customClass="body">
              Payment
            </Text>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDet}>
            <Text customClass="body-lg">
              Adding a valid payment method is required to approve estimates and begin service. Your
              payment method is encrypted for secure storage.
            </Text>
            <Text customClass="body-lg">
              Castle accepts most major credit and debit cards, as well as PayPal. When your project
              is complete, you can pay through the Castle app with a single click using your stored
              information or you can pay with a different payment method.
            </Text>

            <Grid container style={{ paddingTop: 25 }}>
              <Grid item style={{ paddingRight: 10 }}>
                <Text customClass="body-lg" style={{ fontStyle: 'italic' }}>
                  Secured by
                </Text>
              </Grid>
              <Grid item>
                <a
                  href="https://www.paypal.com/webapps/mpp/paypal-popup"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="How PayPal Works"
                >
                  <img src={SecuredByPayPal} alt="PayPal Logo" />
                </a>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <SlashDivider />
        <Text customClass="overline-b" style={{ padding: '32px 0 5px' }}>
          Still have questions?
        </Text>
        <div style={{ display: 'flex' }}>
          <Text customClass="body-lg">Call your Castle Expert at</Text>
          <Link
            href={`tel:${phoneNumber}`}
            onClick={() => {
              pushGAEvent({ id: `call-castle-what-to-expect-${location}-lnk`, type: 'link' });
            }}
            style={{ padding: '0 0 80px 8px' }}
          >
            <Text customClass="body-lg" style={{ color: palette.caribbean, fontWeight: 700 }}>
              {addPhoneFormatting(phoneNumber)}
            </Text>
          </Link>
        </div>
      </Box>
    </>
  );
};
