import React, { useState, useContext } from 'react';
import { navigate } from '@reach/router';
import { AppBar, Grid, IconButton, Box, Button, Zoom } from '@material-ui/core';
import { ChevronLeftSharp, Chat, Phone } from '@material-ui/icons';

import { UserMenu, FixedContainer } from 'components';
import { sticky } from 'core/styles';
import { ID, HeaderAction } from 'data/constants';
import { pushGAEvent } from 'utils';
import { ReactComponent as CastleLogo } from 'assets/svg/Castle_Logo_marine.svg';
import { ReactComponent as ContactUsLogo } from 'assets/svg/contact_us.svg';
import { ReactComponent as Menu } from 'assets/icons/icon_menu.svg';
import { palette } from 'styles/global';
import { BASE_URL } from 'data/env-vars';
import { Text } from 'components/Shared';
import { StickyHeaderStyles } from './StickyHeaderStyles';
import { UserContext } from 'context';
import { MarketTextingSupportNumber, SupportNumber } from 'enums';

interface IStickyHeaderProps {
  action?: HeaderAction;
  backRoute?: string;
  backButtonId?: string;
  justify?: 'center' | 'flex-end' | 'flex-start' | 'space-between';
}

const phoneNumber = SupportNumber.DEFAULT;
const textNumbers: Record<string, string> = {
  tampa: MarketTextingSupportNumber.TAMPA,
  austin: MarketTextingSupportNumber.AUSTIN,
  cleveland: MarketTextingSupportNumber.CLEVELAND,
  default: MarketTextingSupportNumber.DEFAULT
};

export const StickyHeader = ({
  action = HeaderAction.MENU,
  backRoute,
  backButtonId,
  justify = 'space-between'
}: IStickyHeaderProps) => {
  const { user } = useContext(UserContext);
  const classes = StickyHeaderStyles();

  const [showMenu, toggleMenu] = useState(false);
  const [contactUsMenuOpen, setContactUsMenuOpen] = useState(false);
  const handleContactUs = () => {
    setContactUsMenuOpen(!contactUsMenuOpen);
  };

  const primaryMarket = user?.addresses?.find((address) => address.primary)?.market?.toLowerCase();
  const textNumber =
    primaryMarket && textNumbers[primaryMarket] ? textNumbers[primaryMarket] : textNumbers.default;

  const Action = ({ ghostOffset = false }: { ghostOffset?: boolean }) => {
    switch (action) {
      case HeaderAction.MENU:
        return ghostOffset ? (
          <Menu strokeWidth={0} style={{ visibility: 'hidden' }} />
        ) : (
          <Box display="flex" alignItems="center">
            <IconButton
              id="menu-open-btn"
              aria-label="menu"
              onClick={() => {
                pushGAEvent({ id: 'menu-open-btn' });
                toggleMenu(!showMenu);
              }}
              style={styles.button}
            >
              <Menu aria-hidden="true" />
            </IconButton>
            <CastleLogo />
          </Box>
        );

      case HeaderAction.BACK:
        return ghostOffset ? (
          <ChevronLeftSharp strokeWidth={0} style={{ visibility: 'hidden' }} />
        ) : (
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="back"
              id={backButtonId}
              onClick={() => {
                pushGAEvent({ id: backButtonId || '' });
                backRoute ? navigate(`${BASE_URL}${backRoute}`) : window.history.back();
              }}
              style={styles.button}
            >
              <ChevronLeftSharp aria-hidden="true" style={{ color: palette.white }} />
            </IconButton>
            <CastleLogo />
          </Box>
        );

      case HeaderAction.NONE:
      default:
        return null;
    }
  };

  return (
    <FixedContainer fixedId={ID.CASTLE_HEADER}>
      <AppBar style={{ ...sticky, top: 0 }} id={ID.CASTLE_HEADER} elevation={0} color="primary">
        <Grid
          container
          direction="row"
          justify={justify}
          alignItems="center"
          className={classes.appBarGrid}
        >
          <Action />
          <Button
            style={{
              height: 34,
              backgroundColor: contactUsMenuOpen ? palette.caribbeanTransparent : 'transparent'
            }}
            aria-label="Click to open contact menu"
            onClick={() => {
              pushGAEvent({ id: contactUsMenuOpen ? 'contact-us-close' : 'contact-us-open' });
              handleContactUs();
            }}
            onBlur={() => {
              handleContactUs();
            }}
            id="contactUsTarget"
          >
            <ContactUsLogo />
            <Text customClass="body" additionalClasses="contactUsText">
              Contact Us
            </Text>
          </Button>
        </Grid>

        <Box
          display="flex"
          flexDirection="column"
          position="absolute"
          style={{
            height: contactUsMenuOpen ? 'auto' : 0
          }}
          className={classes.floatingMenuRoot}
        >
          <Zoom in={contactUsMenuOpen}>
            <Button
              aria-label="Click to open SMS message with Castle"
              href={`sms:${textNumber}`}
              onClick={() => {
                pushGAEvent({ id: 'text-header' });
              }}
              className={classes.contactUsMenuButton}
            >
              <Chat style={{ fill: palette.pvcWhite }} />
              <Text customClass="body-sm-b" additionalClasses="contactUsMenuButtonText">
                Text
              </Text>
            </Button>
          </Zoom>
          <Zoom in={contactUsMenuOpen}>
            <Button
              style={{ marginTop: 10 }}
              aria-label="Click to start call with Castle"
              href={`tel:${phoneNumber}`}
              onClick={() => {
                pushGAEvent({ id: 'call-header' });
              }}
              className={classes.contactUsMenuButton}
            >
              <Phone style={{ fill: palette.pvcWhite }} />
              <Text customClass="body-sm-b" additionalClasses="contactUsMenuButtonText">
                Call
              </Text>
            </Button>
          </Zoom>
        </Box>
      </AppBar>
      <UserMenu toggleMenu={toggleMenu} showMenu={showMenu} />
    </FixedContainer>
  );
};

const styles = {
  button: {
    padding: 0,
    marginRight: 10
  }
};
