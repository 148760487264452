import React, { FC } from 'react';
import { Button, Grid, InputAdornment } from '@material-ui/core';

import { P, InputText } from 'components';

interface StepSetPasswordProps {
  newPassword: string;
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
  confirmPassword: string;
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;

  showNewPassword: boolean;
  setShowNewPassword: React.Dispatch<React.SetStateAction<boolean>>;
  showConfirmPassword: boolean;
  setShowConfirmPassword: React.Dispatch<React.SetStateAction<boolean>>;

  isPasswordValid: boolean;
  setPasswordValidity: React.Dispatch<React.SetStateAction<boolean>>;
  doesPasswordMatch: boolean;
  setDoesPasswordMatch: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StepSetPassword: FC<StepSetPasswordProps> = props => {
  const {
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    showNewPassword,
    setShowNewPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    isPasswordValid,
    setPasswordValidity,
    doesPasswordMatch,
    setDoesPasswordMatch
  } = props;

  const validatePassword = (password: string) => {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    const isPasswordValid = regex.test(password);
    setPasswordValidity(isPasswordValid);
  };

  const onChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewPassword(value);
    validatePassword(value);
    if (!confirmPassword) return;
    setDoesPasswordMatch(value === confirmPassword);
  };

  const onChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmPassword(value);
    setDoesPasswordMatch(value === newPassword);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item id="password-requirements">
        <P mui muiType="body-bold">
          Your new password must include:
        </P>
        <P mui muiType="caption-body">
          - at least 8 characters
        </P>
        <P mui muiType="caption-body">
          - at least 1 number
        </P>
        <P mui muiType="caption-body">
          - at least 1 character is a special character
        </P>
        <P mui muiType="caption-body">
          - at least 1 character is an uppercase letter
        </P>
        <P mui muiType="caption-body">
          - at least 1 character is a lowercase letter
        </P>
      </Grid>
      <Grid item>
        <InputText
          id="create-password"
          label="New Password"
          type={showNewPassword ? 'text' : 'password'}
          fullWidth
          error={!isPasswordValid}
          helperText={!isPasswordValid && 'Your password does not meet requirements.'}
          FormHelperTextProps={{ id: 'error-create-password' }}
          defaultValue={newPassword}
          onChange={onChangeNewPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={() => setShowNewPassword(!showNewPassword)}>
                  <P mui muiType="caption-body-bold">
                    {showNewPassword ? 'HIDE' : 'SHOW'}
                  </P>
                </Button>
              </InputAdornment>
            )
          }}
        ></InputText>
      </Grid>
      <Grid item>
        <InputText
          id="confirm-password"
          label="Confirm Password"
          type={showConfirmPassword ? 'text' : 'password'}
          fullWidth
          error={!doesPasswordMatch}
          helperText={!doesPasswordMatch && 'Your passwords do not match.'}
          FormHelperTextProps={{ id: 'error-confirm-password' }}
          defaultValue={confirmPassword}
          onChange={onChangeConfirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  <P mui muiType="caption-body-bold">
                    {showConfirmPassword ? 'HIDE' : 'SHOW'}
                  </P>
                </Button>
              </InputAdornment>
            )
          }}
        ></InputText>
      </Grid>
    </Grid>
  );
};
