import React, { FC, useContext } from 'react';
import { Snackbar, IconButton, Grid, Paper } from '@material-ui/core';
import { CloseSharp, DoneSharp, PriorityHighSharp } from '@material-ui/icons';

import { palette } from 'styles/global';
import { P } from 'components/TextStyles/P';
import { SnackbarContext } from 'context';

export const CastleSnackbar: FC = () => {
  const { snackbars, closeSnackbar } = useContext(SnackbarContext);
  const getTopMargin = (index: number) => (index === 0 ? 50 : 50 + index * (70 + 10));
  return (
    <>
      {snackbars.map((snackbar, index) => (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          key={`snackbar-${index}`}
          open={!!snackbar}
          style={{ marginTop: getTopMargin(index) }}
          autoHideDuration={snackbar?.type === 'success' ? 4000 : 6000}
          onClose={() => closeSnackbar(snackbar.key)}
          ClickAwayListenerProps={{
            onClickAway: () => {}
          }}
          id={`snackbar-${index}`}
        >
          <Paper elevation={3}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
              style={{ ...styles[snackbar.type], padding: '15px', width: 340 }}
            >
              <Grid item xs={1}>
                {snackbar?.type === 'success' ? (
                  <DoneSharp style={{ color: palette.marine }} />
                ) : (
                  <PriorityHighSharp style={{ color: palette.electricalWire }} />
                )}
              </Grid>
              <Grid item container direction="column" xs style={{ paddingLeft: 5 }}>
                <Grid item>
                  <P
                    mui
                    muiType="body-2-large"
                    styleOverride={{ color: palette.marine }}
                    id="snackbar-title"
                  >
                    {snackbar?.message}
                  </P>
                </Grid>
                {snackbar?.description && (
                  <Grid item>
                    <P
                      mui
                      muiType="body"
                      styleOverride={{ padding: 0, color: palette.marine }}
                      id="snackbar-description"
                    >
                      {snackbar.description}
                    </P>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'center' }}>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  style={{ padding: 0 }}
                  onClick={() => closeSnackbar(snackbar.key)}
                >
                  <CloseSharp fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Snackbar>
      ))}
    </>
  );
};

const styles = {
  success: {
    backgroundColor: palette.polymer
  },
  error: {
    backgroundColor: palette.dustMUI
  }
};
