import React, { ReactNode } from 'react';
import { palette, fontWeights } from 'styles/global';

interface IPProps {
  id?: string;
  children?: ReactNode;
  styleOverride?: React.CSSProperties;
  type?: '1' | '2' | '3' | '4' | '5';
  mui?: boolean;
  muiType?:
    | 'body'
    | 'body-italic'
    | 'body-bold'
    | 'body-2-large'
    | 'body-2-large-italic'
    | 'body-2-large-bold'
    | 'button'
    | 'sub-header-bold'
    | 'sub-header'
    | 'tertiary-text-button'
    | 'small-paragraph'
    | 'caption-body'
    | 'caption-body-bold'
    | 'overline-small'
    | 'overline-bold'
    | 'divider';
}

export const P = ({ children, type = '1', styleOverride, id, mui = false, muiType }: IPProps) => {
  const style = mui && muiType ? styles.mui[muiType] : styles.grommet[type];
  return (
    <p id={id} style={{ margin: 0, ...style, ...styleOverride }}>
      {children}
    </p>
  );
};

const styles: {
  grommet: Record<string, React.CSSProperties>;
  mui: Record<string, React.CSSProperties>;
} = {
  grommet: {
    1: {
      fontFamily: 'Assistant',
      fontWeight: 400,
      fontSize: 14,
      color: palette.slate,
      lineHeight: '18px'
    },
    2: {
      fontFamily: 'Assistant',
      fontWeight: 400,
      fontSize: 12,
      color: palette.slate,
      lineHeight: '16px'
    },
    3: {
      fontFamily: 'Assistant',
      fontWeight: 300,
      fontSize: 12,
      color: palette.slate,
      lineHeight: '16px'
    },
    4: {
      fontFamily: 'Assistant',
      fontWeight: 400,
      fontSize: 16,
      color: palette.slate,
      lineHeight: '16px'
    },
    5: {
      fontFamily: 'Assistant',
      fontWeight: 600,
      fontSize: 16,
      color: palette.slate,
      lineHeight: '24px'
    }
  },
  mui: {
    body: {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.normal,
      fontSize: 14,
      color: palette.elephant,
      lineHeight: '20px'
    },
    'small-paragraph': {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.normal,
      fontSize: 9,
      color: palette.marine,
      lineHeight: '15px'
    },
    'body-bold': {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.bold,
      fontSize: 14,
      color: palette.elephant,
      lineHeight: '20px'
    },
    'body-italic': {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.normal,
      fontStyle: 'italic',
      fontSize: 14,
      color: palette.elephant,
      lineHeight: '18px'
    },
    'body-2-large': {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.normal,
      fontSize: 16,
      color: palette.elephant,
      lineHeight: '22px',
      letterSpacing: '0px'
    },
    'body-2-large-italic': {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'italic',
      fontWeight: fontWeights.normal,
      fontSize: 16,
      color: palette.elephant,
      lineHeight: '22px',
      letterSpacing: '0px'
    },
    'body-2-large-bold': {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.bold,
      fontSize: 16,
      color: palette.elephant,
      lineHeight: '22px',
      letterSpacing: '0px'
    },
    button: {
      fontFamily: 'Manrope',
      fontWeight: fontWeights.bold,
      fontSize: 12,
      color: palette.caribbean,
      lineHeight: '18px',
      letterSpacing: '1px'
    },
    'sub-header': {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.regular,
      fontSize: 16,
      color: palette.marine,
      lineHeight: '20px',
      letterSpacing: '0px'
    },
    'sub-header-bold': {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.bold,
      fontSize: 16,
      color: palette.marine,
      lineHeight: '20px',
      letterSpacing: '0px'
    },
    'tertiary-text-button': {
      fontFamily: 'Manrope',
      fontWeight: fontWeights.bold,
      fontSize: 12,
      color: palette.caribbean,
      lineHeight: '18px',
      letterSpacing: '1px'
    },
    'caption-body-bold': {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.bold,
      fontSize: 12,
      lineHeight: '16px',
      textTransform: 'uppercase',
      color: palette.marine
    },
    'caption-body': {
      fontFamily: 'Source Sans Pro',
      fontWeight: fontWeights.normal,
      fontSize: 12,
      lineHeight: '16px',
      color: palette.marine
    },
    'overline-small': {
      fontFamily: 'Manrope',
      fontWeight: fontWeights.normal,
      fontSize: 10,
      lineHeight: '20px',
      letterSpacing: '0.1em',
      textTransform: 'uppercase'
    },
    'overline-bold': {
      fontFamily: 'Manrope',
      fontWeight: fontWeights.bold,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.1em',
      textTransform: 'uppercase'
    },
    divider: {
      fontFamily: 'Manrope',
      fontWeight: fontWeights.bold,
      fontSize: 4,
      lineHeight: '5px',
      letterSpacing: '0.12em'
    }
  }
};
