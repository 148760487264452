import { Banner } from 'context';
import { BannerTypes } from 'enums';
import { CastleStorage } from './constants';

export const BANNER_OPTIONS: Record<BannerTypes, Banner> = {
  [BannerTypes.COVID]: {
    title: 'We take safe service seriously.',
    link: '/covid19',
    desc: 'Read our response to Covid-19',
    dismissStorageID: CastleStorage.COVID19,
    type: 'info',
    newTab: true
  },
  [BannerTypes.PRIMARY_CARD_EXPIRED]: {
    title: `Your credit card is expired.`,
    link: '/account/payment-methods',
    desc: 'Add a New Card',
    type: 'error'
  },
  [BannerTypes.NO_CARD_ON_FILE]: {
    title: `You do not have a card on file.`,
    link: '/account/payment-methods',
    type: 'error',
    desc: 'Add a New Card'
  }
};

export const PRIMARY_CARD_WILL_EXPIRE = (last4: string): Banner => ({
  title: `Your credit card ending in ${last4} will expire soon.`,
  link: '/account/payment-methods',
  desc: 'Manage Payment Methods',
  type: 'error'
});
