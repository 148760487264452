import React, { ReactNode, FC } from 'react';
import { Grid } from '@material-ui/core';

interface ICardLayoutContainer {
  actionButton?: ReactNode;
  title?: ReactNode;
  total?: ReactNode;
  description?: ReactNode;
}

export const EstimateCardLayoutContainer: FC<ICardLayoutContainer> = ({
  actionButton,
  title,
  total,
  description,
  children
}) => {
  return (
    <Grid container direction="row">
      {actionButton && (
        <Grid container item xs style={{ maxWidth: 40 }}>
          {actionButton}
        </Grid>
      )}
      <Grid container item xs style={{ paddingTop: '8px' }}>
        {(title || total) && (
          <Grid container item>
            <Grid container direction="row" justify="space-between">
              {title && (
                <Grid item xs>
                  {title}
                </Grid>
              )}
              {total && (
                <Grid item xs={4}>
                  {total}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        {description && (
          <Grid item xs>
            {description}
          </Grid>
        )}
        {children && (
          <Grid item container xs justify="center">
            {children}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
