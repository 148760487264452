import React, { FC, useCallback, useEffect, useState } from 'react';
import { BraintreeError, Client, PayPal, paypal } from 'braintree-web';

interface IBraintreePaypal {
  client?: Client;
  amount?: number;
  flow?: 'checkout' | 'vault';
  billingAgreementDescription?: string;
  redeemNonce: (nonce: string) => Promise<void>;
  onError: (error: Pick<BraintreeError, 'message'>) => void;
  submitButton?: React.RefObject<HTMLButtonElement>;
}

export const BraintreePaypal: FC<IBraintreePaypal> = ({
  client,
  redeemNonce,
  amount,
  billingAgreementDescription = '',
  flow = 'vault',
  onError,
  submitButton
}) => {
  const [paypalInstance, setPaypalInstance] = useState<PayPal>();

  const tokenize = useCallback(
    async (p: PayPal) => {
      try {
        const data = await p?.tokenize({
          flow,
          amount,
          currency: 'USD',
          billingAgreementDescription
        });
        !!data && redeemNonce(data.nonce);
      } catch (e) {
        onError(e);
      }
    },
    [redeemNonce, flow, amount, billingAgreementDescription, onError]
  );

  const createPaypal = useCallback(async () => {
    if (client && !paypalInstance) {
      try {
        const p = await paypal.create({ client });
        setPaypalInstance(p);
        if (submitButton && submitButton.current) {
          submitButton.current.onclick = (_e) => tokenize(p);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [client, paypalInstance, submitButton, tokenize]);

  useEffect(() => {
    if (client && !paypalInstance) {
      createPaypal();
    }
  }, [client, paypalInstance, createPaypal]);

  return <></>;
};
