import React, { createContext, useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_FEATURES } from 'graphql/queries';
import { Feature, FeatureFlag, Query } from 'generated/graphql';

interface FeaturesContextProps {
  features: Feature[];
  checkFeatureIsEnabled: (feature: FeatureFlag) => boolean;
}

type FeaturesProviderProps = {
  children: React.ReactNode | JSX.Element;
};

export const FeaturesContext = createContext<FeaturesContextProps>({} as FeaturesContextProps);

export const FeaturesProvider = ({ children }: FeaturesProviderProps) => {
  const [features, setFeatures] = useState<Feature[]>([]);

  useQuery<Query>(GET_FEATURES, {
    onCompleted: (data: Query) => {
      const features = data && data.getFeatures && (data.getFeatures.filter(x => !!x) as Feature[]);
      if (!!features) {
        setFeatures(features);
      } else {
        setFeatures([]);
      }
    },
    onError: (_e) => {
      setFeatures([]);
    }
  });

  const checkFeatureIsEnabled = useCallback(
    (feature: FeatureFlag) =>
      !!features.find(f => f.name === feature) && !!features.find(f => f.name === feature)?.enabled,
    [features]
  );

  return (
    <FeaturesContext.Provider value={{ features, checkFeatureIsEnabled }}>
      {children}
    </FeaturesContext.Provider>
  );
};
