import React, { useState } from 'react';
import { Box, TextField, Button } from '@material-ui/core';

import { FormError } from 'components';

interface IAuthConfirmProps {
  authType: string;
  onSubmit: (verificationCode: string, type: string) => void;
  resendVerificationCode: () => void;
  errorMessage: string;
}

export const AuthConfirm = (props: IAuthConfirmProps) => {
  const [verificationCode, setVerificationCodeState] = useState('');

  const handleChange = (e: any) => {
    e.preventDefault();
    setVerificationCodeState(e.target.value);
  };

  const onSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(verificationCode, authType);
  };

  const { onSubmit, resendVerificationCode, errorMessage, authType } = props;
  return (
    <form onSubmit={onSubmitForm}>
      <Box flexDirection="row" alignItems="start" justifyItems="start" p="large">
        <Box p="medium" flexDirection="column" flex>
          <FormError errorMessage={errorMessage} />
          <TextField
            placeholder="Enter Code"
            required
            label="Verification Code"
            type="text"
            name="verificationCode"
            id="verificationCode"
            value={verificationCode}
            onChange={handleChange}
          />
        </Box>
      </Box>
      <Box flexDirection="row" alignItems="center" justifyItems="center">
        <Button type="submit">SUBMIT</Button>
        <Button type="button" onClick={resendVerificationCode}>
          Resend Verification Code
        </Button>
      </Box>
    </form>
  );
};
