export const addPhoneFormatting = (phoneNumber: string) => {
  // Ignore falsey values
  if (!phoneNumber) {
    return '';
  }

  // Remove non-numeric characters
  let input = phoneNumber.replace(/[^0-9]/g, '');

  // Don't attempt to format phone numbers of indeterminate size
  if (input.length > 11 || input.length < 10) {
    return '';
  }

  // Remove country code digit if necessary
  if (input.length > 10) {
    input = input.slice(1);
  }

  return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
};

export const removePhoneFormatting = (phoneNumber: string) => {
  // Ignore falsey values
  if (!phoneNumber) {
    return '';
  }

  // Remove non-numeric characters
  const input = phoneNumber.replace(/[^0-9]/g, '');

  // Don't attempt to format phone numbers of indeterminate size
  if (input.length < 10 || input.length > 11) {
    return '';
  }

  // Add country code digit if necessary
  if (input.length === 10) {
    return `+1${input}`;
  } else {
    return `+${input}`;
  }
};
