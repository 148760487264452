import gql from 'graphql-tag';

export const SET_PASSWORD_FROM_TOKEN = gql`
  mutation setPasswordFromToken($token: String!, $password: String!) {
    setPasswordFromToken(token: $token, password: $password) {
      id
    }
  }
`;

export const SEND_EMAIL_RECEIPT = gql`
  mutation sendEmailReceipt($email: String!, $serviceId: String, $projectId: String) {
    sendEmailReceipt(email: $email, serviceId: $serviceId, projectId: $projectId)
  }
`;

export const ACCEPT_SERVICE_ESTIMATE = gql`
  mutation acceptServiceEstimate($estimateId: ID!, $acceptedEstimateTerms: Boolean!) {
    acceptServiceEstimate(estimateId: $estimateId, acceptedEstimateTerms: $acceptedEstimateTerms) {
      id
    }
  }
`;

export const DECLINE_SERVICE_ESTIMATE = gql`
  mutation declineServiceEstimate(
    $serviceId: ID!
    $estimateDeclinedReason: ServiceEstimateDeclinedReason!
    $estimateDeclinedNotes: String
  ) {
    declineServiceEstimate(
      serviceId: $serviceId
      data: {
        estimateDeclinedReason: $estimateDeclinedReason
        estimateDeclinedNotes: $estimateDeclinedNotes
      }
    ) {
      id
      estimateDeclinedReason
      estimateDeclinedNotes
    }
  }
`;

export const PROCESS_TRANSACTION = gql`
  mutation processTransaction($nonce: String, $token: String, $serviceId: String!) {
    processTransaction(nonce: $nonce, token: $token, serviceId: $serviceId) {
      id
    }
  }
`;

export const PROCESS_PROJECT_TRANSACTION = gql`
  mutation processProjectTransaction($nonce: String, $token: String, $projectId: String!) {
    processProjectTransaction(nonce: $nonce, token: $token, projectId: $projectId) {
      id
    }
  }
`;

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation setDefaultPaymentMethod($paymentMethodToken: String!) {
    setDefaultPaymentMethod(paymentMethodToken: $paymentMethodToken)
  }
`;

export const DELETE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($paymentMethodToken: String!) {
    deletePaymentMethod(paymentMethodToken: $paymentMethodToken)
  }
`;

export const SAVE_PAYMENT_METHOD = gql`
  mutation savePaymentMethod($nonce: String!, $termsAccepted: Boolean!) {
    savePaymentMethod(nonce: $nonce, termsAccepted: $termsAccepted) {
      id
    }
  }
`;

export const SAVE_PAYMENT_METHOD_FROM_TOKEN = gql`
  mutation savePaymentMethodFromToken(
    $nonce: String!
    $termsAccepted: Boolean!
    $signUpToken: String!
  ) {
    savePaymentMethodFromToken(
      nonce: $nonce
      termsAccepted: $termsAccepted
      signUpToken: $signUpToken
    ) {
      id
    }
  }
`;

export const APPLY_SERVICE_ACTION = gql`
  mutation applyServiceAction(
    $projectId: String!
    $data: [CalculatePaymentInput!]!
    $estimateDeclinedReason: ServiceEstimateDeclinedReason
    $estimateDeclinedNotes: String
  ) {
    applyServiceAction(
      projectId: $projectId
      data: $data
      estimateDeclinedReason: $estimateDeclinedReason
      estimateDeclinedNotes: $estimateDeclinedNotes
    ) {
      id
    }
  }
`;
